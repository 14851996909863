define('ember-localforage-adapter/utils/cache', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    init: function init() {
      this.data = new Map();
    },

    clear: function clear() {
      this.data.clear();
    },
    get: function get(namespace) {
      var data = this.data.get(namespace);

      if (!data) {
        return null;
      }

      return data;
    },
    set: function set(namespace, objects) {
      this.data.set(namespace, objects);
    },
    replace: function replace(data) {
      this.clear();

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(data)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var index = _step.value;

          this.set(index, data[index]);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  });
});