define('ember-calendar/components/as-calendar/time-zone-option', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    classNameBindings: [':as-calendar-time-zone-option', 'isSelected:selected'],

    description: _ember.default.computed.oneWay('option.description'),
    option: null,
    selectedOption: null,

    isSelected: _ember.default.computed('selectedOption.value', 'option.value', function () {
      return this.get('selectedOption.value') === this.get('option.value');
    }),

    _selectOption: _ember.default.on('click', function () {
      this.attrs.onSelect(this.get('option.value'));
    })
  });
});