define('ember-localforage-adapter/utils/queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    init: function init() {
      this.queue = [Ember.RSVP.resolve()];
    },

    attach: function attach(callback) {
      var _this = this;

      var queueKey = this.queue.length;

      this.queue[queueKey] = new Ember.RSVP.Promise(function (resolve, reject) {
        _this.queue[queueKey - 1].then(function () {
          _this.queue.splice(queueKey - 1, 1);
          callback(resolve, reject);
        });
      });

      return this.queue[queueKey];
    }
  });
});