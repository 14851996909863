define('ember-click-outside/utils', ['exports', 'ember-click-outside/-private/matches-selector'], function (exports, _matchesSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.closest = closest;
  exports.printConsoleMessage = printConsoleMessage;
  function closest(element, selector) {
    if ((0, _matchesSelector.matches)(element, selector)) {
      return element;
    }

    while (element.parentNode) {
      element = element.parentNode;

      if ((0, _matchesSelector.matches)(element, selector)) {
        return element;
      }
    }
  }

  // https://github.com/mike-north/ember-deprecated/blob/master/addon/utils.js
  /* eslint no-console: "off" */
  function printConsoleMessage(msg) {
    if (console.trace) {
      if (console.groupCollapsed) {
        console.groupCollapsed(msg);
        console.trace();
        console.groupEnd();
      } else {
        console.warn(msg);
        console.trace();
      }
    } else {
      console.warn(msg);
    }
  }
});