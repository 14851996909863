enifed('@glimmer/runtime', ['exports', '@glimmer/util', '@glimmer/reference', '@glimmer/wire-format'], function (exports, _util, _reference2, _wireFormat) {
    'use strict';

    exports.ConcreteBounds = exports.ElementStack = exports.insertHTMLBefore = exports.isWhitespace = exports.DOMTreeConstruction = exports.IDOMChanges = exports.DOMChanges = exports.isComponentDefinition = exports.ComponentDefinition = exports.PartialDefinition = exports.Environment = exports.Scope = exports.isSafeString = exports.RenderResult = exports.UpdatingVM = exports.compileExpression = exports.compileList = exports.InlineMacros = exports.BlockMacros = exports.getDynamicVar = exports.resetDebuggerCallback = exports.setDebuggerCallback = exports.normalizeTextValue = exports.debugSlice = exports.Register = exports.readDOMAttr = exports.defaultPropertyManagers = exports.defaultAttributeManagers = exports.defaultManagers = exports.INPUT_VALUE_PROPERTY_MANAGER = exports.PropertyManager = exports.AttributeManager = exports.IAttributeManager = exports.CompiledDynamicTemplate = exports.CompiledStaticTemplate = exports.compileLayout = exports.OpcodeBuilderDSL = exports.ConditionalReference = exports.PrimitiveReference = exports.UNDEFINED_REFERENCE = exports.NULL_REFERENCE = exports.templateFactory = exports.Simple = undefined;

    function _defaults(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults(subClass, superClass);
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    /**
     * Registers
     *
     * For the most part, these follows MIPS naming conventions, however the
     * register numbers are different.
     */
    var Register;
    (function (Register) {
        // $0 or $pc (program counter): pointer into `program` for the next insturction; -1 means exit
        Register[Register["pc"] = 0] = "pc";
        // $1 or $ra (return address): pointer into `program` for the return
        Register[Register["ra"] = 1] = "ra";
        // $2 or $fp (frame pointer): pointer into the `evalStack` for the base of the stack
        Register[Register["fp"] = 2] = "fp";
        // $3 or $sp (stack pointer): pointer into the `evalStack` for the top of the stack
        Register[Register["sp"] = 3] = "sp";
        // $4-$5 or $s0-$s1 (saved): callee saved general-purpose registers
        Register[Register["s0"] = 4] = "s0";
        Register[Register["s1"] = 5] = "s1";
        // $6-$7 or $t0-$t1 (temporaries): caller saved general-purpose registers
        Register[Register["t0"] = 6] = "t0";
        Register[Register["t1"] = 7] = "t1";
    })(Register || (exports.Register = Register = {}));

    var AppendOpcodes = function () {
        function AppendOpcodes() {
            _classCallCheck(this, AppendOpcodes);

            this.evaluateOpcode = (0, _util.fillNulls)(72 /* Size */).slice();
        }

        AppendOpcodes.prototype.add = function (name, evaluate) {
            this.evaluateOpcode[name] = evaluate;
        };

        AppendOpcodes.prototype.evaluate = function (vm, opcode, type) {
            var func = this.evaluateOpcode[type];
            func(vm, opcode);
        };

        return AppendOpcodes;
    }();
    var APPEND_OPCODES = new AppendOpcodes();
    var AbstractOpcode = function () {
        function AbstractOpcode() {
            _classCallCheck(this, AbstractOpcode);

            (0, _util.initializeGuid)(this);
        }

        AbstractOpcode.prototype.toJSON = function () {
            return { guid: this._guid, type: this.type };
        };

        return AbstractOpcode;
    }();
    var UpdatingOpcode = function (_AbstractOpcode) {
        _inherits(UpdatingOpcode, _AbstractOpcode);

        function UpdatingOpcode() {
            _classCallCheck(this, UpdatingOpcode);

            var _this = _possibleConstructorReturn(this, _AbstractOpcode.apply(this, arguments));

            _this.next = null;
            _this.prev = null;
            return _this;
        }

        return UpdatingOpcode;
    }(AbstractOpcode);

    function _defaults$1(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$1(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$1(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$1(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$1(subClass, superClass);
    }

    var PrimitiveReference = function (_ConstReference) {
        _inherits$1(PrimitiveReference, _ConstReference);

        function PrimitiveReference(value) {
            _classCallCheck$1(this, PrimitiveReference);

            return _possibleConstructorReturn$1(this, _ConstReference.call(this, value));
        }

        PrimitiveReference.create = function (value) {
            if (value === undefined) {
                return UNDEFINED_REFERENCE;
            } else if (value === null) {
                return NULL_REFERENCE;
            } else if (value === true) {
                return TRUE_REFERENCE;
            } else if (value === false) {
                return FALSE_REFERENCE;
            } else if (typeof value === 'number') {
                return new ValueReference(value);
            } else {
                return new StringReference(value);
            }
        };

        PrimitiveReference.prototype.get = function () {
            return UNDEFINED_REFERENCE;
        };

        return PrimitiveReference;
    }(_reference2.ConstReference);

    var StringReference = function (_PrimitiveReference) {
        _inherits$1(StringReference, _PrimitiveReference);

        function StringReference() {
            _classCallCheck$1(this, StringReference);

            var _this2 = _possibleConstructorReturn$1(this, _PrimitiveReference.apply(this, arguments));

            _this2.lengthReference = null;
            return _this2;
        }

        StringReference.prototype.get = function (key) {
            var lengthReference;

            if (key === 'length') {
                lengthReference = this.lengthReference;


                if (lengthReference === null) {
                    lengthReference = this.lengthReference = new ValueReference(this.inner.length);
                }
                return lengthReference;
            } else {
                return _PrimitiveReference.prototype.get.call(this, key);
            }
        };

        return StringReference;
    }(PrimitiveReference);

    var ValueReference = function (_PrimitiveReference2) {
        _inherits$1(ValueReference, _PrimitiveReference2);

        function ValueReference(value) {
            _classCallCheck$1(this, ValueReference);

            return _possibleConstructorReturn$1(this, _PrimitiveReference2.call(this, value));
        }

        return ValueReference;
    }(PrimitiveReference);

    var UNDEFINED_REFERENCE = new ValueReference(undefined);
    var NULL_REFERENCE = new ValueReference(null);
    var TRUE_REFERENCE = new ValueReference(true);
    var FALSE_REFERENCE = new ValueReference(false);
    var ConditionalReference = function () {
        function ConditionalReference(inner) {
            _classCallCheck$1(this, ConditionalReference);

            this.inner = inner;
            this.tag = inner.tag;
        }

        ConditionalReference.prototype.value = function () {
            return this.toBool(this.inner.value());
        };

        ConditionalReference.prototype.toBool = function (value) {
            return !!value;
        };

        return ConditionalReference;
    }();

    function _defaults$2(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$2(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$2(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$2(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$2(subClass, superClass);
    }

    var ConcatReference = function (_CachedReference) {
        _inherits$2(ConcatReference, _CachedReference);

        function ConcatReference(parts) {
            _classCallCheck$2(this, ConcatReference);

            var _this = _possibleConstructorReturn$2(this, _CachedReference.call(this));

            _this.parts = parts;
            _this.tag = (0, _reference2.combineTagged)(parts);
            return _this;
        }

        ConcatReference.prototype.compute = function () {
            var parts = new Array(),
                i,
                value;
            for (i = 0; i < this.parts.length; i++) {
                value = this.parts[i].value();

                if (value !== null && value !== undefined) {
                    parts[i] = castToString(value);
                }
            }
            if (parts.length > 0) {
                return parts.join('');
            }
            return null;
        };

        return ConcatReference;
    }(_reference2.CachedReference);
    function castToString(value) {
        if (typeof value.toString !== 'function') {
            return '';
        }
        return String(value);
    }

    APPEND_OPCODES.add(1 /* Helper */, function (vm, _ref) {
        var _helper = _ref.op1;

        var stack = vm.stack;
        var helper = vm.constants.getFunction(_helper);
        var args = stack.pop();
        var value = helper(vm, args);
        args.clear();
        vm.stack.push(value);
    });
    APPEND_OPCODES.add(2 /* Function */, function (vm, _ref2) {
        var _function = _ref2.op1;

        var func = vm.constants.getFunction(_function);
        vm.stack.push(func(vm));
    });
    APPEND_OPCODES.add(5 /* GetVariable */, function (vm, _ref3) {
        var symbol = _ref3.op1;

        var expr = vm.referenceForSymbol(symbol);
        vm.stack.push(expr);
    });
    APPEND_OPCODES.add(4 /* SetVariable */, function (vm, _ref4) {
        var symbol = _ref4.op1;

        var expr = vm.stack.pop();
        vm.scope().bindSymbol(symbol, expr);
    });
    APPEND_OPCODES.add(70 /* ResolveMaybeLocal */, function (vm, _ref5) {
        var _name = _ref5.op1;

        var name = vm.constants.getString(_name);
        var locals = vm.scope().getPartialMap();
        var ref = locals[name];
        if (ref === undefined) {
            ref = vm.getSelf().get(name);
        }
        vm.stack.push(ref);
    });
    APPEND_OPCODES.add(19 /* RootScope */, function (vm, _ref6) {
        var symbols = _ref6.op1,
            bindCallerScope = _ref6.op2;

        vm.pushRootScope(symbols, !!bindCallerScope);
    });
    APPEND_OPCODES.add(6 /* GetProperty */, function (vm, _ref7) {
        var _key = _ref7.op1;

        var key = vm.constants.getString(_key);
        var expr = vm.stack.pop();
        vm.stack.push(expr.get(key));
    });
    APPEND_OPCODES.add(7 /* PushBlock */, function (vm, _ref8) {
        var _block = _ref8.op1;

        var block = _block ? vm.constants.getBlock(_block) : null;
        vm.stack.push(block);
    });
    APPEND_OPCODES.add(8 /* GetBlock */, function (vm, _ref9) {
        var _block = _ref9.op1;

        vm.stack.push(vm.scope().getBlock(_block));
    });
    APPEND_OPCODES.add(9 /* HasBlock */, function (vm, _ref10) {
        var _block = _ref10.op1;

        var hasBlock = !!vm.scope().getBlock(_block);
        vm.stack.push(hasBlock ? TRUE_REFERENCE : FALSE_REFERENCE);
    });
    APPEND_OPCODES.add(10 /* HasBlockParams */, function (vm, _ref11) {
        var _block = _ref11.op1;

        var block = vm.scope().getBlock(_block);
        var hasBlockParams = block && block.symbolTable.parameters.length;
        vm.stack.push(hasBlockParams ? TRUE_REFERENCE : FALSE_REFERENCE);
    });
    APPEND_OPCODES.add(11 /* Concat */, function (vm, _ref12) {
        var count = _ref12.op1,
            i;

        var out = [];
        for (i = count; i > 0; i--) {
            out.push(vm.stack.pop());
        }
        vm.stack.push(new ConcatReference(out.reverse()));
    });

    var _createClass = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _classCallCheck$4(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Arguments = function () {
        function Arguments() {
            _classCallCheck$4(this, Arguments);

            this.stack = null;
            this.positional = new PositionalArguments();
            this.named = new NamedArguments();
        }

        Arguments.prototype.empty = function () {
            this.setup(null, true);
            return this;
        };

        Arguments.prototype.setup = function (stack, synthetic) {
            this.stack = stack;
            var names = stack.fromTop(0);
            var namedCount = names.length;
            var positionalCount = stack.fromTop(namedCount + 1);

            var positional = this.positional;
            positional.setup(stack, positionalCount + namedCount + 2, positionalCount);
            var named = this.named;
            named.setup(stack, namedCount, names, synthetic);
        };

        Arguments.prototype.at = function (pos) {
            return this.positional.at(pos);
        };

        Arguments.prototype.get = function (name) {
            return this.named.get(name);
        };

        Arguments.prototype.capture = function () {
            return {
                tag: this.tag,
                length: this.length,
                positional: this.positional.capture(),
                named: this.named.capture()
            };
        };

        Arguments.prototype.clear = function () {
            var stack = this.stack,
                length = this.length;

            stack.pop(length + 2);
        };

        _createClass(Arguments, [{
            key: 'tag',
            get: function () {
                return (0, _reference2.combineTagged)([this.positional, this.named]);
            }
        }, {
            key: 'length',
            get: function () {
                return this.positional.length + this.named.length;
            }
        }]);

        return Arguments;
    }();

    var PositionalArguments = function () {
        function PositionalArguments() {
            _classCallCheck$4(this, PositionalArguments);

            this.length = 0;
            this.stack = null;
            this.start = 0;
            this._tag = null;
            this._references = null;
        }

        PositionalArguments.prototype.setup = function (stack, start, length) {
            this.stack = stack;
            this.start = start;
            this.length = length;
            this._tag = null;
            this._references = null;
        };

        PositionalArguments.prototype.at = function (position) {
            var start = this.start,
                length = this.length;

            if (position < 0 || position >= length) {
                return UNDEFINED_REFERENCE;
            }
            // stack: pos1, pos2, pos3, named1, named2
            // start: 4 (top - 4)
            //
            // at(0) === pos1 === top - start
            // at(1) === pos2 === top - (start - 1)
            // at(2) === pos3 === top - (start - 2)

            return this.stack.fromTop(start - position - 1);
        };

        PositionalArguments.prototype.capture = function () {
            return new CapturedPositionalArguments(this.tag, this.references);
        };

        _createClass(PositionalArguments, [{
            key: 'tag',
            get: function () {
                var tag = this._tag;
                if (!tag) {
                    tag = this._tag = (0, _reference2.combineTagged)(this.references);
                }
                return tag;
            }
        }, {
            key: 'references',
            get: function () {
                var references = this._references,
                    length,
                    i;
                if (!references) {
                    length = this.length;


                    references = this._references = new Array(length);
                    for (i = 0; i < length; i++) {
                        references[i] = this.at(i);
                    }
                }
                return references;
            }
        }]);

        return PositionalArguments;
    }();

    var CapturedPositionalArguments = function () {
        function CapturedPositionalArguments(tag, references) {
            var length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : references.length;

            _classCallCheck$4(this, CapturedPositionalArguments);

            this.tag = tag;
            this.references = references;
            this.length = length;
        }

        CapturedPositionalArguments.prototype.at = function (position) {
            return this.references[position];
        };

        CapturedPositionalArguments.prototype.value = function () {
            return this.references.map(this.valueOf);
        };

        CapturedPositionalArguments.prototype.get = function (name) {
            var references = this.references,
                length = this.length,
                idx;

            if (name === 'length') {
                return PrimitiveReference.create(length);
            } else {
                idx = parseInt(name, 10);

                if (idx < 0 || idx >= length) {
                    return UNDEFINED_REFERENCE;
                } else {
                    return references[idx];
                }
            }
        };

        CapturedPositionalArguments.prototype.valueOf = function (reference$$1) {
            return reference$$1.value();
        };

        return CapturedPositionalArguments;
    }();

    var NamedArguments = function () {
        function NamedArguments() {
            _classCallCheck$4(this, NamedArguments);

            this.length = 0;
            this._tag = null;
            this._references = null;
            this._names = null;
            this._realNames = _util.EMPTY_ARRAY;
        }

        NamedArguments.prototype.setup = function (stack, length, names, synthetic) {
            this.stack = stack;
            this.length = length;
            this._tag = null;
            this._references = null;
            if (synthetic) {
                this._names = names;
                this._realNames = _util.EMPTY_ARRAY;
            } else {
                this._names = null;
                this._realNames = names;
            }
        };

        NamedArguments.prototype.has = function (name) {
            return this.names.indexOf(name) !== -1;
        };

        NamedArguments.prototype.get = function (name) {
            var names = this.names,
                length = this.length;

            var idx = names.indexOf(name);
            if (idx === -1) {
                return UNDEFINED_REFERENCE;
            }
            // stack: pos1, pos2, pos3, named1, named2
            // start: 4 (top - 4)
            // namedDict: { named1: 1, named2: 0 };
            //
            // get('named1') === named1 === top - (start - 1)
            // get('named2') === named2 === top - start

            return this.stack.fromTop(length - idx);
        };

        NamedArguments.prototype.capture = function () {
            return new CapturedNamedArguments(this.tag, this.names, this.references);
        };

        NamedArguments.prototype.sliceName = function (name) {
            return name.slice(1);
        };

        _createClass(NamedArguments, [{
            key: 'tag',
            get: function () {
                return (0, _reference2.combineTagged)(this.references);
            }
        }, {
            key: 'names',
            get: function () {
                var names = this._names;
                if (!names) {
                    names = this._names = this._realNames.map(this.sliceName);
                }
                return names;
            }
        }, {
            key: 'references',
            get: function () {
                var references = this._references,
                    names,
                    length,
                    i;
                if (!references) {
                    names = this.names, length = this.length;


                    references = this._references = [];
                    for (i = 0; i < length; i++) {
                        references[i] = this.get(names[i]);
                    }
                }
                return references;
            }
        }]);

        return NamedArguments;
    }();

    var CapturedNamedArguments = function () {
        function CapturedNamedArguments(tag, names, references) {
            _classCallCheck$4(this, CapturedNamedArguments);

            this.tag = tag;
            this.names = names;
            this.references = references;
            this.length = names.length;
            this._map = null;
        }

        CapturedNamedArguments.prototype.has = function (name) {
            return this.names.indexOf(name) !== -1;
        };

        CapturedNamedArguments.prototype.get = function (name) {
            var names = this.names,
                references = this.references;

            var idx = names.indexOf(name);
            if (idx === -1) {
                return UNDEFINED_REFERENCE;
            } else {
                return references[idx];
            }
        };

        CapturedNamedArguments.prototype.value = function () {
            var names = this.names,
                references = this.references,
                i,
                name;

            var out = (0, _util.dict)();
            for (i = 0; i < names.length; i++) {
                name = names[i];

                out[name] = references[i].value();
            }
            return out;
        };

        _createClass(CapturedNamedArguments, [{
            key: 'map',
            get: function () {
                var map$$1 = this._map,
                    names,
                    references,
                    i,
                    name;
                if (!map$$1) {
                    names = this.names, references = this.references;


                    map$$1 = this._map = (0, _util.dict)();
                    for (i = 0; i < names.length; i++) {
                        name = names[i];

                        map$$1[name] = references[i];
                    }
                }
                return map$$1;
            }
        }]);

        return CapturedNamedArguments;
    }();

    var ARGS = new Arguments();

    function _defaults$5(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$6(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$5(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$5(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$5(subClass, superClass);
    }

    APPEND_OPCODES.add(20 /* ChildScope */, function (vm) {
        return vm.pushChildScope();
    });
    APPEND_OPCODES.add(21 /* PopScope */, function (vm) {
        return vm.popScope();
    });
    APPEND_OPCODES.add(39 /* PushDynamicScope */, function (vm) {
        return vm.pushDynamicScope();
    });
    APPEND_OPCODES.add(40 /* PopDynamicScope */, function (vm) {
        return vm.popDynamicScope();
    });
    APPEND_OPCODES.add(12 /* Immediate */, function (vm, _ref) {
        var number = _ref.op1;

        vm.stack.push(number);
    });
    APPEND_OPCODES.add(13 /* Constant */, function (vm, _ref2) {
        var other = _ref2.op1;

        vm.stack.push(vm.constants.getOther(other));
    });
    APPEND_OPCODES.add(14 /* PrimitiveReference */, function (vm, _ref3) {
        var primitive = _ref3.op1;

        var stack = vm.stack;

        var value = primitive & ~(3 << 30);
        switch ((primitive & 3 << 30) >>> 30) {
            case 0:
                stack.push(PrimitiveReference.create(value));
                break;
            case 1:
                stack.push(PrimitiveReference.create(vm.constants.getFloat(value)));
                break;
            case 2:
                stack.push(PrimitiveReference.create(vm.constants.getString(value)));
                break;
            case 3:
                switch (value) {
                    case 0:
                        stack.push(FALSE_REFERENCE);
                        break;
                    case 1:
                        stack.push(TRUE_REFERENCE);
                        break;
                    case 2:
                        stack.push(NULL_REFERENCE);
                        break;
                    case 3:
                        stack.push(UNDEFINED_REFERENCE);
                        break;
                }
                break;
        }
    });
    APPEND_OPCODES.add(15 /* Dup */, function (vm, _ref4) {
        var register = _ref4.op1,
            offset = _ref4.op2;

        var position = vm.fetchValue(register) - offset;
        vm.stack.dup(position);
    });
    APPEND_OPCODES.add(16 /* Pop */, function (vm, _ref5) {
        var count = _ref5.op1;
        return vm.stack.pop(count);
    });
    APPEND_OPCODES.add(17 /* Load */, function (vm, _ref6) {
        var register = _ref6.op1;
        return vm.load(register);
    });
    APPEND_OPCODES.add(18 /* Fetch */, function (vm, _ref7) {
        var register = _ref7.op1;
        return vm.fetch(register);
    });
    APPEND_OPCODES.add(38 /* BindDynamicScope */, function (vm, _ref8) {
        var _names = _ref8.op1;

        var names = vm.constants.getArray(_names);
        vm.bindDynamicScope(names);
    });
    APPEND_OPCODES.add(47 /* PushFrame */, function (vm) {
        return vm.pushFrame();
    });
    APPEND_OPCODES.add(48 /* PopFrame */, function (vm) {
        return vm.popFrame();
    });
    APPEND_OPCODES.add(49 /* Enter */, function (vm, _ref9) {
        var args = _ref9.op1;
        return vm.enter(args);
    });
    APPEND_OPCODES.add(50 /* Exit */, function (vm) {
        return vm.exit();
    });
    APPEND_OPCODES.add(41 /* CompileDynamicBlock */, function (vm) {
        var stack = vm.stack;
        var block = stack.pop();
        stack.push(block ? block.compileDynamic(vm.env) : null);
    });
    APPEND_OPCODES.add(42 /* InvokeStatic */, function (vm, _ref10) {
        var _block = _ref10.op1;

        var block = vm.constants.getBlock(_block);
        var compiled = block.compileStatic(vm.env);
        vm.call(compiled.handle);
    });
    APPEND_OPCODES.add(43 /* InvokeDynamic */, function (vm, _ref11) {
        var _invoker = _ref11.op1;

        var invoker = vm.constants.getOther(_invoker);
        var block = vm.stack.pop();
        invoker.invoke(vm, block);
    });
    APPEND_OPCODES.add(44 /* Jump */, function (vm, _ref12) {
        var target = _ref12.op1;
        return vm.goto(target);
    });
    APPEND_OPCODES.add(45 /* JumpIf */, function (vm, _ref13) {
        var target = _ref13.op1,
            cache;

        var reference$$1 = vm.stack.pop();
        if ((0, _reference2.isConst)(reference$$1)) {
            if (reference$$1.value()) {
                vm.goto(target);
            }
        } else {
            cache = new _reference2.ReferenceCache(reference$$1);

            if (cache.peek()) {
                vm.goto(target);
            }
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(46 /* JumpUnless */, function (vm, _ref14) {
        var target = _ref14.op1,
            cache;

        var reference$$1 = vm.stack.pop();
        if ((0, _reference2.isConst)(reference$$1)) {
            if (!reference$$1.value()) {
                vm.goto(target);
            }
        } else {
            cache = new _reference2.ReferenceCache(reference$$1);

            if (!cache.peek()) {
                vm.goto(target);
            }
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(22 /* Return */, function (vm) {
        return vm.return();
    });
    APPEND_OPCODES.add(23 /* ReturnTo */, function (vm, _ref15) {
        var relative = _ref15.op1;

        vm.returnTo(relative);
    });
    var ConstTest = function (ref) {
        return new _reference2.ConstReference(!!ref.value());
    };
    var SimpleTest = function (ref) {
        return ref;
    };
    var EnvironmentTest = function (ref, env) {
        return env.toConditionalReference(ref);
    };
    APPEND_OPCODES.add(51 /* Test */, function (vm, _ref16) {
        var _func = _ref16.op1;

        var stack = vm.stack;
        var operand = stack.pop();
        var func = vm.constants.getFunction(_func);
        stack.push(func(operand, vm.env));
    });
    var Assert = function (_UpdatingOpcode) {
        _inherits$5(Assert, _UpdatingOpcode);

        function Assert(cache) {
            _classCallCheck$6(this, Assert);

            var _this = _possibleConstructorReturn$5(this, _UpdatingOpcode.call(this));

            _this.type = 'assert';
            _this.tag = cache.tag;
            _this.cache = cache;
            return _this;
        }

        Assert.prototype.evaluate = function (vm) {
            var cache = this.cache;

            if ((0, _reference2.isModified)(cache.revalidate())) {
                vm.throw();
            }
        };

        Assert.prototype.toJSON = function () {
            var type = this.type,
                _guid = this._guid,
                cache = this.cache;

            var expected = void 0;
            try {
                expected = JSON.stringify(cache.peek());
            } catch (e) {
                expected = String(cache.peek());
            }
            return {
                args: [],
                details: { expected: expected },
                guid: _guid,
                type: type
            };
        };

        return Assert;
    }(UpdatingOpcode);
    var JumpIfNotModifiedOpcode = function (_UpdatingOpcode2) {
        _inherits$5(JumpIfNotModifiedOpcode, _UpdatingOpcode2);

        function JumpIfNotModifiedOpcode(tag, target) {
            _classCallCheck$6(this, JumpIfNotModifiedOpcode);

            var _this2 = _possibleConstructorReturn$5(this, _UpdatingOpcode2.call(this));

            _this2.target = target;
            _this2.type = 'jump-if-not-modified';
            _this2.tag = tag;
            _this2.lastRevision = tag.value();
            return _this2;
        }

        JumpIfNotModifiedOpcode.prototype.evaluate = function (vm) {
            var tag = this.tag,
                target = this.target,
                lastRevision = this.lastRevision;

            if (!vm.alwaysRevalidate && tag.validate(lastRevision)) {
                vm.goto(target);
            }
        };

        JumpIfNotModifiedOpcode.prototype.didModify = function () {
            this.lastRevision = this.tag.value();
        };

        JumpIfNotModifiedOpcode.prototype.toJSON = function () {
            return {
                args: [JSON.stringify(this.target.inspect())],
                guid: this._guid,
                type: this.type
            };
        };

        return JumpIfNotModifiedOpcode;
    }(UpdatingOpcode);
    var DidModifyOpcode = function (_UpdatingOpcode3) {
        _inherits$5(DidModifyOpcode, _UpdatingOpcode3);

        function DidModifyOpcode(target) {
            _classCallCheck$6(this, DidModifyOpcode);

            var _this3 = _possibleConstructorReturn$5(this, _UpdatingOpcode3.call(this));

            _this3.target = target;
            _this3.type = 'did-modify';
            _this3.tag = _reference2.CONSTANT_TAG;
            return _this3;
        }

        DidModifyOpcode.prototype.evaluate = function () {
            this.target.didModify();
        };

        return DidModifyOpcode;
    }(UpdatingOpcode);
    var LabelOpcode = function () {
        function LabelOpcode(label) {
            _classCallCheck$6(this, LabelOpcode);

            this.tag = _reference2.CONSTANT_TAG;
            this.type = 'label';
            this.label = null;
            this.prev = null;
            this.next = null;
            (0, _util.initializeGuid)(this);
            this.label = label;
        }

        LabelOpcode.prototype.evaluate = function () {};

        LabelOpcode.prototype.inspect = function () {
            return this.label + ' [' + this._guid + ']';
        };

        LabelOpcode.prototype.toJSON = function () {
            return {
                args: [JSON.stringify(this.inspect())],
                guid: this._guid,
                type: this.type
            };
        };

        return LabelOpcode;
    }();

    function _defaults$4(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$4(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$4(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$4(subClass, superClass);
    }

    function _classCallCheck$5(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    APPEND_OPCODES.add(24 /* Text */, function (vm, _ref) {
        var text = _ref.op1;

        vm.elements().appendText(vm.constants.getString(text));
    });
    APPEND_OPCODES.add(25 /* Comment */, function (vm, _ref2) {
        var text = _ref2.op1;

        vm.elements().appendComment(vm.constants.getString(text));
    });
    APPEND_OPCODES.add(27 /* OpenElement */, function (vm, _ref3) {
        var tag = _ref3.op1;

        vm.elements().openElement(vm.constants.getString(tag));
    });
    APPEND_OPCODES.add(28 /* OpenElementWithOperations */, function (vm, _ref4) {
        var tag = _ref4.op1;

        var tagName = vm.constants.getString(tag);
        var operations = vm.stack.pop();
        vm.elements().openElement(tagName, operations);
    });
    APPEND_OPCODES.add(29 /* OpenDynamicElement */, function (vm) {
        var operations = vm.stack.pop();
        var tagName = vm.stack.pop().value();
        vm.elements().openElement(tagName, operations);
    });
    APPEND_OPCODES.add(36 /* PushRemoteElement */, function (vm) {
        var elementRef = vm.stack.pop(),
            cache,
            _cache;
        var nextSiblingRef = vm.stack.pop();
        var element = void 0;
        var nextSibling = void 0;
        if ((0, _reference2.isConst)(elementRef)) {
            element = elementRef.value();
        } else {
            cache = new _reference2.ReferenceCache(elementRef);

            element = cache.peek();
            vm.updateWith(new Assert(cache));
        }
        if ((0, _reference2.isConst)(nextSiblingRef)) {
            nextSibling = nextSiblingRef.value();
        } else {
            _cache = new _reference2.ReferenceCache(nextSiblingRef);

            nextSibling = _cache.peek();
            vm.updateWith(new Assert(_cache));
        }
        vm.elements().pushRemoteElement(element, nextSibling);
    });
    APPEND_OPCODES.add(37 /* PopRemoteElement */, function (vm) {
        return vm.elements().popRemoteElement();
    });

    var ClassList = function () {
        function ClassList() {
            _classCallCheck$5(this, ClassList);

            this.list = null;
            this.isConst = true;
        }

        ClassList.prototype.append = function (reference$$1) {
            var list = this.list,
                isConst$$1 = this.isConst;

            if (list === null) list = this.list = [];
            list.push(reference$$1);
            this.isConst = isConst$$1 && (0, _reference2.isConst)(reference$$1);
        };

        ClassList.prototype.toReference = function () {
            var list = this.list,
                isConst$$1 = this.isConst;

            if (!list) return NULL_REFERENCE;
            if (isConst$$1) return PrimitiveReference.create(toClassName(list));
            return new ClassListReference(list);
        };

        return ClassList;
    }();

    var ClassListReference = function (_CachedReference) {
        _inherits$4(ClassListReference, _CachedReference);

        function ClassListReference(list) {
            _classCallCheck$5(this, ClassListReference);

            var _this = _possibleConstructorReturn$4(this, _CachedReference.call(this));

            _this.list = [];
            _this.tag = (0, _reference2.combineTagged)(list);
            _this.list = list;
            return _this;
        }

        ClassListReference.prototype.compute = function () {
            return toClassName(this.list);
        };

        return ClassListReference;
    }(_reference2.CachedReference);

    function toClassName(list) {
        var ret = [],
            i,
            value;
        for (i = 0; i < list.length; i++) {
            value = list[i].value();

            if (value !== false && value !== null && value !== undefined) ret.push(value);
        }
        return ret.length === 0 ? null : ret.join(' ');
    }
    var SimpleElementOperations = function () {
        function SimpleElementOperations(env) {
            _classCallCheck$5(this, SimpleElementOperations);

            this.env = env;
            this.opcodes = null;
            this.classList = null;
        }

        SimpleElementOperations.prototype.addStaticAttribute = function (element, name, value) {
            if (name === 'class') {
                this.addClass(PrimitiveReference.create(value));
            } else {
                this.env.getAppendOperations().setAttribute(element, name, value);
            }
        };

        SimpleElementOperations.prototype.addStaticAttributeNS = function (element, namespace, name, value) {
            this.env.getAppendOperations().setAttribute(element, name, value, namespace);
        };

        SimpleElementOperations.prototype.addDynamicAttribute = function (element, name, reference$$1, isTrusting) {
            var attributeManager, attribute;

            if (name === 'class') {
                this.addClass(reference$$1);
            } else {
                attributeManager = this.env.attributeFor(element, name, isTrusting);
                attribute = new DynamicAttribute(element, attributeManager, name, reference$$1);

                this.addAttribute(attribute);
            }
        };

        SimpleElementOperations.prototype.addDynamicAttributeNS = function (element, namespace, name, reference$$1, isTrusting) {
            var attributeManager = this.env.attributeFor(element, name, isTrusting, namespace);
            var nsAttribute = new DynamicAttribute(element, attributeManager, name, reference$$1, namespace);
            this.addAttribute(nsAttribute);
        };

        SimpleElementOperations.prototype.flush = function (element, vm) {
            var env = vm.env,
                i,
                attributeManager,
                attribute,
                opcode;
            var opcodes = this.opcodes,
                classList = this.classList;

            for (i = 0; opcodes && i < opcodes.length; i++) {
                vm.updateWith(opcodes[i]);
            }
            if (classList) {
                attributeManager = env.attributeFor(element, 'class', false);
                attribute = new DynamicAttribute(element, attributeManager, 'class', classList.toReference());
                opcode = attribute.flush(env);

                if (opcode) {
                    vm.updateWith(opcode);
                }
            }
            this.opcodes = null;
            this.classList = null;
        };

        SimpleElementOperations.prototype.addClass = function (reference$$1) {
            var classList = this.classList;

            if (!classList) {
                classList = this.classList = new ClassList();
            }
            classList.append(reference$$1);
        };

        SimpleElementOperations.prototype.addAttribute = function (attribute) {
            var opcode = attribute.flush(this.env),
                opcodes;
            if (opcode) {
                opcodes = this.opcodes;


                if (!opcodes) {
                    opcodes = this.opcodes = [];
                }
                opcodes.push(opcode);
            }
        };

        return SimpleElementOperations;
    }();
    var ComponentElementOperations = function () {
        function ComponentElementOperations(env) {
            _classCallCheck$5(this, ComponentElementOperations);

            this.env = env;
            this.attributeNames = null;
            this.attributes = null;
            this.classList = null;
        }

        ComponentElementOperations.prototype.addStaticAttribute = function (element, name, value) {
            if (name === 'class') {
                this.addClass(PrimitiveReference.create(value));
            } else if (this.shouldAddAttribute(name)) {
                this.addAttribute(name, new StaticAttribute(element, name, value));
            }
        };

        ComponentElementOperations.prototype.addStaticAttributeNS = function (element, namespace, name, value) {
            if (this.shouldAddAttribute(name)) {
                this.addAttribute(name, new StaticAttribute(element, name, value, namespace));
            }
        };

        ComponentElementOperations.prototype.addDynamicAttribute = function (element, name, reference$$1, isTrusting) {
            var attributeManager, attribute;

            if (name === 'class') {
                this.addClass(reference$$1);
            } else if (this.shouldAddAttribute(name)) {
                attributeManager = this.env.attributeFor(element, name, isTrusting);
                attribute = new DynamicAttribute(element, attributeManager, name, reference$$1);

                this.addAttribute(name, attribute);
            }
        };

        ComponentElementOperations.prototype.addDynamicAttributeNS = function (element, namespace, name, reference$$1, isTrusting) {
            var attributeManager, nsAttribute;

            if (this.shouldAddAttribute(name)) {
                attributeManager = this.env.attributeFor(element, name, isTrusting, namespace);
                nsAttribute = new DynamicAttribute(element, attributeManager, name, reference$$1, namespace);

                this.addAttribute(name, nsAttribute);
            }
        };

        ComponentElementOperations.prototype.flush = function (element, vm) {
            var env = this.env,
                i,
                opcode,
                attributeManager,
                attribute,
                _opcode;
            var attributes = this.attributes,
                classList = this.classList;

            for (i = 0; attributes && i < attributes.length; i++) {
                opcode = attributes[i].flush(env);

                if (opcode) {
                    vm.updateWith(opcode);
                }
            }
            if (classList) {
                attributeManager = env.attributeFor(element, 'class', false);
                attribute = new DynamicAttribute(element, attributeManager, 'class', classList.toReference());
                _opcode = attribute.flush(env);

                if (_opcode) {
                    vm.updateWith(_opcode);
                }
            }
        };

        ComponentElementOperations.prototype.shouldAddAttribute = function (name) {
            return !this.attributeNames || this.attributeNames.indexOf(name) === -1;
        };

        ComponentElementOperations.prototype.addClass = function (reference$$1) {
            var classList = this.classList;

            if (!classList) {
                classList = this.classList = new ClassList();
            }
            classList.append(reference$$1);
        };

        ComponentElementOperations.prototype.addAttribute = function (name, attribute) {
            var attributeNames = this.attributeNames,
                attributes = this.attributes;

            if (!attributeNames) {
                attributeNames = this.attributeNames = [];
                attributes = this.attributes = [];
            }
            attributeNames.push(name);
            attributes.push(attribute);
        };

        return ComponentElementOperations;
    }();
    APPEND_OPCODES.add(33 /* FlushElement */, function (vm) {
        var stack = vm.elements();
        var action = 'FlushElementOpcode#evaluate';
        stack.expectOperations(action).flush(stack.expectConstructing(action), vm);
        stack.flushElement();
    });
    APPEND_OPCODES.add(34 /* CloseElement */, function (vm) {
        return vm.elements().closeElement();
    });
    APPEND_OPCODES.add(30 /* StaticAttr */, function (vm, _ref5) {
        var _name = _ref5.op1,
            _value = _ref5.op2,
            _namespace = _ref5.op3,
            namespace;

        var name = vm.constants.getString(_name);
        var value = vm.constants.getString(_value);
        if (_namespace) {
            namespace = vm.constants.getString(_namespace);

            vm.elements().setStaticAttributeNS(namespace, name, value);
        } else {
            vm.elements().setStaticAttribute(name, value);
        }
    });
    APPEND_OPCODES.add(35 /* Modifier */, function (vm, _ref6) {
        var _manager = _ref6.op1;

        var manager = vm.constants.getOther(_manager);
        var stack = vm.stack;
        var args = stack.pop();
        var tag = args.tag;

        var _vm$elements = vm.elements(),
            element = _vm$elements.constructing,
            updateOperations = _vm$elements.updateOperations;

        var dynamicScope = vm.dynamicScope();
        var modifier = manager.create(element, args, dynamicScope, updateOperations);
        args.clear();
        vm.env.scheduleInstallModifier(modifier, manager);
        var destructor = manager.getDestructor(modifier);
        if (destructor) {
            vm.newDestroyable(destructor);
        }
        vm.updateWith(new UpdateModifierOpcode(tag, manager, modifier));
    });
    var UpdateModifierOpcode = function (_UpdatingOpcode) {
        _inherits$4(UpdateModifierOpcode, _UpdatingOpcode);

        function UpdateModifierOpcode(tag, manager, modifier) {
            _classCallCheck$5(this, UpdateModifierOpcode);

            var _this2 = _possibleConstructorReturn$4(this, _UpdatingOpcode.call(this));

            _this2.tag = tag;
            _this2.manager = manager;
            _this2.modifier = modifier;
            _this2.type = 'update-modifier';
            _this2.lastUpdated = tag.value();
            return _this2;
        }

        UpdateModifierOpcode.prototype.evaluate = function (vm) {
            var manager = this.manager,
                modifier = this.modifier,
                tag = this.tag,
                lastUpdated = this.lastUpdated;

            if (!tag.validate(lastUpdated)) {
                vm.env.scheduleUpdateModifier(modifier, manager);
                this.lastUpdated = tag.value();
            }
        };

        UpdateModifierOpcode.prototype.toJSON = function () {
            return {
                guid: this._guid,
                type: this.type
            };
        };

        return UpdateModifierOpcode;
    }(UpdatingOpcode);
    var StaticAttribute = function () {
        function StaticAttribute(element, name, value, namespace) {
            _classCallCheck$5(this, StaticAttribute);

            this.element = element;
            this.name = name;
            this.value = value;
            this.namespace = namespace;
        }

        StaticAttribute.prototype.flush = function (env) {
            env.getAppendOperations().setAttribute(this.element, this.name, this.value, this.namespace);
            return null;
        };

        return StaticAttribute;
    }();
    var DynamicAttribute = function () {
        function DynamicAttribute(element, attributeManager, name, reference$$1, namespace) {
            _classCallCheck$5(this, DynamicAttribute);

            this.element = element;
            this.attributeManager = attributeManager;
            this.name = name;
            this.reference = reference$$1;
            this.namespace = namespace;
            this.cache = null;
            this.tag = reference$$1.tag;
        }

        DynamicAttribute.prototype.patch = function (env) {
            var element = this.element,
                cache = this.cache;

            var value = cache.revalidate();
            if ((0, _reference2.isModified)(value)) {
                this.attributeManager.updateAttribute(env, element, value, this.namespace);
            }
        };

        DynamicAttribute.prototype.flush = function (env) {
            var reference$$1 = this.reference,
                element = this.element,
                value,
                cache,
                _value2;

            if ((0, _reference2.isConst)(reference$$1)) {
                value = reference$$1.value();

                this.attributeManager.setAttribute(env, element, value, this.namespace);
                return null;
            } else {
                cache = this.cache = new _reference2.ReferenceCache(reference$$1);
                _value2 = cache.peek();

                this.attributeManager.setAttribute(env, element, _value2, this.namespace);
                return new PatchElementOpcode(this);
            }
        };

        DynamicAttribute.prototype.toJSON = function () {
            var element = this.element,
                namespace = this.namespace,
                name = this.name,
                cache = this.cache;

            var formattedElement = formatElement(element);
            var lastValue = cache.peek();
            if (namespace) {
                return {
                    element: formattedElement,
                    lastValue: lastValue,
                    name: name,
                    namespace: namespace,
                    type: 'attribute'
                };
            }
            return {
                element: formattedElement,
                lastValue: lastValue,
                name: name,
                namespace: namespace === undefined ? null : namespace,
                type: 'attribute'
            };
        };

        return DynamicAttribute;
    }();
    function formatElement(element) {
        return JSON.stringify('<' + element.tagName.toLowerCase() + ' />');
    }
    APPEND_OPCODES.add(32 /* DynamicAttrNS */, function (vm, _ref7) {
        var _name = _ref7.op1,
            _namespace = _ref7.op2,
            trusting = _ref7.op3;

        var name = vm.constants.getString(_name);
        var namespace = vm.constants.getString(_namespace);
        var reference$$1 = vm.stack.pop();
        vm.elements().setDynamicAttributeNS(namespace, name, reference$$1, !!trusting);
    });
    APPEND_OPCODES.add(31 /* DynamicAttr */, function (vm, _ref8) {
        var _name = _ref8.op1,
            trusting = _ref8.op2;

        var name = vm.constants.getString(_name);
        var reference$$1 = vm.stack.pop();
        vm.elements().setDynamicAttribute(name, reference$$1, !!trusting);
    });
    var PatchElementOpcode = function (_UpdatingOpcode2) {
        _inherits$4(PatchElementOpcode, _UpdatingOpcode2);

        function PatchElementOpcode(operation) {
            _classCallCheck$5(this, PatchElementOpcode);

            var _this3 = _possibleConstructorReturn$4(this, _UpdatingOpcode2.call(this));

            _this3.type = 'patch-element';
            _this3.tag = operation.tag;
            _this3.operation = operation;
            return _this3;
        }

        PatchElementOpcode.prototype.evaluate = function (vm) {
            this.operation.patch(vm.env);
        };

        PatchElementOpcode.prototype.toJSON = function () {
            var _guid = this._guid,
                type = this.type,
                operation = this.operation;

            return {
                details: operation.toJSON(),
                guid: _guid,
                type: type
            };
        };

        return PatchElementOpcode;
    }(UpdatingOpcode);

    function _defaults$3(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$3(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$3(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$3(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$3(subClass, superClass);
    }

    APPEND_OPCODES.add(56 /* PushComponentManager */, function (vm, _ref) {
        var _definition = _ref.op1;

        var definition = vm.constants.getOther(_definition);
        var stack = vm.stack;
        stack.push({ definition: definition, manager: definition.manager, component: null });
    });
    APPEND_OPCODES.add(57 /* PushDynamicComponentManager */, function (vm) {
        var stack = vm.stack;
        var reference$$1 = stack.pop();
        var cache = (0, _reference2.isConst)(reference$$1) ? undefined : new _reference2.ReferenceCache(reference$$1);
        var definition = cache ? cache.peek() : reference$$1.value();
        stack.push({ definition: definition, manager: definition.manager, component: null });
        if (cache) {
            vm.updateWith(new Assert(cache));
        }
    });
    APPEND_OPCODES.add(58 /* PushArgs */, function (vm, _ref2) {
        var synthetic = _ref2.op1;

        var stack = vm.stack;
        ARGS.setup(stack, !!synthetic);
        stack.push(ARGS);
    });
    APPEND_OPCODES.add(59 /* PrepareArgs */, function (vm, _ref3) {
        var _state = _ref3.op1,
            positional,
            named,
            positionalCount,
            i,
            names,
            namedCount,
            atNames,
            _i,
            value,
            atName;

        var stack = vm.stack;

        var _vm$fetchValue = vm.fetchValue(_state),
            definition = _vm$fetchValue.definition,
            manager = _vm$fetchValue.manager;

        var args = stack.pop();
        var preparedArgs = manager.prepareArgs(definition, args);
        if (preparedArgs) {
            args.clear();
            positional = preparedArgs.positional, named = preparedArgs.named;
            positionalCount = positional.length;

            for (i = 0; i < positionalCount; i++) {
                stack.push(positional[i]);
            }
            stack.push(positionalCount);
            names = Object.keys(named);
            namedCount = names.length;
            atNames = [];

            for (_i = 0; _i < namedCount; _i++) {
                value = named[names[_i]];
                atName = '@' + names[_i];

                stack.push(value);
                atNames.push(atName);
            }
            stack.push(atNames);
            args.setup(stack, false);
        }
        stack.push(args);
    });
    APPEND_OPCODES.add(60 /* CreateComponent */, function (vm, _ref4) {
        var _vm$fetchValue2;

        var flags = _ref4.op1,
            _state = _ref4.op2;

        var definition = void 0;
        var manager = void 0;
        var args = vm.stack.pop();
        var dynamicScope = vm.dynamicScope();
        var state = (_vm$fetchValue2 = vm.fetchValue(_state), definition = _vm$fetchValue2.definition, manager = _vm$fetchValue2.manager, _vm$fetchValue2);

        var component = manager.create(vm.env, definition, args, dynamicScope, vm.getSelf(), !!(flags & 1));
        state.component = component;
        vm.updateWith(new UpdateComponentOpcode(args.tag, definition.name, component, manager, dynamicScope));
    });
    APPEND_OPCODES.add(61 /* RegisterComponentDestructor */, function (vm, _ref5) {
        var _state = _ref5.op1;

        var _vm$fetchValue3 = vm.fetchValue(_state),
            manager = _vm$fetchValue3.manager,
            component = _vm$fetchValue3.component;

        var destructor = manager.getDestructor(component);
        if (destructor) vm.newDestroyable(destructor);
    });
    APPEND_OPCODES.add(65 /* BeginComponentTransaction */, function (vm) {
        vm.beginCacheGroup();
        vm.elements().pushSimpleBlock();
    });
    APPEND_OPCODES.add(62 /* PushComponentOperations */, function (vm) {
        vm.stack.push(new ComponentElementOperations(vm.env));
    });
    APPEND_OPCODES.add(67 /* DidCreateElement */, function (vm, _ref6) {
        var _state = _ref6.op1;

        var _vm$fetchValue4 = vm.fetchValue(_state),
            manager = _vm$fetchValue4.manager,
            component = _vm$fetchValue4.component;

        var action = 'DidCreateElementOpcode#evaluate';
        manager.didCreateElement(component, vm.elements().expectConstructing(action), vm.elements().expectOperations(action));
    });
    APPEND_OPCODES.add(63 /* GetComponentSelf */, function (vm, _ref7) {
        var _state = _ref7.op1;

        var state = vm.fetchValue(_state);
        vm.stack.push(state.manager.getSelf(state.component));
    });
    APPEND_OPCODES.add(64 /* GetComponentLayout */, function (vm, _ref8) {
        var _state = _ref8.op1;

        var _vm$fetchValue5 = vm.fetchValue(_state),
            manager = _vm$fetchValue5.manager,
            definition = _vm$fetchValue5.definition,
            component = _vm$fetchValue5.component;

        vm.stack.push(manager.layoutFor(definition, component, vm.env));
    });
    APPEND_OPCODES.add(68 /* DidRenderLayout */, function (vm, _ref9) {
        var _state = _ref9.op1;

        var _vm$fetchValue6 = vm.fetchValue(_state),
            manager = _vm$fetchValue6.manager,
            component = _vm$fetchValue6.component;

        var bounds = vm.elements().popBlock();
        manager.didRenderLayout(component, bounds);
        vm.env.didCreate(component, manager);
        vm.updateWith(new DidUpdateLayoutOpcode(manager, component, bounds));
    });
    APPEND_OPCODES.add(66 /* CommitComponentTransaction */, function (vm) {
        return vm.commitCacheGroup();
    });
    var UpdateComponentOpcode = function (_UpdatingOpcode) {
        _inherits$3(UpdateComponentOpcode, _UpdatingOpcode);

        function UpdateComponentOpcode(tag, name, component, manager, dynamicScope) {
            _classCallCheck$3(this, UpdateComponentOpcode);

            var _this = _possibleConstructorReturn$3(this, _UpdatingOpcode.call(this));

            _this.name = name;
            _this.component = component;
            _this.manager = manager;
            _this.dynamicScope = dynamicScope;
            _this.type = 'update-component';
            var componentTag = manager.getTag(component);
            if (componentTag) {
                _this.tag = (0, _reference2.combine)([tag, componentTag]);
            } else {
                _this.tag = tag;
            }
            return _this;
        }

        UpdateComponentOpcode.prototype.evaluate = function () {
            var component = this.component,
                manager = this.manager,
                dynamicScope = this.dynamicScope;

            manager.update(component, dynamicScope);
        };

        UpdateComponentOpcode.prototype.toJSON = function () {
            return {
                args: [JSON.stringify(this.name)],
                guid: this._guid,
                type: this.type
            };
        };

        return UpdateComponentOpcode;
    }(UpdatingOpcode);
    var DidUpdateLayoutOpcode = function (_UpdatingOpcode2) {
        _inherits$3(DidUpdateLayoutOpcode, _UpdatingOpcode2);

        function DidUpdateLayoutOpcode(manager, component, bounds) {
            _classCallCheck$3(this, DidUpdateLayoutOpcode);

            var _this2 = _possibleConstructorReturn$3(this, _UpdatingOpcode2.call(this));

            _this2.manager = manager;
            _this2.component = component;
            _this2.bounds = bounds;
            _this2.type = 'did-update-layout';
            _this2.tag = _reference2.CONSTANT_TAG;
            return _this2;
        }

        DidUpdateLayoutOpcode.prototype.evaluate = function (vm) {
            var manager = this.manager,
                component = this.component,
                bounds = this.bounds;

            manager.didUpdateLayout(component, bounds);
            vm.env.didUpdate(component, manager);
        };

        return DidUpdateLayoutOpcode;
    }(UpdatingOpcode);

    function _classCallCheck$8(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Cursor = function Cursor(element, nextSibling) {
        _classCallCheck$8(this, Cursor);

        this.element = element;
        this.nextSibling = nextSibling;
    };

    var ConcreteBounds = function () {
        function ConcreteBounds(parentNode, first, last) {
            _classCallCheck$8(this, ConcreteBounds);

            this.parentNode = parentNode;
            this.first = first;
            this.last = last;
        }

        ConcreteBounds.prototype.parentElement = function () {
            return this.parentNode;
        };

        ConcreteBounds.prototype.firstNode = function () {
            return this.first;
        };

        ConcreteBounds.prototype.lastNode = function () {
            return this.last;
        };

        return ConcreteBounds;
    }();
    var SingleNodeBounds = function () {
        function SingleNodeBounds(parentNode, node) {
            _classCallCheck$8(this, SingleNodeBounds);

            this.parentNode = parentNode;
            this.node = node;
        }

        SingleNodeBounds.prototype.parentElement = function () {
            return this.parentNode;
        };

        SingleNodeBounds.prototype.firstNode = function () {
            return this.node;
        };

        SingleNodeBounds.prototype.lastNode = function () {
            return this.node;
        };

        return SingleNodeBounds;
    }();

    function single(parent, node) {
        return new SingleNodeBounds(parent, node);
    }
    function move(bounds, reference$$1) {
        var parent = bounds.parentElement(),
            next;
        var first = bounds.firstNode();
        var last = bounds.lastNode();
        var node = first;
        while (node) {
            next = node.nextSibling;

            parent.insertBefore(node, reference$$1);
            if (node === last) return next;
            node = next;
        }
        return null;
    }
    function clear(bounds) {
        var parent = bounds.parentElement(),
            next;
        var first = bounds.firstNode();
        var last = bounds.lastNode();
        var node = first;
        while (node) {
            next = node.nextSibling;

            parent.removeChild(node);
            if (node === last) return next;
            node = next;
        }
        return null;
    }

    function _defaults$7(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$7(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$7(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$7(subClass, superClass);
    }

    function _classCallCheck$9(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var First = function () {
        function First(node) {
            _classCallCheck$9(this, First);

            this.node = node;
        }

        First.prototype.firstNode = function () {
            return this.node;
        };

        return First;
    }();

    var Last = function () {
        function Last(node) {
            _classCallCheck$9(this, Last);

            this.node = node;
        }

        Last.prototype.lastNode = function () {
            return this.node;
        };

        return Last;
    }();

    var Fragment = function () {
        function Fragment(bounds$$1) {
            _classCallCheck$9(this, Fragment);

            this.bounds = bounds$$1;
        }

        Fragment.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        Fragment.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        Fragment.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        Fragment.prototype.update = function (bounds$$1) {
            this.bounds = bounds$$1;
        };

        return Fragment;
    }();
    var ElementStack = function () {
        function ElementStack(env, parentNode, nextSibling) {
            _classCallCheck$9(this, ElementStack);

            this.constructing = null;
            this.operations = null;
            this.elementStack = new _util.Stack();
            this.nextSiblingStack = new _util.Stack();
            this.blockStack = new _util.Stack();
            this.env = env;
            this.dom = env.getAppendOperations();
            this.updateOperations = env.getDOM();
            this.element = parentNode;
            this.nextSibling = nextSibling;
            this.defaultOperations = new SimpleElementOperations(env);
            this.pushSimpleBlock();
            this.elementStack.push(this.element);
            this.nextSiblingStack.push(this.nextSibling);
        }

        ElementStack.forInitialRender = function (env, parentNode, nextSibling) {
            return new ElementStack(env, parentNode, nextSibling);
        };

        ElementStack.resume = function (env, tracker, nextSibling) {
            var parentNode = tracker.parentElement();
            var stack = new ElementStack(env, parentNode, nextSibling);
            stack.pushBlockTracker(tracker);
            return stack;
        };

        ElementStack.prototype.expectConstructing = function () {
            return this.constructing;
        };

        ElementStack.prototype.expectOperations = function () {
            return this.operations;
        };

        ElementStack.prototype.block = function () {
            return this.blockStack.current;
        };

        ElementStack.prototype.popElement = function () {
            var elementStack = this.elementStack,
                nextSiblingStack = this.nextSiblingStack;

            var topElement = elementStack.pop();
            nextSiblingStack.pop();
            // LOGGER.debug(`-> element stack ${this.elementStack.toArray().map(e => e.tagName).join(', ')}`);
            this.element = elementStack.current;
            this.nextSibling = nextSiblingStack.current;
            return topElement;
        };

        ElementStack.prototype.pushSimpleBlock = function () {
            var tracker = new SimpleBlockTracker(this.element);
            this.pushBlockTracker(tracker);
            return tracker;
        };

        ElementStack.prototype.pushUpdatableBlock = function () {
            var tracker = new UpdatableBlockTracker(this.element);
            this.pushBlockTracker(tracker);
            return tracker;
        };

        ElementStack.prototype.pushBlockTracker = function (tracker) {
            var isRemote = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var current = this.blockStack.current;
            if (current !== null) {
                current.newDestroyable(tracker);
                if (!isRemote) {
                    current.newBounds(tracker);
                }
            }
            this.blockStack.push(tracker);
            return tracker;
        };

        ElementStack.prototype.pushBlockList = function (list) {
            var tracker = new BlockListTracker(this.element, list);
            var current = this.blockStack.current;
            if (current !== null) {
                current.newDestroyable(tracker);
                current.newBounds(tracker);
            }
            this.blockStack.push(tracker);
            return tracker;
        };

        ElementStack.prototype.popBlock = function () {
            this.block().finalize(this);
            return this.blockStack.pop();
        };

        ElementStack.prototype.openElement = function (tag, _operations) {
            // workaround argument.length transpile of arg initializer
            var operations = _operations === undefined ? this.defaultOperations : _operations;
            var element = this.dom.createElement(tag, this.element);
            this.constructing = element;
            this.operations = operations;
            return element;
        };

        ElementStack.prototype.flushElement = function () {
            var parent = this.element;
            var element = this.constructing;
            this.dom.insertBefore(parent, element, this.nextSibling);
            this.constructing = null;
            this.operations = null;
            this.pushElement(element, null);
            this.block().openElement(element);
        };

        ElementStack.prototype.pushRemoteElement = function (element) {
            var nextSibling = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            this.pushElement(element, nextSibling);
            var tracker = new RemoteBlockTracker(element);
            this.pushBlockTracker(tracker, true);
        };

        ElementStack.prototype.popRemoteElement = function () {
            this.popBlock();
            this.popElement();
        };

        ElementStack.prototype.pushElement = function (element, nextSibling) {
            this.element = element;
            this.elementStack.push(element);
            // LOGGER.debug(`-> element stack ${this.elementStack.toArray().map(e => e.tagName).join(', ')}`);
            this.nextSibling = nextSibling;
            this.nextSiblingStack.push(nextSibling);
        };

        ElementStack.prototype.newDestroyable = function (d) {
            this.block().newDestroyable(d);
        };

        ElementStack.prototype.newBounds = function (bounds$$1) {
            this.block().newBounds(bounds$$1);
        };

        ElementStack.prototype.appendText = function (string) {
            var dom = this.dom;

            var text = dom.createTextNode(string);
            dom.insertBefore(this.element, text, this.nextSibling);
            this.block().newNode(text);
            return text;
        };

        ElementStack.prototype.appendComment = function (string) {
            var dom = this.dom;

            var comment = dom.createComment(string);
            dom.insertBefore(this.element, comment, this.nextSibling);
            this.block().newNode(comment);
            return comment;
        };

        ElementStack.prototype.setStaticAttribute = function (name, value) {
            this.expectOperations('setStaticAttribute').addStaticAttribute(this.expectConstructing('setStaticAttribute'), name, value);
        };

        ElementStack.prototype.setStaticAttributeNS = function (namespace, name, value) {
            this.expectOperations('setStaticAttributeNS').addStaticAttributeNS(this.expectConstructing('setStaticAttributeNS'), namespace, name, value);
        };

        ElementStack.prototype.setDynamicAttribute = function (name, reference$$1, isTrusting) {
            this.expectOperations('setDynamicAttribute').addDynamicAttribute(this.expectConstructing('setDynamicAttribute'), name, reference$$1, isTrusting);
        };

        ElementStack.prototype.setDynamicAttributeNS = function (namespace, name, reference$$1, isTrusting) {
            this.expectOperations('setDynamicAttributeNS').addDynamicAttributeNS(this.expectConstructing('setDynamicAttributeNS'), namespace, name, reference$$1, isTrusting);
        };

        ElementStack.prototype.closeElement = function () {
            this.block().closeElement();
            this.popElement();
        };

        return ElementStack;
    }();
    var SimpleBlockTracker = function () {
        function SimpleBlockTracker(parent) {
            _classCallCheck$9(this, SimpleBlockTracker);

            this.parent = parent;
            this.first = null;
            this.last = null;
            this.destroyables = null;
            this.nesting = 0;
        }

        SimpleBlockTracker.prototype.destroy = function () {
            var destroyables = this.destroyables,
                i;

            if (destroyables && destroyables.length) {
                for (i = 0; i < destroyables.length; i++) {
                    destroyables[i].destroy();
                }
            }
        };

        SimpleBlockTracker.prototype.parentElement = function () {
            return this.parent;
        };

        SimpleBlockTracker.prototype.firstNode = function () {
            return this.first && this.first.firstNode();
        };

        SimpleBlockTracker.prototype.lastNode = function () {
            return this.last && this.last.lastNode();
        };

        SimpleBlockTracker.prototype.openElement = function (element) {
            this.newNode(element);
            this.nesting++;
        };

        SimpleBlockTracker.prototype.closeElement = function () {
            this.nesting--;
        };

        SimpleBlockTracker.prototype.newNode = function (node) {
            if (this.nesting !== 0) return;
            if (!this.first) {
                this.first = new First(node);
            }
            this.last = new Last(node);
        };

        SimpleBlockTracker.prototype.newBounds = function (bounds$$1) {
            if (this.nesting !== 0) return;
            if (!this.first) {
                this.first = bounds$$1;
            }
            this.last = bounds$$1;
        };

        SimpleBlockTracker.prototype.newDestroyable = function (d) {
            this.destroyables = this.destroyables || [];
            this.destroyables.push(d);
        };

        SimpleBlockTracker.prototype.finalize = function (stack) {
            if (!this.first) {
                stack.appendComment('');
            }
        };

        return SimpleBlockTracker;
    }();

    var RemoteBlockTracker = function (_SimpleBlockTracker) {
        _inherits$7(RemoteBlockTracker, _SimpleBlockTracker);

        function RemoteBlockTracker() {
            _classCallCheck$9(this, RemoteBlockTracker);

            return _possibleConstructorReturn$7(this, _SimpleBlockTracker.apply(this, arguments));
        }

        RemoteBlockTracker.prototype.destroy = function () {
            _SimpleBlockTracker.prototype.destroy.call(this);
            clear(this);
        };

        return RemoteBlockTracker;
    }(SimpleBlockTracker);

    var UpdatableBlockTracker = function (_SimpleBlockTracker2) {
        _inherits$7(UpdatableBlockTracker, _SimpleBlockTracker2);

        function UpdatableBlockTracker() {
            _classCallCheck$9(this, UpdatableBlockTracker);

            return _possibleConstructorReturn$7(this, _SimpleBlockTracker2.apply(this, arguments));
        }

        UpdatableBlockTracker.prototype.reset = function (env) {
            var destroyables = this.destroyables,
                i;

            if (destroyables && destroyables.length) {
                for (i = 0; i < destroyables.length; i++) {
                    env.didDestroy(destroyables[i]);
                }
            }
            var nextSibling = clear(this);
            this.first = null;
            this.last = null;
            this.destroyables = null;
            this.nesting = 0;
            return nextSibling;
        };

        return UpdatableBlockTracker;
    }(SimpleBlockTracker);

    var BlockListTracker = function () {
        function BlockListTracker(parent, boundList) {
            _classCallCheck$9(this, BlockListTracker);

            this.parent = parent;
            this.boundList = boundList;
            this.parent = parent;
            this.boundList = boundList;
        }

        BlockListTracker.prototype.destroy = function () {
            this.boundList.forEachNode(function (node) {
                return node.destroy();
            });
        };

        BlockListTracker.prototype.parentElement = function () {
            return this.parent;
        };

        BlockListTracker.prototype.firstNode = function () {
            var head = this.boundList.head();
            return head && head.firstNode();
        };

        BlockListTracker.prototype.lastNode = function () {
            var tail = this.boundList.tail();
            return tail && tail.lastNode();
        };

        BlockListTracker.prototype.openElement = function () {
            (0, _util.assert)(false, 'Cannot openElement directly inside a block list');
        };

        BlockListTracker.prototype.closeElement = function () {
            (0, _util.assert)(false, 'Cannot closeElement directly inside a block list');
        };

        BlockListTracker.prototype.newNode = function () {
            (0, _util.assert)(false, 'Cannot create a new node directly inside a block list');
        };

        BlockListTracker.prototype.newBounds = function () {};

        BlockListTracker.prototype.newDestroyable = function () {};

        BlockListTracker.prototype.finalize = function () {};

        return BlockListTracker;
    }();

    function _classCallCheck$10(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var COMPONENT_DEFINITION_BRAND = 'COMPONENT DEFINITION [id=e59c754e-61eb-4392-8c4a-2c0ac72bfcd4]';
    function isComponentDefinition(obj) {
        return typeof obj === 'object' && obj !== null && obj[COMPONENT_DEFINITION_BRAND];
    }


    function _defaults$8(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$8(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$8(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$8(subClass, superClass);
    }

    function _classCallCheck$11(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function isSafeString(value) {
        return typeof value === 'object' && value !== null && typeof value.toHTML === 'function';
    }
    function isNode(value) {
        return typeof value === 'object' && value !== null && typeof value.nodeType === 'number';
    }
    function isString(value) {
        return typeof value === 'string';
    }

    var Upsert = function Upsert(bounds$$1) {
        _classCallCheck$11(this, Upsert);

        this.bounds = bounds$$1;
    };

    function cautiousInsert(dom, cursor, value) {
        if (isString(value)) {
            return TextUpsert.insert(dom, cursor, value);
        }
        if (isSafeString(value)) {
            return SafeStringUpsert.insert(dom, cursor, value);
        }
        if (isNode(value)) {
            return NodeUpsert.insert(dom, cursor, value);
        }
        throw (0, _util.unreachable)();
    }
    function trustingInsert(dom, cursor, value) {
        if (isString(value)) {
            return HTMLUpsert.insert(dom, cursor, value);
        }
        if (isNode(value)) {
            return NodeUpsert.insert(dom, cursor, value);
        }
        throw (0, _util.unreachable)();
    }

    var TextUpsert = function (_Upsert) {
        _inherits$8(TextUpsert, _Upsert);

        TextUpsert.insert = function (dom, cursor, value) {
            var textNode = dom.createTextNode(value);
            dom.insertBefore(cursor.element, textNode, cursor.nextSibling);
            var bounds$$1 = new SingleNodeBounds(cursor.element, textNode);
            return new TextUpsert(bounds$$1, textNode);
        };

        function TextUpsert(bounds$$1, textNode) {
            _classCallCheck$11(this, TextUpsert);

            var _this = _possibleConstructorReturn$8(this, _Upsert.call(this, bounds$$1));

            _this.textNode = textNode;
            return _this;
        }

        TextUpsert.prototype.update = function (_dom, value) {
            var textNode;

            if (isString(value)) {
                textNode = this.textNode;


                textNode.nodeValue = value;
                return true;
            } else {
                return false;
            }
        };

        return TextUpsert;
    }(Upsert);

    var HTMLUpsert = function (_Upsert2) {
        _inherits$8(HTMLUpsert, _Upsert2);

        function HTMLUpsert() {
            _classCallCheck$11(this, HTMLUpsert);

            return _possibleConstructorReturn$8(this, _Upsert2.apply(this, arguments));
        }

        HTMLUpsert.insert = function (dom, cursor, value) {
            var bounds$$1 = dom.insertHTMLBefore(cursor.element, cursor.nextSibling, value);
            return new HTMLUpsert(bounds$$1);
        };

        HTMLUpsert.prototype.update = function (dom, value) {
            var bounds$$1, parentElement, nextSibling;

            if (isString(value)) {
                bounds$$1 = this.bounds;
                parentElement = bounds$$1.parentElement();
                nextSibling = clear(bounds$$1);

                this.bounds = dom.insertHTMLBefore(parentElement, nextSibling, value);
                return true;
            } else {
                return false;
            }
        };

        return HTMLUpsert;
    }(Upsert);

    var SafeStringUpsert = function (_Upsert3) {
        _inherits$8(SafeStringUpsert, _Upsert3);

        function SafeStringUpsert(bounds$$1, lastStringValue) {
            _classCallCheck$11(this, SafeStringUpsert);

            var _this3 = _possibleConstructorReturn$8(this, _Upsert3.call(this, bounds$$1));

            _this3.lastStringValue = lastStringValue;
            return _this3;
        }

        SafeStringUpsert.insert = function (dom, cursor, value) {
            var stringValue = value.toHTML();
            var bounds$$1 = dom.insertHTMLBefore(cursor.element, cursor.nextSibling, stringValue);
            return new SafeStringUpsert(bounds$$1, stringValue);
        };

        SafeStringUpsert.prototype.update = function (dom, value) {
            var stringValue, bounds$$1, parentElement, nextSibling;

            if (isSafeString(value)) {
                stringValue = value.toHTML();

                if (stringValue !== this.lastStringValue) {
                    bounds$$1 = this.bounds;
                    parentElement = bounds$$1.parentElement();
                    nextSibling = clear(bounds$$1);

                    this.bounds = dom.insertHTMLBefore(parentElement, nextSibling, stringValue);
                    this.lastStringValue = stringValue;
                }
                return true;
            } else {
                return false;
            }
        };

        return SafeStringUpsert;
    }(Upsert);

    var NodeUpsert = function (_Upsert4) {
        _inherits$8(NodeUpsert, _Upsert4);

        function NodeUpsert() {
            _classCallCheck$11(this, NodeUpsert);

            return _possibleConstructorReturn$8(this, _Upsert4.apply(this, arguments));
        }

        NodeUpsert.insert = function (dom, cursor, node) {
            dom.insertBefore(cursor.element, node, cursor.nextSibling);
            return new NodeUpsert(single(cursor.element, node));
        };

        NodeUpsert.prototype.update = function (dom, value) {
            var bounds$$1, parentElement, nextSibling;

            if (isNode(value)) {
                bounds$$1 = this.bounds;
                parentElement = bounds$$1.parentElement();
                nextSibling = clear(bounds$$1);

                this.bounds = dom.insertNodeBefore(parentElement, value, nextSibling);
                return true;
            } else {
                return false;
            }
        };

        return NodeUpsert;
    }(Upsert);

    function _defaults$6(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$6(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$6(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$6(subClass, superClass);
    }

    function _classCallCheck$7(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    APPEND_OPCODES.add(26 /* DynamicContent */, function (vm, _ref) {
        var append = _ref.op1;

        var opcode = vm.constants.getOther(append);
        opcode.evaluate(vm);
    });
    function isEmpty(value) {
        return value === null || value === undefined || typeof value.toString !== 'function';
    }
    function normalizeTextValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        return String(value);
    }
    function normalizeTrustedValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        if (isString(value)) {
            return value;
        }
        if (isSafeString(value)) {
            return value.toHTML();
        }
        if (isNode(value)) {
            return value;
        }
        return String(value);
    }
    function normalizeValue(value) {
        if (isEmpty(value)) {
            return '';
        }
        if (isString(value)) {
            return value;
        }
        if (isSafeString(value) || isNode(value)) {
            return value;
        }
        return String(value);
    }
    var AppendDynamicOpcode = function () {
        function AppendDynamicOpcode() {
            _classCallCheck$7(this, AppendDynamicOpcode);
        }

        AppendDynamicOpcode.prototype.evaluate = function (vm) {
            var reference$$1 = vm.stack.pop();
            var normalized = this.normalize(reference$$1);
            var value = void 0;
            var cache = void 0;
            if ((0, _reference2.isConst)(reference$$1)) {
                value = normalized.value();
            } else {
                cache = new _reference2.ReferenceCache(normalized);
                value = cache.peek();
            }
            var stack = vm.elements();
            var upsert = this.insert(vm.env.getAppendOperations(), stack, value);
            var bounds$$1 = new Fragment(upsert.bounds);
            stack.newBounds(bounds$$1);
            if (cache /* i.e. !isConst(reference) */) {
                    vm.updateWith(this.updateWith(vm, reference$$1, cache, bounds$$1, upsert));
                }
        };

        return AppendDynamicOpcode;
    }();
    var IsComponentDefinitionReference = function (_ConditionalReference) {
        _inherits$6(IsComponentDefinitionReference, _ConditionalReference);

        function IsComponentDefinitionReference() {
            _classCallCheck$7(this, IsComponentDefinitionReference);

            return _possibleConstructorReturn$6(this, _ConditionalReference.apply(this, arguments));
        }

        IsComponentDefinitionReference.create = function (inner) {
            return new IsComponentDefinitionReference(inner);
        };

        IsComponentDefinitionReference.prototype.toBool = function (value) {
            return isComponentDefinition(value);
        };

        return IsComponentDefinitionReference;
    }(ConditionalReference);

    var UpdateOpcode = function (_UpdatingOpcode) {
        _inherits$6(UpdateOpcode, _UpdatingOpcode);

        function UpdateOpcode(cache, bounds$$1, upsert) {
            _classCallCheck$7(this, UpdateOpcode);

            var _this2 = _possibleConstructorReturn$6(this, _UpdatingOpcode.call(this));

            _this2.cache = cache;
            _this2.bounds = bounds$$1;
            _this2.upsert = upsert;
            _this2.tag = cache.tag;
            return _this2;
        }

        UpdateOpcode.prototype.evaluate = function (vm) {
            var value = this.cache.revalidate(),
                bounds$$1,
                upsert,
                dom,
                cursor;
            if ((0, _reference2.isModified)(value)) {
                bounds$$1 = this.bounds, upsert = this.upsert;
                dom = vm.dom;


                if (!this.upsert.update(dom, value)) {
                    cursor = new Cursor(bounds$$1.parentElement(), clear(bounds$$1));

                    upsert = this.upsert = this.insert(vm.env.getAppendOperations(), cursor, value);
                }
                bounds$$1.update(upsert.bounds);
            }
        };

        UpdateOpcode.prototype.toJSON = function () {
            var guid = this._guid,
                type = this.type,
                cache = this.cache;

            return {
                details: { lastValue: JSON.stringify(cache.peek()) },
                guid: guid,
                type: type
            };
        };

        return UpdateOpcode;
    }(UpdatingOpcode);

    var OptimizedCautiousAppendOpcode = function (_AppendDynamicOpcode) {
        _inherits$6(OptimizedCautiousAppendOpcode, _AppendDynamicOpcode);

        function OptimizedCautiousAppendOpcode() {
            _classCallCheck$7(this, OptimizedCautiousAppendOpcode);

            var _this3 = _possibleConstructorReturn$6(this, _AppendDynamicOpcode.apply(this, arguments));

            _this3.type = 'optimized-cautious-append';
            return _this3;
        }

        OptimizedCautiousAppendOpcode.prototype.normalize = function (reference$$1) {
            return (0, _reference2.map)(reference$$1, normalizeValue);
        };

        OptimizedCautiousAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        OptimizedCautiousAppendOpcode.prototype.updateWith = function (_vm, _reference, cache, bounds$$1, upsert) {
            return new OptimizedCautiousUpdateOpcode(cache, bounds$$1, upsert);
        };

        return OptimizedCautiousAppendOpcode;
    }(AppendDynamicOpcode);

    var OptimizedCautiousUpdateOpcode = function (_UpdateOpcode) {
        _inherits$6(OptimizedCautiousUpdateOpcode, _UpdateOpcode);

        function OptimizedCautiousUpdateOpcode() {
            _classCallCheck$7(this, OptimizedCautiousUpdateOpcode);

            var _this4 = _possibleConstructorReturn$6(this, _UpdateOpcode.apply(this, arguments));

            _this4.type = 'optimized-cautious-update';
            return _this4;
        }

        OptimizedCautiousUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return cautiousInsert(dom, cursor, value);
        };

        return OptimizedCautiousUpdateOpcode;
    }(UpdateOpcode);

    var OptimizedTrustingAppendOpcode = function (_AppendDynamicOpcode2) {
        _inherits$6(OptimizedTrustingAppendOpcode, _AppendDynamicOpcode2);

        function OptimizedTrustingAppendOpcode() {
            _classCallCheck$7(this, OptimizedTrustingAppendOpcode);

            var _this5 = _possibleConstructorReturn$6(this, _AppendDynamicOpcode2.apply(this, arguments));

            _this5.type = 'optimized-trusting-append';
            return _this5;
        }

        OptimizedTrustingAppendOpcode.prototype.normalize = function (reference$$1) {
            return (0, _reference2.map)(reference$$1, normalizeTrustedValue);
        };

        OptimizedTrustingAppendOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        OptimizedTrustingAppendOpcode.prototype.updateWith = function (_vm, _reference, cache, bounds$$1, upsert) {
            return new OptimizedTrustingUpdateOpcode(cache, bounds$$1, upsert);
        };

        return OptimizedTrustingAppendOpcode;
    }(AppendDynamicOpcode);

    var OptimizedTrustingUpdateOpcode = function (_UpdateOpcode2) {
        _inherits$6(OptimizedTrustingUpdateOpcode, _UpdateOpcode2);

        function OptimizedTrustingUpdateOpcode() {
            _classCallCheck$7(this, OptimizedTrustingUpdateOpcode);

            var _this6 = _possibleConstructorReturn$6(this, _UpdateOpcode2.apply(this, arguments));

            _this6.type = 'optimized-trusting-update';
            return _this6;
        }

        OptimizedTrustingUpdateOpcode.prototype.insert = function (dom, cursor, value) {
            return trustingInsert(dom, cursor, value);
        };

        return OptimizedTrustingUpdateOpcode;
    }(UpdateOpcode);

    function _classCallCheck$12(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    /* tslint:disable */
    function debugCallback(context, get) {
        console.info('Use `context`, and `get(<path>)` to debug this template.');
        // for example...
        context === get('this');
        debugger;
    }
    /* tslint:enable */
    var callback = debugCallback;
    // For testing purposes


    var ScopeInspector = function () {
        function ScopeInspector(scope, symbols, evalInfo) {
            var i, slot, name, ref;

            _classCallCheck$12(this, ScopeInspector);

            this.scope = scope;
            this.locals = (0, _util.dict)();
            for (i = 0; i < evalInfo.length; i++) {
                slot = evalInfo[i];
                name = symbols[slot - 1];
                ref = scope.getSymbol(slot);

                this.locals[name] = ref;
            }
        }

        ScopeInspector.prototype.get = function (path) {
            var scope = this.scope,
                locals = this.locals;

            var parts = path.split('.');

            var _path$split = path.split('.'),
                head = _path$split[0],
                tail = _path$split.slice(1);

            var evalScope = scope.getEvalScope();
            var ref = void 0;
            if (head === 'this') {
                ref = scope.getSelf();
            } else if (locals[head]) {
                ref = locals[head];
            } else if (head.indexOf('@') === 0 && evalScope[head]) {
                ref = evalScope[head];
            } else {
                ref = this.scope.getSelf();
                tail = parts;
            }
            return tail.reduce(function (r, part) {
                return r.get(part);
            }, ref);
        };

        return ScopeInspector;
    }();

    APPEND_OPCODES.add(71 /* Debugger */, function (vm, _ref) {
        var _symbols = _ref.op1,
            _evalInfo = _ref.op2;

        var symbols = vm.constants.getOther(_symbols);
        var evalInfo = vm.constants.getArray(_evalInfo);
        var inspector = new ScopeInspector(vm.scope(), symbols, evalInfo);
        callback(vm.getSelf().value(), function (path) {
            return inspector.get(path).value();
        });
    });

    APPEND_OPCODES.add(69 /* GetPartialTemplate */, function (vm) {
        var stack = vm.stack;
        var definition = stack.pop();
        stack.push(definition.value().template.asPartial());
    });

    function _classCallCheck$13(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var IterablePresenceReference = function () {
        function IterablePresenceReference(artifacts) {
            _classCallCheck$13(this, IterablePresenceReference);

            this.tag = artifacts.tag;
            this.artifacts = artifacts;
        }

        IterablePresenceReference.prototype.value = function () {
            return !this.artifacts.isEmpty();
        };

        return IterablePresenceReference;
    }();

    APPEND_OPCODES.add(54 /* PutIterator */, function (vm) {
        var stack = vm.stack;
        var listRef = stack.pop();
        var key = stack.pop();
        var iterable = vm.env.iterableFor(listRef, key.value());
        var iterator = new _reference2.ReferenceIterator(iterable);
        stack.push(iterator);
        stack.push(new IterablePresenceReference(iterator.artifacts));
    });
    APPEND_OPCODES.add(52 /* EnterList */, function (vm, _ref) {
        var relativeStart = _ref.op1;

        vm.enterList(relativeStart);
    });
    APPEND_OPCODES.add(53 /* ExitList */, function (vm) {
        return vm.exitList();
    });
    APPEND_OPCODES.add(55 /* Iterate */, function (vm, _ref2) {
        var breaks = _ref2.op1,
            tryOpcode;

        var stack = vm.stack;
        var item = stack.peek().next();
        if (item) {
            tryOpcode = vm.iterate(item.memo, item.value);

            vm.enterItem(item.key, tryOpcode);
        } else {
            vm.goto(breaks);
        }
    });

    var Ops$2;
    (function (Ops$$1) {
        Ops$$1[Ops$$1["OpenComponentElement"] = 0] = "OpenComponentElement";
        Ops$$1[Ops$$1["DidCreateElement"] = 1] = "DidCreateElement";
        Ops$$1[Ops$$1["DidRenderLayout"] = 2] = "DidRenderLayout";
        Ops$$1[Ops$$1["FunctionExpression"] = 3] = "FunctionExpression";
    })(Ops$2 || (Ops$2 = {}));

    function _classCallCheck$17(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var CompiledStaticTemplate = function CompiledStaticTemplate(handle) {
        _classCallCheck$17(this, CompiledStaticTemplate);

        this.handle = handle;
    };
    var CompiledDynamicTemplate = function CompiledDynamicTemplate(handle, symbolTable) {
        _classCallCheck$17(this, CompiledDynamicTemplate);

        this.handle = handle;
        this.symbolTable = symbolTable;
    };

    var _createClass$2 = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _classCallCheck$20(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var ComponentLayoutBuilder = function () {
        function ComponentLayoutBuilder(env) {
            _classCallCheck$20(this, ComponentLayoutBuilder);

            this.env = env;
        }

        ComponentLayoutBuilder.prototype.wrapLayout = function (layout) {
            this.inner = new WrappedBuilder(this.env, layout);
        };

        ComponentLayoutBuilder.prototype.fromLayout = function (componentName, layout) {
            this.inner = new UnwrappedBuilder(this.env, componentName, layout);
        };

        ComponentLayoutBuilder.prototype.compile = function () {
            return this.inner.compile();
        };

        _createClass$2(ComponentLayoutBuilder, [{
            key: 'tag',
            get: function () {
                return this.inner.tag;
            }
        }, {
            key: 'attrs',
            get: function () {
                return this.inner.attrs;
            }
        }]);

        return ComponentLayoutBuilder;
    }();

    var WrappedBuilder = function () {
        function WrappedBuilder(env, layout) {
            _classCallCheck$20(this, WrappedBuilder);

            this.env = env;
            this.layout = layout;
            this.tag = new ComponentTagBuilder();
            this.attrs = new ComponentAttrsBuilder();
        }

        WrappedBuilder.prototype.compile = function () {
            //========DYNAMIC
            //        PutValue(TagExpr)
            //        Test
            //        JumpUnless(BODY)
            //        OpenDynamicPrimitiveElement
            //        DidCreateElement
            //        ...attr statements...
            //        FlushElement
            // BODY:  Noop
            //        ...body statements...
            //        PutValue(TagExpr)
            //        Test
            //        JumpUnless(END)
            //        CloseElement
            // END:   Noop
            //        DidRenderLayout
            //        Exit
            //
            //========STATIC
            //        OpenPrimitiveElementOpcode
            //        DidCreateElement
            //        ...attr statements...
            //        FlushElement
            //        ...body statements...
            //        CloseElement
            //        DidRenderLayout
            //        Exit
            var env = this.env,
                layout = this.layout,
                attrs,
                i;

            var meta = { templateMeta: layout.meta, symbols: layout.symbols, asPartial: false };
            var dynamicTag = this.tag.getDynamic();
            var staticTag = this.tag.getStatic();
            var b = builder(env, meta);
            b.startLabels();
            if (dynamicTag) {
                b.fetch(Register.s1);
                expr(dynamicTag, b);
                b.dup();
                b.load(Register.s1);
                b.test('simple');
                b.jumpUnless('BODY');
                b.fetch(Register.s1);
                b.pushComponentOperations();
                b.openDynamicElement();
            } else if (staticTag) {
                b.pushComponentOperations();
                b.openElementWithOperations(staticTag);
            }
            if (dynamicTag || staticTag) {
                b.didCreateElement(Register.s0);
                attrs = this.attrs.buffer;

                for (i = 0; i < attrs.length; i++) {
                    compileStatement(attrs[i], b);
                }
                b.flushElement();
            }
            b.label('BODY');
            b.invokeStatic(layout.asBlock());
            if (dynamicTag) {
                b.fetch(Register.s1);
                b.test('simple');
                b.jumpUnless('END');
                b.closeElement();
            } else if (staticTag) {
                b.closeElement();
            }
            b.label('END');
            b.didRenderLayout(Register.s0);
            if (dynamicTag) {
                b.load(Register.s1);
            }
            b.stopLabels();
            var start = b.start;
            b.finalize();

            return new CompiledDynamicTemplate(start, {
                meta: meta,
                hasEval: layout.hasEval,
                symbols: layout.symbols.concat([ATTRS_BLOCK])
            });
        };

        return WrappedBuilder;
    }();

    var UnwrappedBuilder = function () {
        function UnwrappedBuilder(env, componentName, layout) {
            _classCallCheck$20(this, UnwrappedBuilder);

            this.env = env;
            this.componentName = componentName;
            this.layout = layout;
            this.attrs = new ComponentAttrsBuilder();
        }

        UnwrappedBuilder.prototype.compile = function () {
            var env = this.env,
                layout = this.layout;

            return layout.asLayout(this.componentName, this.attrs.buffer).compileDynamic(env);
        };

        _createClass$2(UnwrappedBuilder, [{
            key: 'tag',
            get: function () {
                throw new Error('BUG: Cannot call `tag` on an UnwrappedBuilder');
            }
        }]);

        return UnwrappedBuilder;
    }();

    var ComponentTagBuilder = function () {
        function ComponentTagBuilder() {
            _classCallCheck$20(this, ComponentTagBuilder);

            this.isDynamic = null;
            this.isStatic = null;
            this.staticTagName = null;
            this.dynamicTagName = null;
        }

        ComponentTagBuilder.prototype.getDynamic = function () {
            if (this.isDynamic) {
                return this.dynamicTagName;
            }
        };

        ComponentTagBuilder.prototype.getStatic = function () {
            if (this.isStatic) {
                return this.staticTagName;
            }
        };

        ComponentTagBuilder.prototype.static = function (tagName) {
            this.isStatic = true;
            this.staticTagName = tagName;
        };

        ComponentTagBuilder.prototype.dynamic = function (tagName) {
            this.isDynamic = true;
            this.dynamicTagName = [_wireFormat.Ops.ClientSideExpression, Ops$2.FunctionExpression, tagName];
        };

        return ComponentTagBuilder;
    }();

    var ComponentAttrsBuilder = function () {
        function ComponentAttrsBuilder() {
            _classCallCheck$20(this, ComponentAttrsBuilder);

            this.buffer = [];
        }

        ComponentAttrsBuilder.prototype.static = function (name, value) {
            this.buffer.push([_wireFormat.Ops.StaticAttr, name, value, null]);
        };

        ComponentAttrsBuilder.prototype.dynamic = function (name, value) {
            this.buffer.push([_wireFormat.Ops.DynamicAttr, name, [_wireFormat.Ops.ClientSideExpression, Ops$2.FunctionExpression, value], null]);
        };

        return ComponentAttrsBuilder;
    }();

    var ComponentBuilder = function () {
        function ComponentBuilder(builder) {
            _classCallCheck$20(this, ComponentBuilder);

            this.builder = builder;
            this.env = builder.env;
        }

        ComponentBuilder.prototype.static = function (definition, args) {
            var params = args[0],
                hash = args[1],
                _default = args[2],
                inverse = args[3];
            var builder = this.builder;

            builder.pushComponentManager(definition);
            builder.invokeComponent(null, params, hash, _default, inverse);
        };

        ComponentBuilder.prototype.dynamic = function (definitionArgs, getDefinition, args) {
            var params = args[0],
                hash = args[1],
                block = args[2],
                inverse = args[3];
            var builder = this.builder;

            if (!definitionArgs || definitionArgs.length === 0) {
                throw new Error("Dynamic syntax without an argument");
            }
            var meta = this.builder.meta.templateMeta;

            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            builder.compileArgs(definitionArgs[0], definitionArgs[1], true);
            builder.helper(function (vm, a) {
                return getDefinition(vm, a, meta);
            });
            builder.dup();
            builder.test('simple');
            builder.enter(2);
            builder.jumpUnless('ELSE');
            builder.pushDynamicComponentManager();
            builder.invokeComponent(null, params, hash, block, inverse);
            builder.label('ELSE');
            builder.exit();
            builder.return();
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        };

        return ComponentBuilder;
    }();
    function builder(env, meta) {
        return new OpcodeBuilder(env, meta);
    }

    function _classCallCheck$21(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var RawInlineBlock = function () {
        function RawInlineBlock(meta, statements, parameters) {
            _classCallCheck$21(this, RawInlineBlock);

            this.meta = meta;
            this.statements = statements;
            this.parameters = parameters;
        }

        RawInlineBlock.prototype.scan = function () {
            return new CompilableTemplate(this.statements, { parameters: this.parameters, meta: this.meta });
        };

        return RawInlineBlock;
    }();

    var _createClass$1 = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _defaults$9(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$9(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$9(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$9(subClass, superClass);
    }

    function _classCallCheck$19(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Labels = function () {
        function Labels() {
            _classCallCheck$19(this, Labels);

            this.labels = (0, _util.dict)();
            this.targets = [];
        }

        Labels.prototype.label = function (name, index) {
            this.labels[name] = index;
        };

        Labels.prototype.target = function (at, Target, _target) {
            this.targets.push({ at: at, Target: Target, target: _target });
        };

        Labels.prototype.patch = function (program) {
            var targets = this.targets,
                labels = this.labels,
                i,
                _targets$i,
                at,
                target,
                goto;

            for (i = 0; i < targets.length; i++) {
                _targets$i = targets[i], at = _targets$i.at, target = _targets$i.target;
                goto = labels[target] - at;

                program.heap.setbyaddr(at + 1, goto);
            }
        };

        return Labels;
    }();

    var BasicOpcodeBuilder = function () {
        function BasicOpcodeBuilder(env, meta, program) {
            _classCallCheck$19(this, BasicOpcodeBuilder);

            this.env = env;
            this.meta = meta;
            this.program = program;
            this.labelsStack = new _util.Stack();
            this.constants = program.constants;
            this.heap = program.heap;
            this.start = this.heap.malloc();
        }

        BasicOpcodeBuilder.prototype.upvars = function (count) {
            return (0, _util.fillNulls)(count);
        };

        BasicOpcodeBuilder.prototype.reserve = function (name) {
            this.push(name, 0, 0, 0);
        };

        BasicOpcodeBuilder.prototype.push = function (name) {
            var op1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
            var op2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var op3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            this.heap.push(name);
            this.heap.push(op1);
            this.heap.push(op2);
            this.heap.push(op3);
        };

        BasicOpcodeBuilder.prototype.finalize = function () {
            this.push(22 /* Return */);
            this.heap.finishMalloc(this.start);
            return this.start;
        };
        // args


        BasicOpcodeBuilder.prototype.pushArgs = function (synthetic) {
            this.push(58 /* PushArgs */, synthetic === true ? 1 : 0);
        };
        // helpers


        BasicOpcodeBuilder.prototype.startLabels = function () {
            this.labelsStack.push(new Labels());
        };

        BasicOpcodeBuilder.prototype.stopLabels = function () {
            var label = this.labelsStack.pop();
            label.patch(this.program);
        };
        // components


        BasicOpcodeBuilder.prototype.pushComponentManager = function (definition) {
            this.push(56 /* PushComponentManager */, this.other(definition));
        };

        BasicOpcodeBuilder.prototype.pushDynamicComponentManager = function () {
            this.push(57 /* PushDynamicComponentManager */);
        };

        BasicOpcodeBuilder.prototype.prepareArgs = function (state) {
            this.push(59 /* PrepareArgs */, state);
        };

        BasicOpcodeBuilder.prototype.createComponent = function (state, hasDefault, hasInverse) {
            var flag = (hasDefault === true ? 1 : 0) | (hasInverse === true ? 1 : 0) << 1;
            this.push(60 /* CreateComponent */, flag, state);
        };

        BasicOpcodeBuilder.prototype.registerComponentDestructor = function (state) {
            this.push(61 /* RegisterComponentDestructor */, state);
        };

        BasicOpcodeBuilder.prototype.beginComponentTransaction = function () {
            this.push(65 /* BeginComponentTransaction */);
        };

        BasicOpcodeBuilder.prototype.commitComponentTransaction = function () {
            this.push(66 /* CommitComponentTransaction */);
        };

        BasicOpcodeBuilder.prototype.pushComponentOperations = function () {
            this.push(62 /* PushComponentOperations */);
        };

        BasicOpcodeBuilder.prototype.getComponentSelf = function (state) {
            this.push(63 /* GetComponentSelf */, state);
        };

        BasicOpcodeBuilder.prototype.getComponentLayout = function (state) {
            this.push(64 /* GetComponentLayout */, state);
        };

        BasicOpcodeBuilder.prototype.didCreateElement = function (state) {
            this.push(67 /* DidCreateElement */, state);
        };

        BasicOpcodeBuilder.prototype.didRenderLayout = function (state) {
            this.push(68 /* DidRenderLayout */, state);
        };
        // partial


        BasicOpcodeBuilder.prototype.getPartialTemplate = function () {
            this.push(69 /* GetPartialTemplate */);
        };

        BasicOpcodeBuilder.prototype.resolveMaybeLocal = function (name) {
            this.push(70 /* ResolveMaybeLocal */, this.string(name));
        };
        // debugger


        BasicOpcodeBuilder.prototype.debugger = function (symbols, evalInfo) {
            this.push(71 /* Debugger */, this.constants.other(symbols), this.constants.array(evalInfo));
        };
        // content


        BasicOpcodeBuilder.prototype.dynamicContent = function (Opcode) {
            this.push(26 /* DynamicContent */, this.other(Opcode));
        };

        BasicOpcodeBuilder.prototype.cautiousAppend = function () {
            this.dynamicContent(new OptimizedCautiousAppendOpcode());
        };

        BasicOpcodeBuilder.prototype.trustingAppend = function () {
            this.dynamicContent(new OptimizedTrustingAppendOpcode());
        };
        // dom


        BasicOpcodeBuilder.prototype.text = function (_text) {
            this.push(24 /* Text */, this.constants.string(_text));
        };

        BasicOpcodeBuilder.prototype.openPrimitiveElement = function (tag) {
            this.push(27 /* OpenElement */, this.constants.string(tag));
        };

        BasicOpcodeBuilder.prototype.openElementWithOperations = function (tag) {
            this.push(28 /* OpenElementWithOperations */, this.constants.string(tag));
        };

        BasicOpcodeBuilder.prototype.openDynamicElement = function () {
            this.push(29 /* OpenDynamicElement */);
        };

        BasicOpcodeBuilder.prototype.flushElement = function () {
            this.push(33 /* FlushElement */);
        };

        BasicOpcodeBuilder.prototype.closeElement = function () {
            this.push(34 /* CloseElement */);
        };

        BasicOpcodeBuilder.prototype.staticAttr = function (_name, _namespace, _value) {
            var name = this.constants.string(_name);
            var namespace = _namespace ? this.constants.string(_namespace) : 0;
            var value = this.constants.string(_value);
            this.push(30 /* StaticAttr */, name, value, namespace);
        };

        BasicOpcodeBuilder.prototype.dynamicAttrNS = function (_name, _namespace, trusting) {
            var name = this.constants.string(_name);
            var namespace = this.constants.string(_namespace);
            this.push(32 /* DynamicAttrNS */, name, namespace, trusting === true ? 1 : 0);
        };

        BasicOpcodeBuilder.prototype.dynamicAttr = function (_name, trusting) {
            var name = this.constants.string(_name);
            this.push(31 /* DynamicAttr */, name, trusting === true ? 1 : 0);
        };

        BasicOpcodeBuilder.prototype.comment = function (_comment) {
            var comment = this.constants.string(_comment);
            this.push(25 /* Comment */, comment);
        };

        BasicOpcodeBuilder.prototype.modifier = function (_definition) {
            this.push(35 /* Modifier */, this.other(_definition));
        };
        // lists


        BasicOpcodeBuilder.prototype.putIterator = function () {
            this.push(54 /* PutIterator */);
        };

        BasicOpcodeBuilder.prototype.enterList = function (start) {
            this.reserve(52 /* EnterList */);
            this.labels.target(this.pos, 52 /* EnterList */, start);
        };

        BasicOpcodeBuilder.prototype.exitList = function () {
            this.push(53 /* ExitList */);
        };

        BasicOpcodeBuilder.prototype.iterate = function (breaks) {
            this.reserve(55 /* Iterate */);
            this.labels.target(this.pos, 55 /* Iterate */, breaks);
        };
        // expressions


        BasicOpcodeBuilder.prototype.setVariable = function (symbol) {
            this.push(4 /* SetVariable */, symbol);
        };

        BasicOpcodeBuilder.prototype.getVariable = function (symbol) {
            this.push(5 /* GetVariable */, symbol);
        };

        BasicOpcodeBuilder.prototype.getProperty = function (key) {
            this.push(6 /* GetProperty */, this.string(key));
        };

        BasicOpcodeBuilder.prototype.getBlock = function (symbol) {
            this.push(8 /* GetBlock */, symbol);
        };

        BasicOpcodeBuilder.prototype.hasBlock = function (symbol) {
            this.push(9 /* HasBlock */, symbol);
        };

        BasicOpcodeBuilder.prototype.hasBlockParams = function (symbol) {
            this.push(10 /* HasBlockParams */, symbol);
        };

        BasicOpcodeBuilder.prototype.concat = function (size) {
            this.push(11 /* Concat */, size);
        };

        BasicOpcodeBuilder.prototype.function = function (f) {
            this.push(2 /* Function */, this.func(f));
        };

        BasicOpcodeBuilder.prototype.load = function (register) {
            this.push(17 /* Load */, register);
        };

        BasicOpcodeBuilder.prototype.fetch = function (register) {
            this.push(18 /* Fetch */, register);
        };

        BasicOpcodeBuilder.prototype.dup = function () {
            var register = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Register.sp;
            var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

            return this.push(15 /* Dup */, register, offset);
        };

        BasicOpcodeBuilder.prototype.pop = function () {
            var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            return this.push(16 /* Pop */, count);
        };
        // vm


        BasicOpcodeBuilder.prototype.pushRemoteElement = function () {
            this.push(36 /* PushRemoteElement */);
        };

        BasicOpcodeBuilder.prototype.popRemoteElement = function () {
            this.push(37 /* PopRemoteElement */);
        };

        BasicOpcodeBuilder.prototype.label = function (name) {
            this.labels.label(name, this.nextPos);
        };

        BasicOpcodeBuilder.prototype.pushRootScope = function (symbols, bindCallerScope) {
            this.push(19 /* RootScope */, symbols, bindCallerScope ? 1 : 0);
        };

        BasicOpcodeBuilder.prototype.pushChildScope = function () {
            this.push(20 /* ChildScope */);
        };

        BasicOpcodeBuilder.prototype.popScope = function () {
            this.push(21 /* PopScope */);
        };

        BasicOpcodeBuilder.prototype.returnTo = function (label) {
            this.reserve(23 /* ReturnTo */);
            this.labels.target(this.pos, 23 /* ReturnTo */, label);
        };

        BasicOpcodeBuilder.prototype.pushDynamicScope = function () {
            this.push(39 /* PushDynamicScope */);
        };

        BasicOpcodeBuilder.prototype.popDynamicScope = function () {
            this.push(40 /* PopDynamicScope */);
        };

        BasicOpcodeBuilder.prototype.pushImmediate = function (value) {
            this.push(13 /* Constant */, this.other(value));
        };

        BasicOpcodeBuilder.prototype.primitive = function (_primitive) {
            var flag = 0;
            var primitive = void 0;
            switch (typeof _primitive) {
                case 'number':
                    if (_primitive % 1 === 0 && _primitive > 0) {
                        primitive = _primitive;
                    } else {
                        primitive = this.float(_primitive);
                        flag = 1;
                    }
                    break;
                case 'string':
                    primitive = this.string(_primitive);
                    flag = 2;
                    break;
                case 'boolean':
                    primitive = _primitive | 0;
                    flag = 3;
                    break;
                case 'object':
                    // assume null
                    primitive = 2;
                    flag = 3;
                    break;
                case 'undefined':
                    primitive = 3;
                    flag = 3;
                    break;
                default:
                    throw new Error('Invalid primitive passed to pushPrimitive');
            }
            this.push(14 /* PrimitiveReference */, flag << 30 | primitive);
        };

        BasicOpcodeBuilder.prototype.helper = function (func) {
            this.push(1 /* Helper */, this.func(func));
        };

        BasicOpcodeBuilder.prototype.pushBlock = function (block) {
            this.push(7 /* PushBlock */, this.block(block));
        };

        BasicOpcodeBuilder.prototype.bindDynamicScope = function (_names) {
            this.push(38 /* BindDynamicScope */, this.names(_names));
        };

        BasicOpcodeBuilder.prototype.enter = function (args) {
            this.push(49 /* Enter */, args);
        };

        BasicOpcodeBuilder.prototype.exit = function () {
            this.push(50 /* Exit */);
        };

        BasicOpcodeBuilder.prototype.return = function () {
            this.push(22 /* Return */);
        };

        BasicOpcodeBuilder.prototype.pushFrame = function () {
            this.push(47 /* PushFrame */);
        };

        BasicOpcodeBuilder.prototype.popFrame = function () {
            this.push(48 /* PopFrame */);
        };

        BasicOpcodeBuilder.prototype.compileDynamicBlock = function () {
            this.push(41 /* CompileDynamicBlock */);
        };

        BasicOpcodeBuilder.prototype.invokeDynamic = function (invoker) {
            this.push(43 /* InvokeDynamic */, this.other(invoker));
        };

        BasicOpcodeBuilder.prototype.invokeStatic = function (block) {
            var callerCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0,
                i;
            var parameters = block.symbolTable.parameters;

            var calleeCount = parameters.length;
            var count = Math.min(callerCount, calleeCount);
            this.pushFrame();
            if (count) {
                this.pushChildScope();
                for (i = 0; i < count; i++) {
                    this.dup(Register.fp, callerCount - i);
                    this.setVariable(parameters[i]);
                }
            }
            var _block = this.constants.block(block);
            this.push(42 /* InvokeStatic */, _block);
            if (count) {
                this.popScope();
            }
            this.popFrame();
        };

        BasicOpcodeBuilder.prototype.test = function (testFunc) {
            var _func = void 0;
            if (testFunc === 'const') {
                _func = ConstTest;
            } else if (testFunc === 'simple') {
                _func = SimpleTest;
            } else if (testFunc === 'environment') {
                _func = EnvironmentTest;
            } else if (typeof testFunc === 'function') {
                _func = testFunc;
            } else {
                throw new Error('unreachable');
            }
            var func = this.constants.function(_func);
            this.push(51 /* Test */, func);
        };

        BasicOpcodeBuilder.prototype.jump = function (target) {
            this.reserve(44 /* Jump */);
            this.labels.target(this.pos, 44 /* Jump */, target);
        };

        BasicOpcodeBuilder.prototype.jumpIf = function (target) {
            this.reserve(45 /* JumpIf */);
            this.labels.target(this.pos, 45 /* JumpIf */, target);
        };

        BasicOpcodeBuilder.prototype.jumpUnless = function (target) {
            this.reserve(46 /* JumpUnless */);
            this.labels.target(this.pos, 46 /* JumpUnless */, target);
        };

        BasicOpcodeBuilder.prototype.string = function (_string) {
            return this.constants.string(_string);
        };

        BasicOpcodeBuilder.prototype.float = function (num) {
            return this.constants.float(num);
        };

        BasicOpcodeBuilder.prototype.names = function (_names) {
            var names = [],
                i,
                n;
            for (i = 0; i < _names.length; i++) {
                n = _names[i];

                names[i] = this.constants.string(n);
            }
            return this.constants.array(names);
        };

        BasicOpcodeBuilder.prototype.symbols = function (_symbols) {
            return this.constants.array(_symbols);
        };

        BasicOpcodeBuilder.prototype.other = function (value) {
            return this.constants.other(value);
        };

        BasicOpcodeBuilder.prototype.block = function (_block2) {
            return _block2 ? this.constants.block(_block2) : 0;
        };

        BasicOpcodeBuilder.prototype.func = function (_func2) {
            return this.constants.function(_func2);
        };

        _createClass$1(BasicOpcodeBuilder, [{
            key: 'pos',
            get: function () {
                return (0, _util.typePos)(this.heap.size());
            }
        }, {
            key: 'nextPos',
            get: function () {
                return this.heap.size();
            }
        }, {
            key: 'labels',
            get: function () {
                return this.labelsStack.current;
            }
        }]);

        return BasicOpcodeBuilder;
    }();

    function isCompilableExpression(expr$$1) {
        return typeof expr$$1 === 'object' && expr$$1 !== null && typeof expr$$1.compile === 'function';
    }

    var OpcodeBuilder = function (_BasicOpcodeBuilder) {
        _inherits$9(OpcodeBuilder, _BasicOpcodeBuilder);

        function OpcodeBuilder(env, meta) {
            var program = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : env.program;

            _classCallCheck$19(this, OpcodeBuilder);

            var _this = _possibleConstructorReturn$9(this, _BasicOpcodeBuilder.call(this, env, meta, program));

            _this.component = new ComponentBuilder(_this);
            return _this;
        }

        OpcodeBuilder.prototype.compileArgs = function (params, hash, synthetic) {
            var positional = 0,
                i,
                val,
                _i;
            if (params) {
                for (i = 0; i < params.length; i++) {
                    expr(params[i], this);
                }
                positional = params.length;
            }
            this.pushImmediate(positional);
            var names = _util.EMPTY_ARRAY;
            if (hash) {
                names = hash[0];
                val = hash[1];

                for (_i = 0; _i < val.length; _i++) {
                    expr(val[_i], this);
                }
            }
            this.pushImmediate(names);
            this.pushArgs(synthetic);
        };

        OpcodeBuilder.prototype.compile = function (expr$$1) {
            if (isCompilableExpression(expr$$1)) {
                return expr$$1.compile(this);
            } else {
                return expr$$1;
            }
        };

        OpcodeBuilder.prototype.guardedAppend = function (expression, trusting) {
            this.startLabels();
            this.pushFrame();
            this.returnTo('END');
            expr(expression, this);
            this.dup();
            this.test(function (reference$$1) {
                return IsComponentDefinitionReference.create(reference$$1);
            });
            this.enter(2);
            this.jumpUnless('ELSE');
            this.pushDynamicComponentManager();
            this.invokeComponent(null, null, null, null, null);
            this.exit();
            this.return();
            this.label('ELSE');
            if (trusting) {
                this.trustingAppend();
            } else {
                this.cautiousAppend();
            }
            this.exit();
            this.return();
            this.label('END');
            this.popFrame();
            this.stopLabels();
        };

        OpcodeBuilder.prototype.invokeComponent = function (attrs, params, hash, block) {
            var inverse = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

            this.fetch(Register.s0);
            this.dup(Register.sp, 1);
            this.load(Register.s0);
            this.pushBlock(block);
            this.pushBlock(inverse);
            this.compileArgs(params, hash, false);
            this.prepareArgs(Register.s0);
            this.beginComponentTransaction();
            this.pushDynamicScope();
            this.createComponent(Register.s0, block !== null, inverse !== null);
            this.registerComponentDestructor(Register.s0);
            this.getComponentSelf(Register.s0);
            this.getComponentLayout(Register.s0);
            this.invokeDynamic(new InvokeDynamicLayout(attrs && attrs.scan()));
            this.popFrame();
            this.popScope();
            this.popDynamicScope();
            this.commitComponentTransaction();
            this.load(Register.s0);
        };

        OpcodeBuilder.prototype.template = function (block) {
            if (!block) return null;
            return new RawInlineBlock(this.meta, block.statements, block.parameters);
        };

        return OpcodeBuilder;
    }(BasicOpcodeBuilder);

    function _classCallCheck$18(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Ops$3 = _wireFormat.Ops;
    var ATTRS_BLOCK = '&attrs';

    var Compilers = function () {
        function Compilers() {
            var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

            _classCallCheck$18(this, Compilers);

            this.offset = offset;
            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Compilers.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Compilers.prototype.compile = function (sexp, builder) {
            var name = sexp[this.offset];
            var index = this.names[name];
            var func = this.funcs[index];
            (0, _util.assert)(!!func, 'expected an implementation for ' + (this.offset === 0 ? Ops$3[sexp[0]] : Ops$2[sexp[1]]));
            func(sexp, builder);
        };

        return Compilers;
    }();

    var STATEMENTS = new Compilers();
    var CLIENT_SIDE = new Compilers(1);
    STATEMENTS.add(Ops$3.Text, function (sexp, builder) {
        builder.text(sexp[1]);
    });
    STATEMENTS.add(Ops$3.Comment, function (sexp, builder) {
        builder.comment(sexp[1]);
    });
    STATEMENTS.add(Ops$3.CloseElement, function (_sexp, builder) {
        builder.closeElement();
    });
    STATEMENTS.add(Ops$3.FlushElement, function (_sexp, builder) {
        builder.flushElement();
    });
    STATEMENTS.add(Ops$3.Modifier, function (sexp, builder) {
        var env = builder.env,
            meta = builder.meta;
        var name = sexp[1],
            params = sexp[2],
            hash = sexp[3];

        if (env.hasModifier(name, meta.templateMeta)) {
            builder.compileArgs(params, hash, true);
            builder.modifier(env.lookupModifier(name, meta.templateMeta));
        } else {
            throw new Error('Compile Error ' + name + ' is not a modifier: Helpers may not be used in the element form.');
        }
    });
    STATEMENTS.add(Ops$3.StaticAttr, function (sexp, builder) {
        var name = sexp[1],
            value = sexp[2],
            namespace = sexp[3];

        builder.staticAttr(name, namespace, value);
    });
    STATEMENTS.add(Ops$3.DynamicAttr, function (sexp, builder) {
        dynamicAttr(sexp, false, builder);
    });
    STATEMENTS.add(Ops$3.TrustingAttr, function (sexp, builder) {
        dynamicAttr(sexp, true, builder);
    });
    function dynamicAttr(sexp, trusting, builder) {
        var name = sexp[1],
            value = sexp[2],
            namespace = sexp[3];

        expr(value, builder);
        if (namespace) {
            builder.dynamicAttrNS(name, namespace, trusting);
        } else {
            builder.dynamicAttr(name, trusting);
        }
    }
    STATEMENTS.add(Ops$3.OpenElement, function (sexp, builder) {
        builder.openPrimitiveElement(sexp[1]);
    });
    CLIENT_SIDE.add(Ops$2.OpenComponentElement, function (sexp, builder) {
        builder.pushComponentOperations();
        builder.openElementWithOperations(sexp[2]);
    });
    CLIENT_SIDE.add(Ops$2.DidCreateElement, function (_sexp, builder) {
        builder.didCreateElement(Register.s0);
    });
    CLIENT_SIDE.add(Ops$2.DidRenderLayout, function (_sexp, builder) {
        builder.didRenderLayout(Register.s0);
    });
    STATEMENTS.add(Ops$3.Append, function (sexp, builder) {
        var value = sexp[1],
            trusting = sexp[2];

        var _builder$env$macros = builder.env.macros(),
            inlines = _builder$env$macros.inlines;

        var returned = inlines.compile(sexp, builder) || value;
        if (returned === true) return;
        var isGet = E.isGet(value);
        var isMaybeLocal = E.isMaybeLocal(value);
        if (trusting) {
            builder.guardedAppend(value, true);
        } else {
            if (isGet || isMaybeLocal) {
                builder.guardedAppend(value, false);
            } else {
                expr(value, builder);
                builder.cautiousAppend();
            }
        }
    });
    STATEMENTS.add(Ops$3.Block, function (sexp, builder) {
        var name = sexp[1],
            params = sexp[2],
            hash = sexp[3],
            _template = sexp[4],
            _inverse = sexp[5];

        var template = builder.template(_template);
        var inverse = builder.template(_inverse);
        var templateBlock = template && template.scan();
        var inverseBlock = inverse && inverse.scan();

        var _builder$env$macros2 = builder.env.macros(),
            blocks = _builder$env$macros2.blocks;

        blocks.compile(name, params, hash, templateBlock, inverseBlock, builder);
    });
    var InvokeDynamicLayout = function () {
        function InvokeDynamicLayout(attrs) {
            _classCallCheck$18(this, InvokeDynamicLayout);

            this.attrs = attrs;
        }

        InvokeDynamicLayout.prototype.invoke = function (vm, layout) {
            var _layout$symbolTable = layout.symbolTable,
                symbols = _layout$symbolTable.symbols,
                hasEval = _layout$symbolTable.hasEval,
                i,
                symbol,
                value;

            var stack = vm.stack;
            var scope = vm.pushRootScope(symbols.length + 1, true);
            scope.bindSelf(stack.pop());
            scope.bindBlock(symbols.indexOf(ATTRS_BLOCK) + 1, this.attrs);
            var lookup = null;

            if (hasEval) {
                symbols.indexOf('$eval') + 1;
                lookup = (0, _util.dict)();
            }
            var callerNames = stack.pop();
            for (i = callerNames.length - 1; i >= 0; i--) {
                symbol = symbols.indexOf(callerNames[i]);
                value = stack.pop();

                if (symbol !== -1) scope.bindSymbol(symbol + 1, value);
                if (hasEval) lookup[callerNames[i]] = value;
            }
            var numPositionalArgs = stack.pop();
            (0, _util.assert)(typeof numPositionalArgs === 'number', '[BUG] Incorrect value of positional argument count found during invoke-dynamic-layout.');
            // Currently we don't support accessing positional args in templates, so just throw them away
            stack.pop(numPositionalArgs);
            var inverseSymbol = symbols.indexOf('&inverse');
            var inverse = stack.pop();
            if (inverseSymbol !== -1) {
                scope.bindBlock(inverseSymbol + 1, inverse);
            }
            if (lookup) lookup['&inverse'] = inverse;
            var defaultSymbol = symbols.indexOf('&default');
            var defaultBlock = stack.pop();
            if (defaultSymbol !== -1) {
                scope.bindBlock(defaultSymbol + 1, defaultBlock);
            }
            if (lookup) lookup['&default'] = defaultBlock;
            if (lookup) scope.bindEvalScope(lookup);
            vm.pushFrame();
            vm.call(layout.handle);
        };

        InvokeDynamicLayout.prototype.toJSON = function () {
            return { GlimmerDebug: '<invoke-dynamic-layout>' };
        };

        return InvokeDynamicLayout;
    }();
    STATEMENTS.add(Ops$3.Component, function (sexp, builder) {
        var tag = sexp[1],
            attrs = sexp[2],
            args = sexp[3],
            block = sexp[4],
            child,
            attrsBlock,
            definition,
            i,
            stmts,
            _i;

        if (builder.env.hasComponentDefinition(tag, builder.meta.templateMeta)) {
            child = builder.template(block);
            attrsBlock = new RawInlineBlock(builder.meta, attrs, _util.EMPTY_ARRAY);
            definition = builder.env.getComponentDefinition(tag, builder.meta.templateMeta);

            builder.pushComponentManager(definition);
            builder.invokeComponent(attrsBlock, null, args, child && child.scan());
        } else if (block && block.parameters.length) {
            throw new Error('Compile Error: Cannot find component ' + tag);
        } else {
            builder.openPrimitiveElement(tag);
            for (i = 0; i < attrs.length; i++) {
                STATEMENTS.compile(attrs[i], builder);
            }
            builder.flushElement();
            if (block) {
                stmts = block.statements;

                for (_i = 0; _i < stmts.length; _i++) {
                    STATEMENTS.compile(stmts[_i], builder);
                }
            }
            builder.closeElement();
        }
    });
    var PartialInvoker = function () {
        function PartialInvoker(outerSymbols, evalInfo) {
            _classCallCheck$18(this, PartialInvoker);

            this.outerSymbols = outerSymbols;
            this.evalInfo = evalInfo;
        }

        PartialInvoker.prototype.invoke = function (vm, _partial) {
            var partial = _partial,
                i,
                slot,
                name,
                ref,
                _i2,
                _name,
                symbol,
                value;
            var partialSymbols = partial.symbolTable.symbols;
            var outerScope = vm.scope();
            var evalScope = outerScope.getEvalScope();
            var partialScope = vm.pushRootScope(partialSymbols.length, false);
            partialScope.bindCallerScope(outerScope.getCallerScope());
            partialScope.bindEvalScope(evalScope);
            partialScope.bindSelf(outerScope.getSelf());
            var evalInfo = this.evalInfo,
                outerSymbols = this.outerSymbols;

            var locals = Object.create(outerScope.getPartialMap());
            for (i = 0; i < evalInfo.length; i++) {
                slot = evalInfo[i];
                name = outerSymbols[slot - 1];
                ref = outerScope.getSymbol(slot);

                locals[name] = ref;
            }
            if (evalScope) {
                for (_i2 = 0; _i2 < partialSymbols.length; _i2++) {
                    _name = partialSymbols[_i2];
                    symbol = _i2 + 1;
                    value = evalScope[_name];

                    if (value !== undefined) partialScope.bind(symbol, value);
                }
            }
            partialScope.bindPartialMap(locals);
            vm.pushFrame();
            vm.call(partial.handle);
        };

        return PartialInvoker;
    }();
    STATEMENTS.add(Ops$3.Partial, function (sexp, builder) {
        var name = sexp[1],
            evalInfo = sexp[2];
        var _builder$meta = builder.meta,
            templateMeta = _builder$meta.templateMeta,
            symbols = _builder$meta.symbols;

        builder.startLabels();
        builder.pushFrame();
        builder.returnTo('END');
        expr(name, builder);
        builder.pushImmediate(1);
        builder.pushImmediate(_util.EMPTY_ARRAY);
        builder.pushArgs(true);
        builder.helper(function (vm, args) {
            var env = vm.env;

            var nameRef = args.positional.at(0);
            return (0, _reference2.map)(nameRef, function (n) {
                if (typeof n === 'string' && n) {
                    if (!env.hasPartial(n, templateMeta)) {
                        throw new Error('Could not find a partial named "' + n + '"');
                    }
                    return env.lookupPartial(n, templateMeta);
                } else if (n) {
                    throw new Error('Could not find a partial named "' + String(n) + '"');
                } else {
                    return null;
                }
            });
        });
        builder.dup();
        builder.test('simple');
        builder.enter(2);
        builder.jumpUnless('ELSE');
        builder.getPartialTemplate();
        builder.compileDynamicBlock();
        builder.invokeDynamic(new PartialInvoker(symbols, evalInfo));
        builder.popScope();
        builder.popFrame();
        builder.label('ELSE');
        builder.exit();
        builder.return();
        builder.label('END');
        builder.popFrame();
        builder.stopLabels();
    });

    var InvokeDynamicYield = function () {
        function InvokeDynamicYield(callerCount) {
            _classCallCheck$18(this, InvokeDynamicYield);

            this.callerCount = callerCount;
        }

        InvokeDynamicYield.prototype.invoke = function (vm, block) {
            var callerCount = this.callerCount,
                i;

            var stack = vm.stack;
            if (!block) {
                // To balance the pop{Frame,Scope}
                vm.pushFrame();
                vm.pushCallerScope();
                return;
            }
            var table = block.symbolTable;
            var locals = table.parameters; // always present in inline blocks
            var calleeCount = locals ? locals.length : 0;
            var count = Math.min(callerCount, calleeCount);
            vm.pushFrame();
            vm.pushCallerScope(calleeCount > 0);
            var scope = vm.scope();
            for (i = 0; i < count; i++) {
                scope.bindSymbol(locals[i], stack.fromBase(callerCount - i));
            }
            vm.call(block.handle);
        };

        InvokeDynamicYield.prototype.toJSON = function () {
            return { GlimmerDebug: '<invoke-dynamic-yield caller-count=' + this.callerCount + '>' };
        };

        return InvokeDynamicYield;
    }();

    STATEMENTS.add(Ops$3.Yield, function (sexp, builder) {
        var to = sexp[1],
            params = sexp[2];

        var count = compileList(params, builder);
        builder.getBlock(to);
        builder.compileDynamicBlock();
        builder.invokeDynamic(new InvokeDynamicYield(count));
        builder.popScope();
        builder.popFrame();
        if (count) {
            builder.pop(count);
        }
    });
    STATEMENTS.add(Ops$3.Debugger, function (sexp, builder) {
        var evalInfo = sexp[1];

        builder.debugger(builder.meta.symbols, evalInfo);
    });
    STATEMENTS.add(Ops$3.ClientSideStatement, function (sexp, builder) {
        CLIENT_SIDE.compile(sexp, builder);
    });
    var EXPRESSIONS = new Compilers();
    var CLIENT_SIDE_EXPRS = new Compilers(1);
    var E = _wireFormat.Expressions;
    function expr(expression, builder) {
        if (Array.isArray(expression)) {
            EXPRESSIONS.compile(expression, builder);
        } else {
            builder.primitive(expression);
        }
    }
    EXPRESSIONS.add(Ops$3.Unknown, function (sexp, builder) {
        var name = sexp[1];
        if (builder.env.hasHelper(name, builder.meta.templateMeta)) {
            EXPRESSIONS.compile([Ops$3.Helper, name, _util.EMPTY_ARRAY, null], builder);
        } else if (builder.meta.asPartial) {
            builder.resolveMaybeLocal(name);
        } else {
            builder.getVariable(0);
            builder.getProperty(name);
        }
    });
    EXPRESSIONS.add(Ops$3.Concat, function (sexp, builder) {
        var parts = sexp[1],
            i;
        for (i = 0; i < parts.length; i++) {
            expr(parts[i], builder);
        }
        builder.concat(parts.length);
    });
    CLIENT_SIDE_EXPRS.add(Ops$2.FunctionExpression, function (sexp, builder) {
        builder.function(sexp[2]);
    });
    EXPRESSIONS.add(Ops$3.Helper, function (sexp, builder) {
        var env = builder.env,
            meta = builder.meta;
        var name = sexp[1],
            params = sexp[2],
            hash = sexp[3];

        if (env.hasHelper(name, meta.templateMeta)) {
            builder.compileArgs(params, hash, true);
            builder.helper(env.lookupHelper(name, meta.templateMeta));
        } else {
            throw new Error('Compile Error: ' + name + ' is not a helper');
        }
    });
    EXPRESSIONS.add(Ops$3.Get, function (sexp, builder) {
        var head = sexp[1],
            path = sexp[2],
            i;

        builder.getVariable(head);
        for (i = 0; i < path.length; i++) {
            builder.getProperty(path[i]);
        }
    });
    EXPRESSIONS.add(Ops$3.MaybeLocal, function (sexp, builder) {
        var path = sexp[1],
            head,
            i;

        if (builder.meta.asPartial) {
            head = path[0];

            path = path.slice(1);
            builder.resolveMaybeLocal(head);
        } else {
            builder.getVariable(0);
        }
        for (i = 0; i < path.length; i++) {
            builder.getProperty(path[i]);
        }
    });
    EXPRESSIONS.add(Ops$3.Undefined, function (_sexp, builder) {
        return builder.primitive(undefined);
    });
    EXPRESSIONS.add(Ops$3.HasBlock, function (sexp, builder) {
        builder.hasBlock(sexp[1]);
    });
    EXPRESSIONS.add(Ops$3.HasBlockParams, function (sexp, builder) {
        builder.hasBlockParams(sexp[1]);
    });
    EXPRESSIONS.add(Ops$3.ClientSideExpression, function (sexp, builder) {
        CLIENT_SIDE_EXPRS.compile(sexp, builder);
    });
    function compileList(params, builder) {
        var i;

        if (!params) return 0;
        for (i = 0; i < params.length; i++) {
            expr(params[i], builder);
        }
        return params.length;
    }
    var Blocks = function () {
        function Blocks() {
            _classCallCheck$18(this, Blocks);

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Blocks.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Blocks.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Blocks.prototype.compile = function (name, params, hash, template, inverse, builder) {
            var index = this.names[name],
                func,
                handled,
                _func;
            if (index === undefined) {
                (0, _util.assert)(!!this.missing, name + ' not found, and no catch-all block handler was registered');
                func = this.missing;
                handled = func(name, params, hash, template, inverse, builder);

                (0, _util.assert)(!!handled, name + ' not found, and the catch-all block handler didn\'t handle it');
            } else {
                _func = this.funcs[index];

                _func(params, hash, template, inverse, builder);
            }
        };

        return Blocks;
    }();
    var BLOCKS = new Blocks();
    var Inlines = function () {
        function Inlines() {
            _classCallCheck$18(this, Inlines);

            this.names = (0, _util.dict)();
            this.funcs = [];
        }

        Inlines.prototype.add = function (name, func) {
            this.funcs.push(func);
            this.names[name] = this.funcs.length - 1;
        };

        Inlines.prototype.addMissing = function (func) {
            this.missing = func;
        };

        Inlines.prototype.compile = function (sexp, builder) {
            var value = sexp[1],
                func,
                returned,
                _func2,
                _returned;
            // TODO: Fix this so that expression macros can return
            // things like components, so that {{component foo}}
            // is the same as {{(component foo)}}
            if (!Array.isArray(value)) return ['expr', value];
            var name = void 0;
            var params = void 0;
            var hash = void 0;
            if (value[0] === Ops$3.Helper) {
                name = value[1];
                params = value[2];
                hash = value[3];
            } else if (value[0] === Ops$3.Unknown) {
                name = value[1];
                params = hash = null;
            } else {
                return ['expr', value];
            }
            var index = this.names[name];
            if (index === undefined && this.missing) {
                func = this.missing;
                returned = func(name, params, hash, builder);

                return returned === false ? ['expr', value] : returned;
            } else if (index !== undefined) {
                _func2 = this.funcs[index];
                _returned = _func2(name, params, hash, builder);

                return _returned === false ? ['expr', value] : _returned;
            } else {
                return ['expr', value];
            }
        };

        return Inlines;
    }();
    var INLINES = new Inlines();
    populateBuiltins(BLOCKS, INLINES);
    function populateBuiltins() {
        var blocks = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Blocks();
        var inlines = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Inlines();

        blocks.add('if', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #if requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            expr(params[0], builder);
            builder.test('environment');
            builder.enter(1);
            builder.jumpUnless('ELSE');
            builder.invokeStatic(template);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStatic(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('unless', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #unless requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            expr(params[0], builder);
            builder.test('environment');
            builder.enter(1);
            builder.jumpIf('ELSE');
            builder.invokeStatic(template);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStatic(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('with', function (params, _hash, template, inverse, builder) {
            //        PutArgs
            //        Test(Environment)
            //        Enter(BEGIN, END)
            // BEGIN: Noop
            //        JumpUnless(ELSE)
            //        Evaluate(default)
            //        Jump(END)
            // ELSE:  Noop
            //        Evalulate(inverse)
            // END:   Noop
            //        Exit
            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #with requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            expr(params[0], builder);
            builder.dup();
            builder.test('environment');
            builder.enter(2);
            builder.jumpUnless('ELSE');
            builder.invokeStatic(template, 1);
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStatic(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('each', function (params, hash, template, inverse, builder) {
            //         Enter(BEGIN, END)
            // BEGIN:  Noop
            //         PutArgs
            //         PutIterable
            //         JumpUnless(ELSE)
            //         EnterList(BEGIN2, END2)
            // ITER:   Noop
            //         NextIter(BREAK)
            // BEGIN2: Noop
            //         PushChildScope
            //         Evaluate(default)
            //         PopScope
            // END2:   Noop
            //         Exit
            //         Jump(ITER)
            // BREAK:  Noop
            //         ExitList
            //         Jump(END)
            // ELSE:   Noop
            //         Evalulate(inverse)
            // END:    Noop
            //         Exit
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            if (hash && hash[0][0] === 'key') {
                expr(hash[1][0], builder);
            } else {
                builder.primitive(null);
            }
            expr(params[0], builder);
            builder.enter(2);
            builder.putIterator();
            builder.jumpUnless('ELSE');
            builder.pushFrame();
            builder.returnTo('ITER');
            builder.dup(Register.fp, 1);
            builder.enterList('BODY');
            builder.label('ITER');
            builder.iterate('BREAK');
            builder.label('BODY');
            builder.invokeStatic(template, 2);
            builder.pop(2);
            builder.exit();
            builder.return();
            builder.label('BREAK');
            builder.exitList();
            builder.popFrame();
            if (inverse) {
                builder.jump('EXIT');
                builder.label('ELSE');
                builder.invokeStatic(inverse);
                builder.label('EXIT');
                builder.exit();
                builder.return();
            } else {
                builder.label('ELSE');
                builder.exit();
                builder.return();
            }
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('-in-element', function (params, hash, template, _inverse, builder) {
            var keys, values;

            if (!params || params.length !== 1) {
                throw new Error('SYNTAX ERROR: #-in-element requires a single argument');
            }
            builder.startLabels();
            builder.pushFrame();
            builder.returnTo('END');
            if (hash && hash[0].length) {
                keys = hash[0], values = hash[1];


                if (keys.length === 1 && keys[0] === 'nextSibling') {
                    expr(values[0], builder);
                } else {
                    throw new Error('SYNTAX ERROR: #-in-element does not take a `' + keys[0] + '` option');
                }
            } else {
                expr(null, builder);
            }
            expr(params[0], builder);
            builder.dup();
            builder.test('simple');
            builder.enter(3);
            builder.jumpUnless('ELSE');
            builder.pushRemoteElement();
            builder.invokeStatic(template);
            builder.popRemoteElement();
            builder.label('ELSE');
            builder.exit();
            builder.return();
            builder.label('END');
            builder.popFrame();
            builder.stopLabels();
        });
        blocks.add('-with-dynamic-vars', function (_params, hash, template, _inverse, builder) {
            var names, expressions;

            if (hash) {
                names = hash[0], expressions = hash[1];


                compileList(expressions, builder);
                builder.pushDynamicScope();
                builder.bindDynamicScope(names);
                builder.invokeStatic(template);
                builder.popDynamicScope();
            } else {
                builder.invokeStatic(template);
            }
        });
        return { blocks: blocks, inlines: inlines };
    }
    function compileStatement(statement, builder) {
        STATEMENTS.compile(statement, builder);
    }
    function compileStatements(statements, meta, env) {
        var b = new OpcodeBuilder(env, meta),
            i;
        for (i = 0; i < statements.length; i++) {
            compileStatement(statements[i], b);
        }
        return b;
    }

    function _classCallCheck$16(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var CompilableTemplate = function () {
        function CompilableTemplate(statements, symbolTable) {
            _classCallCheck$16(this, CompilableTemplate);

            this.statements = statements;
            this.symbolTable = symbolTable;
            this.compiledStatic = null;
            this.compiledDynamic = null;
        }

        CompilableTemplate.prototype.compileStatic = function (env) {
            var compiledStatic = this.compiledStatic,
                builder,
                handle;

            if (!compiledStatic) {
                builder = compileStatements(this.statements, this.symbolTable.meta, env);

                builder.finalize();
                handle = builder.start;

                compiledStatic = this.compiledStatic = new CompiledStaticTemplate(handle);
            }
            return compiledStatic;
        };

        CompilableTemplate.prototype.compileDynamic = function (env) {
            var compiledDynamic = this.compiledDynamic,
                staticBlock;

            if (!compiledDynamic) {
                staticBlock = this.compileStatic(env);

                compiledDynamic = new CompiledDynamicTemplate(staticBlock.handle, this.symbolTable);
            }
            return compiledDynamic;
        };

        return CompilableTemplate;
    }();

    function _classCallCheck$15(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Ops$1 = _wireFormat.Ops;

    var Scanner = function () {
        function Scanner(block, env) {
            _classCallCheck$15(this, Scanner);

            this.block = block;
            this.env = env;
        }

        Scanner.prototype.scanEntryPoint = function (meta) {
            var block = this.block;
            var statements = block.statements,
                symbols = block.symbols,
                hasEval = block.hasEval;

            return new CompilableTemplate(statements, { meta: meta, symbols: symbols, hasEval: hasEval });
        };

        Scanner.prototype.scanBlock = function (meta) {
            var block = this.block;
            var statements = block.statements;

            return new CompilableTemplate(statements, { meta: meta, parameters: _util.EMPTY_ARRAY });
        };

        Scanner.prototype.scanLayout = function (meta, attrs, componentName) {
            var block = this.block,
                i,
                statement,
                tagName;
            var statements = block.statements,
                symbols = block.symbols,
                hasEval = block.hasEval;

            var newStatements = [];
            var toplevel = void 0;
            var inTopLevel = false;
            for (i = 0; i < statements.length; i++) {
                statement = statements[i];

                if (_wireFormat.Statements.isComponent(statement)) {
                    tagName = statement[1];

                    if (!this.env.hasComponentDefinition(tagName, meta.templateMeta)) {
                        if (toplevel !== undefined) {
                            newStatements.push([Ops$1.OpenElement, tagName]);
                        } else {
                            toplevel = tagName;
                            decorateTopLevelElement(tagName, symbols, attrs, newStatements);
                        }
                        addFallback(statement, newStatements);
                    } else {
                        if (toplevel === undefined && tagName === componentName) {
                            toplevel = tagName;
                            decorateTopLevelElement(tagName, symbols, attrs, newStatements);
                            addFallback(statement, newStatements);
                        } else {
                            newStatements.push(statement);
                        }
                    }
                } else {
                    if (toplevel === undefined && _wireFormat.Statements.isOpenElement(statement)) {
                        toplevel = statement[1];
                        inTopLevel = true;
                        decorateTopLevelElement(toplevel, symbols, attrs, newStatements);
                    } else {
                        if (inTopLevel) {
                            if (_wireFormat.Statements.isFlushElement(statement)) {
                                inTopLevel = false;
                            } else if (_wireFormat.Statements.isModifier(statement)) {
                                throw Error('Found modifier "' + statement[1] + '" on the top-level element of "' + componentName + '". Modifiers cannot be on the top-level element');
                            }
                        }
                        newStatements.push(statement);
                    }
                }
            }
            newStatements.push([Ops$1.ClientSideStatement, Ops$2.DidRenderLayout]);
            return new CompilableTemplate(newStatements, { meta: meta, hasEval: hasEval, symbols: symbols });
        };

        return Scanner;
    }();

    function addFallback(statement, buffer) {
        var attrs = statement[2],
            block = statement[4],
            i,
            statements,
            _i;

        for (i = 0; i < attrs.length; i++) {
            buffer.push(attrs[i]);
        }
        buffer.push([Ops$1.FlushElement]);
        if (block) {
            statements = block.statements;


            for (_i = 0; _i < statements.length; _i++) {
                buffer.push(statements[_i]);
            }
        }
        buffer.push([Ops$1.CloseElement]);
    }
    function decorateTopLevelElement(tagName, symbols, attrs, buffer) {
        var attrsSymbol = symbols.push(ATTRS_BLOCK);
        buffer.push([Ops$1.ClientSideStatement, Ops$2.OpenComponentElement, tagName]);
        buffer.push([Ops$1.ClientSideStatement, Ops$2.DidCreateElement]);
        buffer.push([Ops$1.Yield, attrsSymbol, _util.EMPTY_ARRAY]);
        buffer.push.apply(buffer, attrs);
    }

    function _classCallCheck$24(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Constants = function () {
        function Constants() {
            _classCallCheck$24(this, Constants);

            // `0` means NULL
            this.references = [];
            this.strings = [];
            this.expressions = [];
            this.floats = [];
            this.arrays = [];
            this.blocks = [];
            this.functions = [];
            this.others = [];
        }

        Constants.prototype.getReference = function (value) {
            return this.references[value - 1];
        };

        Constants.prototype.reference = function (value) {
            var index = this.references.length;
            this.references.push(value);
            return index + 1;
        };

        Constants.prototype.getString = function (value) {
            return this.strings[value - 1];
        };

        Constants.prototype.getFloat = function (value) {
            return this.floats[value - 1];
        };

        Constants.prototype.float = function (value) {
            return this.floats.push(value);
        };

        Constants.prototype.string = function (value) {
            var index = this.strings.length;
            this.strings.push(value);
            return index + 1;
        };

        Constants.prototype.getExpression = function (value) {
            return this.expressions[value - 1];
        };

        Constants.prototype.getArray = function (value) {
            return this.arrays[value - 1];
        };

        Constants.prototype.getNames = function (value) {
            var _names = [],
                i,
                n;
            var names = this.getArray(value);
            for (i = 0; i < names.length; i++) {
                n = names[i];

                _names[i] = this.getString(n);
            }
            return _names;
        };

        Constants.prototype.array = function (values) {
            var index = this.arrays.length;
            this.arrays.push(values);
            return index + 1;
        };

        Constants.prototype.getBlock = function (value) {
            return this.blocks[value - 1];
        };

        Constants.prototype.block = function (_block) {
            var index = this.blocks.length;
            this.blocks.push(_block);
            return index + 1;
        };

        Constants.prototype.getFunction = function (value) {
            return this.functions[value - 1];
        };

        Constants.prototype.function = function (f) {
            var index = this.functions.length;
            this.functions.push(f);
            return index + 1;
        };

        Constants.prototype.getOther = function (value) {
            return this.others[value - 1];
        };

        Constants.prototype.other = function (_other) {
            var index = this.others.length;
            this.others.push(_other);
            return index + 1;
        };

        return Constants;
    }();

    var badProtocols = ['javascript:', 'vbscript:'];
    var badTags = ['A', 'BODY', 'LINK', 'IMG', 'IFRAME', 'BASE', 'FORM'];
    var badTagsForDataURI = ['EMBED'];
    var badAttributes = ['href', 'src', 'background', 'action'];
    var badAttributesForDataURI = ['src'];
    function has(array, item) {
        return array.indexOf(item) !== -1;
    }
    function checkURI(tagName, attribute) {
        return (tagName === null || has(badTags, tagName)) && has(badAttributes, attribute);
    }
    function checkDataURI(tagName, attribute) {
        if (tagName === null) return false;
        return has(badTagsForDataURI, tagName) && has(badAttributesForDataURI, attribute);
    }
    function requiresSanitization(tagName, attribute) {
        return checkURI(tagName, attribute) || checkDataURI(tagName, attribute);
    }
    function sanitizeAttributeValue(env, element, attribute, value) {
        var tagName = null,
            protocol;
        if (value === null || value === undefined) {
            return value;
        }
        if (isSafeString(value)) {
            return value.toHTML();
        }
        if (!element) {
            tagName = null;
        } else {
            tagName = element.tagName.toUpperCase();
        }
        var str = normalizeTextValue(value);
        if (checkURI(tagName, attribute)) {
            protocol = env.protocolForURL(str);

            if (has(badProtocols, protocol)) {
                return 'unsafe:' + str;
            }
        }
        if (checkDataURI(tagName, attribute)) {
            return 'unsafe:' + str;
        }
        return str;
    }

    /*
     * @method normalizeProperty
     * @param element {HTMLElement}
     * @param slotName {String}
     * @returns {Object} { name, type }
     */
    function normalizeProperty(element, slotName) {
        var type = void 0,
            normalized = void 0,
            lower;
        if (slotName in element) {
            normalized = slotName;
            type = 'prop';
        } else {
            lower = slotName.toLowerCase();

            if (lower in element) {
                type = 'prop';
                normalized = lower;
            } else {
                type = 'attr';
                normalized = slotName;
            }
        }
        if (type === 'prop' && (normalized.toLowerCase() === 'style' || preferAttr(element.tagName, normalized))) {
            type = 'attr';
        }
        return { normalized: normalized, type: type };
    }

    // properties that MUST be set as attributes, due to:
    // * browser bug
    // * strange spec outlier
    var ATTR_OVERRIDES = {
        // phantomjs < 2.0 lets you set it as a prop but won't reflect it
        // back to the attribute. button.getAttribute('type') === null
        BUTTON: { type: true, form: true },
        INPUT: {
            // Some version of IE (like IE9) actually throw an exception
            // if you set input.type = 'something-unknown'
            type: true,
            form: true,
            // Chrome 46.0.2464.0: 'autocorrect' in document.createElement('input') === false
            // Safari 8.0.7: 'autocorrect' in document.createElement('input') === false
            // Mobile Safari (iOS 8.4 simulator): 'autocorrect' in document.createElement('input') === true
            autocorrect: true,
            // Chrome 54.0.2840.98: 'list' in document.createElement('input') === true
            // Safari 9.1.3: 'list' in document.createElement('input') === false
            list: true
        },
        // element.form is actually a legitimate readOnly property, that is to be
        // mutated, but must be mutated by setAttribute...
        SELECT: { form: true },
        OPTION: { form: true },
        TEXTAREA: { form: true },
        LABEL: { form: true },
        FIELDSET: { form: true },
        LEGEND: { form: true },
        OBJECT: { form: true }
    };
    function preferAttr(tagName, propName) {
        var tag = ATTR_OVERRIDES[tagName.toUpperCase()];
        return tag && tag[propName.toLowerCase()] || false;
    }

    function _defaults$12(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$27(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$12(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$12(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$12(subClass, superClass);
    }

    var innerHTMLWrapper = {
        colgroup: { depth: 2, before: '<table><colgroup>', after: '</colgroup></table>' },
        table: { depth: 1, before: '<table>', after: '</table>' },
        tbody: { depth: 2, before: '<table><tbody>', after: '</tbody></table>' },
        tfoot: { depth: 2, before: '<table><tfoot>', after: '</tfoot></table>' },
        thead: { depth: 2, before: '<table><thead>', after: '</thead></table>' },
        tr: { depth: 3, before: '<table><tbody><tr>', after: '</tr></tbody></table>' }
    };
    // Patch:    innerHTML Fix
    // Browsers: IE9
    // Reason:   IE9 don't allow us to set innerHTML on col, colgroup, frameset,
    //           html, style, table, tbody, tfoot, thead, title, tr.
    // Fix:      Wrap the innerHTML we are about to set in its parents, apply the
    //           wrapped innerHTML on a div, then move the unwrapped nodes into the
    //           target position.

    function treeConstruction(document, DOMTreeConstructionClass) {
        if (!document) return DOMTreeConstructionClass;
        if (!shouldApplyFix(document)) {
            return DOMTreeConstructionClass;
        }
        var div = document.createElement('div');
        return function (_DOMTreeConstructionC) {
            _inherits$12(DOMTreeConstructionWithInnerHTMLFix, _DOMTreeConstructionC);

            function DOMTreeConstructionWithInnerHTMLFix() {
                _classCallCheck$27(this, DOMTreeConstructionWithInnerHTMLFix);

                return _possibleConstructorReturn$12(this, _DOMTreeConstructionC.apply(this, arguments));
            }

            DOMTreeConstructionWithInnerHTMLFix.prototype.insertHTMLBefore = function (parent, referenceNode, html) {
                if (html === null || html === '') {
                    return _DOMTreeConstructionC.prototype.insertHTMLBefore.call(this, parent, referenceNode, html);
                }
                var parentTag = parent.tagName.toLowerCase();
                var wrapper = innerHTMLWrapper[parentTag];
                if (wrapper === undefined) {
                    return _DOMTreeConstructionC.prototype.insertHTMLBefore.call(this, parent, referenceNode, html);
                }
                return fixInnerHTML(parent, wrapper, div, html, referenceNode);
            };

            return DOMTreeConstructionWithInnerHTMLFix;
        }(DOMTreeConstructionClass);
    }
    function fixInnerHTML(parent, wrapper, div, html, reference$$1) {
        var wrappedHtml = wrapper.before + html + wrapper.after,
            i;
        div.innerHTML = wrappedHtml;
        var parentNode = div;
        for (i = 0; i < wrapper.depth; i++) {
            parentNode = parentNode.childNodes[0];
        }

        var _moveNodesBefore = moveNodesBefore(parentNode, parent, reference$$1),
            first = _moveNodesBefore[0],
            last = _moveNodesBefore[1];

        return new ConcreteBounds(parent, first, last);
    }
    function shouldApplyFix(document) {
        var table = document.createElement('table');
        try {
            table.innerHTML = '<tbody></tbody>';
        } catch (e) {} finally {
            if (table.childNodes.length !== 0) {
                // It worked as expected, no fix required
                return false;
            }
        }
        return true;
    }

    function _defaults$13(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$28(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$13(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$13(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$13(subClass, superClass);
    }

    // Patch:    insertAdjacentHTML on SVG Fix
    // Browsers: Safari, IE, Edge, Firefox ~33-34
    // Reason:   insertAdjacentHTML does not exist on SVG elements in Safari. It is
    //           present but throws an exception on IE and Edge. Old versions of
    //           Firefox create nodes in the incorrect namespace.
    // Fix:      Since IE and Edge silently fail to create SVG nodes using
    //           innerHTML, and because Firefox may create nodes in the incorrect
    //           namespace using innerHTML on SVG elements, an HTML-string wrapping
    //           approach is used. A pre/post SVG tag is added to the string, then
    //           that whole string is added to a div. The created nodes are plucked
    //           out and applied to the target location on DOM.
    function treeConstruction$1(document, TreeConstructionClass, svgNamespace) {
        if (!document) return TreeConstructionClass;
        if (!shouldApplyFix$1(document, svgNamespace)) {
            return TreeConstructionClass;
        }
        var div = document.createElement('div');
        return function (_TreeConstructionClas) {
            _inherits$13(TreeConstructionWithSVGInnerHTMLFix, _TreeConstructionClas);

            function TreeConstructionWithSVGInnerHTMLFix() {
                _classCallCheck$28(this, TreeConstructionWithSVGInnerHTMLFix);

                return _possibleConstructorReturn$13(this, _TreeConstructionClas.apply(this, arguments));
            }

            TreeConstructionWithSVGInnerHTMLFix.prototype.insertHTMLBefore = function (parent, reference$$1, html) {
                if (html === null || html === '') {
                    return _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, reference$$1, html);
                }
                if (parent.namespaceURI !== svgNamespace) {
                    return _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, reference$$1, html);
                }
                return fixSVG(parent, div, html, reference$$1);
            };

            return TreeConstructionWithSVGInnerHTMLFix;
        }(TreeConstructionClass);
    }
    function fixSVG(parent, div, html, reference$$1) {
        div.innerHTML = '<svg>' + html + '</svg>';
        // IE, Edge: also do not correctly support using `innerHTML` on SVG
        // namespaced elements. So here a wrapper is used.


        var _moveNodesBefore = moveNodesBefore(div.firstChild, parent, reference$$1),
            first = _moveNodesBefore[0],
            last = _moveNodesBefore[1];

        return new ConcreteBounds(parent, first, last);
    }
    function shouldApplyFix$1(document, svgNamespace) {
        var svg = document.createElementNS(svgNamespace, 'svg');
        try {
            svg['insertAdjacentHTML']('beforeend', '<circle></circle>');
        } catch (e) {
            // IE, Edge: Will throw, insertAdjacentHTML is unsupported on SVG
            // Safari: Will throw, insertAdjacentHTML is not present on SVG
        } finally {
            // FF: Old versions will create a node in the wrong namespace
            if (svg.childNodes.length === 1 && svg.firstChild.namespaceURI === 'http://www.w3.org/2000/svg') {
                // The test worked as expected, no fix required
                return false;
            }
            return true;
        }
    }

    function _defaults$14(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck$29(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn$14(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$14(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$14(subClass, superClass);
    }

    // Patch:    Adjacent text node merging fix
    // Browsers: IE, Edge, Firefox w/o inspector open
    // Reason:   These browsers will merge adjacent text nodes. For exmaple given
    //           <div>Hello</div> with div.insertAdjacentHTML(' world') browsers
    //           with proper behavior will populate div.childNodes with two items.
    //           These browsers will populate it with one merged node instead.
    // Fix:      Add these nodes to a wrapper element, then iterate the childNodes
    //           of that wrapper and move the nodes to their target location. Note
    //           that potential SVG bugs will have been handled before this fix.
    //           Note that this fix must only apply to the previous text node, as
    //           the base implementation of `insertHTMLBefore` already handles
    //           following text nodes correctly.

    function treeConstruction$2(document, TreeConstructionClass) {
        if (!document) return TreeConstructionClass;
        if (!shouldApplyFix$2(document)) {
            return TreeConstructionClass;
        }
        return function (_TreeConstructionClas) {
            _inherits$14(TreeConstructionWithTextNodeMergingFix, _TreeConstructionClas);

            function TreeConstructionWithTextNodeMergingFix(document) {
                _classCallCheck$29(this, TreeConstructionWithTextNodeMergingFix);

                var _this2 = _possibleConstructorReturn$14(this, _TreeConstructionClas.call(this, document));

                _this2.uselessComment = _this2.createComment('');
                return _this2;
            }

            TreeConstructionWithTextNodeMergingFix.prototype.insertHTMLBefore = function (parent, reference$$1, html) {
                if (html === null) {
                    return _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, reference$$1, html);
                }
                var didSetUselessComment = false;
                var nextPrevious = reference$$1 ? reference$$1.previousSibling : parent.lastChild;
                if (nextPrevious && nextPrevious instanceof Text) {
                    didSetUselessComment = true;
                    parent.insertBefore(this.uselessComment, reference$$1);
                }
                var bounds = _TreeConstructionClas.prototype.insertHTMLBefore.call(this, parent, reference$$1, html);
                if (didSetUselessComment) {
                    parent.removeChild(this.uselessComment);
                }
                return bounds;
            };

            return TreeConstructionWithTextNodeMergingFix;
        }(TreeConstructionClass);
    }
    function shouldApplyFix$2(document) {
        var mergingTextDiv = document.createElement('div');
        mergingTextDiv.innerHTML = 'first';
        mergingTextDiv.insertAdjacentHTML('beforeend', 'second');
        if (mergingTextDiv.childNodes.length === 2) {
            // It worked as expected, no fix required
            return false;
        }
        return true;
    }

    function _defaults$11(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$11(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$11(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$11(subClass, superClass);
    }

    function _classCallCheck$26(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
    // http://www.w3.org/TR/html/syntax.html#html-integration-point
    var SVG_INTEGRATION_POINTS = { foreignObject: 1, desc: 1, title: 1 };
    // http://www.w3.org/TR/html/syntax.html#adjust-svg-attributes
    // TODO: Adjust SVG attributes
    // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign
    // TODO: Adjust SVG elements
    // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign
    var BLACKLIST_TABLE = Object.create(null);
    ["b", "big", "blockquote", "body", "br", "center", "code", "dd", "div", "dl", "dt", "em", "embed", "h1", "h2", "h3", "h4", "h5", "h6", "head", "hr", "i", "img", "li", "listing", "main", "meta", "nobr", "ol", "p", "pre", "ruby", "s", "small", "span", "strong", "strike", "sub", "sup", "table", "tt", "u", "ul", "var"].forEach(function (tag) {
        return BLACKLIST_TABLE[tag] = 1;
    });
    var WHITESPACE = /[\t-\r \xA0\u1680\u180E\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]/;
    var doc = typeof document === 'undefined' ? null : document;

    function moveNodesBefore(source, target, nextSibling) {
        var first = source.firstChild;
        var last = null;
        var current = first;
        while (current) {
            last = current;
            current = current.nextSibling;
            target.insertBefore(last, nextSibling);
        }
        return [first, last];
    }
    var DOMOperations = function () {
        function DOMOperations(document) {
            _classCallCheck$26(this, DOMOperations);

            this.document = document;
            this.setupUselessElement();
        }
        // split into seperate method so that NodeDOMTreeConstruction
        // can override it.


        DOMOperations.prototype.setupUselessElement = function () {
            this.uselessElement = this.document.createElement('div');
        };

        DOMOperations.prototype.createElement = function (tag, context) {
            var isElementInSVGNamespace = void 0,
                isHTMLIntegrationPoint = void 0;
            if (context) {
                isElementInSVGNamespace = context.namespaceURI === SVG_NAMESPACE || tag === 'svg';
                isHTMLIntegrationPoint = SVG_INTEGRATION_POINTS[context.tagName];
            } else {
                isElementInSVGNamespace = tag === 'svg';
                isHTMLIntegrationPoint = false;
            }
            if (isElementInSVGNamespace && !isHTMLIntegrationPoint) {
                // FIXME: This does not properly handle <font> with color, face, or
                // size attributes, which is also disallowed by the spec. We should fix
                // this.
                if (BLACKLIST_TABLE[tag]) {
                    throw new Error('Cannot create a ' + tag + ' inside an SVG context');
                }
                return this.document.createElementNS(SVG_NAMESPACE, tag);
            } else {
                return this.document.createElement(tag);
            }
        };

        DOMOperations.prototype.insertBefore = function (parent, node, reference$$1) {
            parent.insertBefore(node, reference$$1);
        };

        DOMOperations.prototype.insertHTMLBefore = function (_parent, nextSibling, html) {
            return _insertHTMLBefore(this.uselessElement, _parent, nextSibling, html);
        };

        DOMOperations.prototype.createTextNode = function (text) {
            return this.document.createTextNode(text);
        };

        DOMOperations.prototype.createComment = function (data) {
            return this.document.createComment(data);
        };

        return DOMOperations;
    }();
    var DOM;
    (function (DOM) {
        var TreeConstruction = function (_DOMOperations) {
            _inherits$11(TreeConstruction, _DOMOperations);

            function TreeConstruction() {
                _classCallCheck$26(this, TreeConstruction);

                return _possibleConstructorReturn$11(this, _DOMOperations.apply(this, arguments));
            }

            TreeConstruction.prototype.createElementNS = function (namespace, tag) {
                return this.document.createElementNS(namespace, tag);
            };

            TreeConstruction.prototype.setAttribute = function (element, name, value, namespace) {
                if (namespace) {
                    element.setAttributeNS(namespace, name, value);
                } else {
                    element.setAttribute(name, value);
                }
            };

            return TreeConstruction;
        }(DOMOperations);

        DOM.TreeConstruction = TreeConstruction;
        var appliedTreeContruction = TreeConstruction;
        appliedTreeContruction = treeConstruction$2(doc, appliedTreeContruction);
        appliedTreeContruction = treeConstruction(doc, appliedTreeContruction);
        appliedTreeContruction = treeConstruction$1(doc, appliedTreeContruction, SVG_NAMESPACE);
        DOM.DOMTreeConstruction = appliedTreeContruction;
    })(DOM || (DOM = {}));
    var DOMChanges = function (_DOMOperations2) {
        _inherits$11(DOMChanges, _DOMOperations2);

        function DOMChanges(document) {
            _classCallCheck$26(this, DOMChanges);

            var _this2 = _possibleConstructorReturn$11(this, _DOMOperations2.call(this, document));

            _this2.document = document;
            _this2.namespace = null;
            return _this2;
        }

        DOMChanges.prototype.setAttribute = function (element, name, value) {
            element.setAttribute(name, value);
        };

        DOMChanges.prototype.setAttributeNS = function (element, namespace, name, value) {
            element.setAttributeNS(namespace, name, value);
        };

        DOMChanges.prototype.removeAttribute = function (element, name) {
            element.removeAttribute(name);
        };

        DOMChanges.prototype.removeAttributeNS = function (element, namespace, name) {
            element.removeAttributeNS(namespace, name);
        };

        DOMChanges.prototype.insertNodeBefore = function (parent, node, reference$$1) {
            var firstChild, lastChild;

            if (isDocumentFragment(node)) {
                firstChild = node.firstChild, lastChild = node.lastChild;


                this.insertBefore(parent, node, reference$$1);
                return new ConcreteBounds(parent, firstChild, lastChild);
            } else {
                this.insertBefore(parent, node, reference$$1);
                return new SingleNodeBounds(parent, node);
            }
        };

        DOMChanges.prototype.insertTextBefore = function (parent, nextSibling, text) {
            var textNode = this.createTextNode(text);
            this.insertBefore(parent, textNode, nextSibling);
            return textNode;
        };

        DOMChanges.prototype.insertBefore = function (element, node, reference$$1) {
            element.insertBefore(node, reference$$1);
        };

        DOMChanges.prototype.insertAfter = function (element, node, reference$$1) {
            this.insertBefore(element, node, reference$$1.nextSibling);
        };

        return DOMChanges;
    }(DOMOperations);
    function _insertHTMLBefore(_useless, _parent, _nextSibling, html) {
        // TypeScript vendored an old version of the DOM spec where `insertAdjacentHTML`
        // only exists on `HTMLElement` but not on `Element`. We actually work with the
        // newer version of the DOM API here (and monkey-patch this method in `./compat`
        // when we detect older browsers). This is a hack to work around this limitation.
        var parent = _parent;
        var useless = _useless;
        var nextSibling = _nextSibling;
        var prev = nextSibling ? nextSibling.previousSibling : parent.lastChild;
        var last = void 0;
        if (html === null || html === '') {
            return new ConcreteBounds(parent, null, null);
        }
        if (nextSibling === null) {
            parent.insertAdjacentHTML('beforeend', html);
            last = parent.lastChild;
        } else if (nextSibling instanceof HTMLElement) {
            nextSibling.insertAdjacentHTML('beforebegin', html);
            last = nextSibling.previousSibling;
        } else {
            // Non-element nodes do not support insertAdjacentHTML, so add an
            // element and call it on that element. Then remove the element.
            //
            // This also protects Edge, IE and Firefox w/o the inspector open
            // from merging adjacent text nodes. See ./compat/text-node-merging-fix.ts
            parent.insertBefore(useless, nextSibling);
            useless.insertAdjacentHTML('beforebegin', html);
            last = useless.previousSibling;
            parent.removeChild(useless);
        }
        var first = prev ? prev.nextSibling : parent.firstChild;
        return new ConcreteBounds(parent, first, last);
    }
    function isDocumentFragment(node) {
        return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
    }
    var helper = DOMChanges;
    helper = function (document, DOMChangesClass) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix$2(document)) {
            return DOMChangesClass;
        }
        return function (_DOMChangesClass) {
            _inherits$14(DOMChangesWithTextNodeMergingFix, _DOMChangesClass);

            function DOMChangesWithTextNodeMergingFix(document) {
                _classCallCheck$29(this, DOMChangesWithTextNodeMergingFix);

                var _this = _possibleConstructorReturn$14(this, _DOMChangesClass.call(this, document));

                _this.uselessComment = document.createComment('');
                return _this;
            }

            DOMChangesWithTextNodeMergingFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null) {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                var didSetUselessComment = false;
                var nextPrevious = nextSibling ? nextSibling.previousSibling : parent.lastChild;
                if (nextPrevious && nextPrevious instanceof Text) {
                    didSetUselessComment = true;
                    parent.insertBefore(this.uselessComment, nextSibling);
                }
                var bounds = _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                if (didSetUselessComment) {
                    parent.removeChild(this.uselessComment);
                }
                return bounds;
            };

            return DOMChangesWithTextNodeMergingFix;
        }(DOMChangesClass);
    }(doc, helper);
    helper = function (document, DOMChangesClass) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix(document)) {
            return DOMChangesClass;
        }
        var div = document.createElement('div');
        return function (_DOMChangesClass) {
            _inherits$12(DOMChangesWithInnerHTMLFix, _DOMChangesClass);

            function DOMChangesWithInnerHTMLFix() {
                _classCallCheck$27(this, DOMChangesWithInnerHTMLFix);

                return _possibleConstructorReturn$12(this, _DOMChangesClass.apply(this, arguments));
            }

            DOMChangesWithInnerHTMLFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null || html === '') {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                var parentTag = parent.tagName.toLowerCase();
                var wrapper = innerHTMLWrapper[parentTag];
                if (wrapper === undefined) {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                return fixInnerHTML(parent, wrapper, div, html, nextSibling);
            };

            return DOMChangesWithInnerHTMLFix;
        }(DOMChangesClass);
    }(doc, helper);
    helper = function (document, DOMChangesClass, svgNamespace) {
        if (!document) return DOMChangesClass;
        if (!shouldApplyFix$1(document, svgNamespace)) {
            return DOMChangesClass;
        }
        var div = document.createElement('div');
        return function (_DOMChangesClass) {
            _inherits$13(DOMChangesWithSVGInnerHTMLFix, _DOMChangesClass);

            function DOMChangesWithSVGInnerHTMLFix() {
                _classCallCheck$28(this, DOMChangesWithSVGInnerHTMLFix);

                return _possibleConstructorReturn$13(this, _DOMChangesClass.apply(this, arguments));
            }

            DOMChangesWithSVGInnerHTMLFix.prototype.insertHTMLBefore = function (parent, nextSibling, html) {
                if (html === null || html === '') {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                if (parent.namespaceURI !== svgNamespace) {
                    return _DOMChangesClass.prototype.insertHTMLBefore.call(this, parent, nextSibling, html);
                }
                return fixSVG(parent, div, html, nextSibling);
            };

            return DOMChangesWithSVGInnerHTMLFix;
        }(DOMChangesClass);
    }(doc, helper, SVG_NAMESPACE);
    var helper$1 = helper;
    var DOMTreeConstruction = DOM.DOMTreeConstruction;

    function _defaults$10(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _possibleConstructorReturn$10(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$10(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$10(subClass, superClass);
    }

    function _classCallCheck$25(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function defaultManagers(element, attr) {
        var tagName = element.tagName;
        var isSVG = element.namespaceURI === SVG_NAMESPACE;
        if (isSVG) {
            return defaultAttributeManagers(tagName, attr);
        }

        var _normalizeProperty = normalizeProperty(element, attr),
            type = _normalizeProperty.type,
            normalized = _normalizeProperty.normalized;

        if (type === 'attr') {
            return defaultAttributeManagers(tagName, normalized);
        } else {
            return defaultPropertyManagers(tagName, normalized);
        }
    }
    function defaultPropertyManagers(tagName, attr) {
        if (requiresSanitization(tagName, attr)) {
            return new SafePropertyManager(attr);
        }
        if (isUserInputValue(tagName, attr)) {
            return INPUT_VALUE_PROPERTY_MANAGER;
        }
        if (isOptionSelected(tagName, attr)) {
            return OPTION_SELECTED_MANAGER;
        }
        return new PropertyManager(attr);
    }
    function defaultAttributeManagers(tagName, attr) {
        if (requiresSanitization(tagName, attr)) {
            return new SafeAttributeManager(attr);
        }
        return new AttributeManager(attr);
    }


    var AttributeManager = function () {
        function AttributeManager(attr) {
            _classCallCheck$25(this, AttributeManager);

            this.attr = attr;
        }

        AttributeManager.prototype.setAttribute = function (env, element, value, namespace) {
            var dom = env.getAppendOperations();
            var normalizedValue = normalizeAttributeValue(value);
            if (!isAttrRemovalValue(normalizedValue)) {
                dom.setAttribute(element, this.attr, normalizedValue, namespace);
            }
        };

        AttributeManager.prototype.updateAttribute = function (env, element, value, namespace) {
            if (value === null || value === undefined || value === false) {
                if (namespace) {
                    env.getDOM().removeAttributeNS(element, namespace, this.attr);
                } else {
                    env.getDOM().removeAttribute(element, this.attr);
                }
            } else {
                this.setAttribute(env, element, value);
            }
        };

        return AttributeManager;
    }();

    var PropertyManager = function (_AttributeManager) {
        _inherits$10(PropertyManager, _AttributeManager);

        function PropertyManager() {
            _classCallCheck$25(this, PropertyManager);

            return _possibleConstructorReturn$10(this, _AttributeManager.apply(this, arguments));
        }

        PropertyManager.prototype.setAttribute = function (_env, element, value) {
            if (!isAttrRemovalValue(value)) {
                element[this.attr] = value;
            }
        };

        PropertyManager.prototype.removeAttribute = function (env, element, namespace) {
            // TODO this sucks but to preserve properties first and to meet current
            // semantics we must do this.
            var attr = this.attr;

            if (namespace) {
                env.getDOM().removeAttributeNS(element, namespace, attr);
            } else {
                env.getDOM().removeAttribute(element, attr);
            }
        };

        PropertyManager.prototype.updateAttribute = function (env, element, value, namespace) {
            // ensure the property is always updated
            element[this.attr] = value;
            if (isAttrRemovalValue(value)) {
                this.removeAttribute(env, element, namespace);
            }
        };

        return PropertyManager;
    }(AttributeManager);

    function normalizeAttributeValue(value) {
        if (value === false || value === undefined || value === null) {
            return null;
        }
        if (value === true) {
            return '';
        }
        // onclick function etc in SSR
        if (typeof value === 'function') {
            return null;
        }
        return String(value);
    }
    function isAttrRemovalValue(value) {
        return value === null || value === undefined;
    }

    var SafePropertyManager = function (_PropertyManager) {
        _inherits$10(SafePropertyManager, _PropertyManager);

        function SafePropertyManager() {
            _classCallCheck$25(this, SafePropertyManager);

            return _possibleConstructorReturn$10(this, _PropertyManager.apply(this, arguments));
        }

        SafePropertyManager.prototype.setAttribute = function (env, element, value) {
            _PropertyManager.prototype.setAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        SafePropertyManager.prototype.updateAttribute = function (env, element, value) {
            _PropertyManager.prototype.updateAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        return SafePropertyManager;
    }(PropertyManager);

    function isUserInputValue(tagName, attribute) {
        return (tagName === 'INPUT' || tagName === 'TEXTAREA') && attribute === 'value';
    }

    var InputValuePropertyManager = function (_AttributeManager2) {
        _inherits$10(InputValuePropertyManager, _AttributeManager2);

        function InputValuePropertyManager() {
            _classCallCheck$25(this, InputValuePropertyManager);

            return _possibleConstructorReturn$10(this, _AttributeManager2.apply(this, arguments));
        }

        InputValuePropertyManager.prototype.setAttribute = function (_env, element, value) {
            element.value = normalizeTextValue(value);
        };

        InputValuePropertyManager.prototype.updateAttribute = function (_env, element, value) {
            var input = element;
            var currentValue = input.value;
            var normalizedValue = normalizeTextValue(value);
            if (currentValue !== normalizedValue) {
                input.value = normalizedValue;
            }
        };

        return InputValuePropertyManager;
    }(AttributeManager);

    var INPUT_VALUE_PROPERTY_MANAGER = new InputValuePropertyManager('value');
    function isOptionSelected(tagName, attribute) {
        return tagName === 'OPTION' && attribute === 'selected';
    }

    var OptionSelectedManager = function (_PropertyManager2) {
        _inherits$10(OptionSelectedManager, _PropertyManager2);

        function OptionSelectedManager() {
            _classCallCheck$25(this, OptionSelectedManager);

            return _possibleConstructorReturn$10(this, _PropertyManager2.apply(this, arguments));
        }

        OptionSelectedManager.prototype.setAttribute = function (_env, element, value) {
            if (value !== null && value !== undefined && value !== false) {
                element.selected = true;
            }
        };

        OptionSelectedManager.prototype.updateAttribute = function (_env, element, value) {
            var option = element;
            if (value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        };

        return OptionSelectedManager;
    }(PropertyManager);

    var OPTION_SELECTED_MANAGER = new OptionSelectedManager('selected');

    var SafeAttributeManager = function (_AttributeManager3) {
        _inherits$10(SafeAttributeManager, _AttributeManager3);

        function SafeAttributeManager() {
            _classCallCheck$25(this, SafeAttributeManager);

            return _possibleConstructorReturn$10(this, _AttributeManager3.apply(this, arguments));
        }

        SafeAttributeManager.prototype.setAttribute = function (env, element, value) {
            _AttributeManager3.prototype.setAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        SafeAttributeManager.prototype.updateAttribute = function (env, element, value) {
            _AttributeManager3.prototype.updateAttribute.call(this, env, element, sanitizeAttributeValue(env, element, this.attr, value));
        };

        return SafeAttributeManager;
    }(AttributeManager);

    var _createClass$4 = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _classCallCheck$23(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Scope = function () {
        function Scope(
        // the 0th slot is `self`
        slots, callerScope,
        // named arguments and blocks passed to a layout that uses eval
        evalScope,
        // locals in scope when the partial was invoked
        partialMap) {
            _classCallCheck$23(this, Scope);

            this.slots = slots;
            this.callerScope = callerScope;
            this.evalScope = evalScope;
            this.partialMap = partialMap;
        }

        Scope.root = function (self) {
            var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0,
                i;

            var refs = new Array(size + 1);
            for (i = 0; i <= size; i++) {
                refs[i] = UNDEFINED_REFERENCE;
            }
            return new Scope(refs, null, null, null).init({ self: self });
        };

        Scope.sized = function () {
            var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0,
                i;

            var refs = new Array(size + 1);
            for (i = 0; i <= size; i++) {
                refs[i] = UNDEFINED_REFERENCE;
            }
            return new Scope(refs, null, null, null);
        };

        Scope.prototype.init = function (_ref) {
            var self = _ref.self;

            this.slots[0] = self;
            return this;
        };

        Scope.prototype.getSelf = function () {
            return this.get(0);
        };

        Scope.prototype.getSymbol = function (symbol) {
            return this.get(symbol);
        };

        Scope.prototype.getBlock = function (symbol) {
            return this.get(symbol);
        };

        Scope.prototype.getEvalScope = function () {
            return this.evalScope;
        };

        Scope.prototype.getPartialMap = function () {
            return this.partialMap;
        };

        Scope.prototype.bind = function (symbol, value) {
            this.set(symbol, value);
        };

        Scope.prototype.bindSelf = function (self) {
            this.set(0, self);
        };

        Scope.prototype.bindSymbol = function (symbol, value) {
            this.set(symbol, value);
        };

        Scope.prototype.bindBlock = function (symbol, value) {
            this.set(symbol, value);
        };

        Scope.prototype.bindEvalScope = function (map$$1) {
            this.evalScope = map$$1;
        };

        Scope.prototype.bindPartialMap = function (map$$1) {
            this.partialMap = map$$1;
        };

        Scope.prototype.bindCallerScope = function (scope) {
            this.callerScope = scope;
        };

        Scope.prototype.getCallerScope = function () {
            return this.callerScope;
        };

        Scope.prototype.child = function () {
            return new Scope(this.slots.slice(), this.callerScope, this.evalScope, this.partialMap);
        };

        Scope.prototype.get = function (index) {
            if (index >= this.slots.length) {
                throw new RangeError('BUG: cannot get $' + index + ' from scope; length=' + this.slots.length);
            }
            return this.slots[index];
        };

        Scope.prototype.set = function (index, value) {
            if (index >= this.slots.length) {
                throw new RangeError('BUG: cannot get $' + index + ' from scope; length=' + this.slots.length);
            }
            this.slots[index] = value;
        };

        return Scope;
    }();

    var Transaction = function () {
        function Transaction() {
            _classCallCheck$23(this, Transaction);

            this.scheduledInstallManagers = [];
            this.scheduledInstallModifiers = [];
            this.scheduledUpdateModifierManagers = [];
            this.scheduledUpdateModifiers = [];
            this.createdComponents = [];
            this.createdManagers = [];
            this.updatedComponents = [];
            this.updatedManagers = [];
            this.destructors = [];
        }

        Transaction.prototype.didCreate = function (component, manager) {
            this.createdComponents.push(component);
            this.createdManagers.push(manager);
        };

        Transaction.prototype.didUpdate = function (component, manager) {
            this.updatedComponents.push(component);
            this.updatedManagers.push(manager);
        };

        Transaction.prototype.scheduleInstallModifier = function (modifier, manager) {
            this.scheduledInstallManagers.push(manager);
            this.scheduledInstallModifiers.push(modifier);
        };

        Transaction.prototype.scheduleUpdateModifier = function (modifier, manager) {
            this.scheduledUpdateModifierManagers.push(manager);
            this.scheduledUpdateModifiers.push(modifier);
        };

        Transaction.prototype.didDestroy = function (d) {
            this.destructors.push(d);
        };

        Transaction.prototype.commit = function () {
            var createdComponents = this.createdComponents,
                createdManagers = this.createdManagers,
                i,
                component,
                manager,
                _i,
                _component,
                _manager,
                _i2,
                _i3,
                _manager2,
                modifier,
                _i4,
                _manager3,
                _modifier;

            for (i = 0; i < createdComponents.length; i++) {
                component = createdComponents[i];
                manager = createdManagers[i];

                manager.didCreate(component);
            }
            var updatedComponents = this.updatedComponents,
                updatedManagers = this.updatedManagers;

            for (_i = 0; _i < updatedComponents.length; _i++) {
                _component = updatedComponents[_i];
                _manager = updatedManagers[_i];

                _manager.didUpdate(_component);
            }
            var destructors = this.destructors;

            for (_i2 = 0; _i2 < destructors.length; _i2++) {
                destructors[_i2].destroy();
            }
            var scheduledInstallManagers = this.scheduledInstallManagers,
                scheduledInstallModifiers = this.scheduledInstallModifiers;

            for (_i3 = 0; _i3 < scheduledInstallManagers.length; _i3++) {
                _manager2 = scheduledInstallManagers[_i3];
                modifier = scheduledInstallModifiers[_i3];

                _manager2.install(modifier);
            }
            var scheduledUpdateModifierManagers = this.scheduledUpdateModifierManagers,
                scheduledUpdateModifiers = this.scheduledUpdateModifiers;

            for (_i4 = 0; _i4 < scheduledUpdateModifierManagers.length; _i4++) {
                _manager3 = scheduledUpdateModifierManagers[_i4];
                _modifier = scheduledUpdateModifiers[_i4];

                _manager3.update(_modifier);
            }
        };

        return Transaction;
    }();

    var Opcode = function () {
        function Opcode(heap) {
            _classCallCheck$23(this, Opcode);

            this.heap = heap;
            this.offset = 0;
        }

        _createClass$4(Opcode, [{
            key: 'type',
            get: function () {
                return this.heap.getbyaddr(this.offset);
            }
        }, {
            key: 'op1',
            get: function () {
                return this.heap.getbyaddr(this.offset + 1);
            }
        }, {
            key: 'op2',
            get: function () {
                return this.heap.getbyaddr(this.offset + 2);
            }
        }, {
            key: 'op3',
            get: function () {
                return this.heap.getbyaddr(this.offset + 3);
            }
        }]);

        return Opcode;
    }();
    var TableSlotState;
    (function (TableSlotState) {
        TableSlotState[TableSlotState["Allocated"] = 0] = "Allocated";
        TableSlotState[TableSlotState["Freed"] = 1] = "Freed";
        TableSlotState[TableSlotState["Purged"] = 2] = "Purged";
        TableSlotState[TableSlotState["Pointer"] = 3] = "Pointer";
    })(TableSlotState || (TableSlotState = {}));
    var Heap = function () {
        function Heap() {
            _classCallCheck$23(this, Heap);

            this.heap = [];
            this.offset = 0;
            this.handle = 0;
            /**
             * layout:
             *
             * - pointer into heap
             * - size
             * - freed (0 or 1)
             */
            this.table = [];
        }

        Heap.prototype.push = function (item) {
            this.heap[this.offset++] = item;
        };

        Heap.prototype.getbyaddr = function (address) {
            return this.heap[address];
        };

        Heap.prototype.setbyaddr = function (address, value) {
            this.heap[address] = value;
        };

        Heap.prototype.malloc = function () {
            this.table.push(this.offset, 0, 0);
            var handle = this.handle;
            this.handle += 3;
            return handle;
        };

        Heap.prototype.finishMalloc = function (handle) {
            var start = this.table[handle];
            var finish = this.offset;
            this.table[handle + 1] = finish - start;
        };

        Heap.prototype.size = function () {
            return this.offset;
        };
        // It is illegal to close over this address, as compaction
        // may move it. However, it is legal to use this address
        // multiple times between compactions.


        Heap.prototype.getaddr = function (handle) {
            return this.table[handle];
        };

        Heap.prototype.gethandle = function (address) {
            this.table.push(address, 0, TableSlotState.Pointer);
            var handle = this.handle;
            this.handle += 3;
            return handle;
        };

        Heap.prototype.sizeof = function () {
            return -1;
        };

        Heap.prototype.free = function (handle) {
            this.table[handle + 2] = 1;
        };

        Heap.prototype.compact = function () {
            var compactedSize = 0,
                i,
                offset,
                size,
                state,
                j;
            var table = this.table,
                length = this.table.length,
                heap = this.heap;

            for (i = 0; i < length; i += 3) {
                offset = table[i];
                size = table[i + 1];
                state = table[i + 2];

                if (state === TableSlotState.Purged) {
                    continue;
                } else if (state === TableSlotState.Freed) {
                    // transition to "already freed"
                    // a good improvement would be to reuse
                    // these slots
                    table[i + 2] = 2;
                    compactedSize += size;
                } else if (state === TableSlotState.Allocated) {
                    for (j = offset; j <= i + size; j++) {
                        heap[j - compactedSize] = heap[j];
                    }
                    table[i] = offset - compactedSize;
                } else if (state === TableSlotState.Pointer) {
                    table[i] = offset - compactedSize;
                }
            }
            this.offset = this.offset - compactedSize;
        };

        return Heap;
    }();
    var Program = function () {
        function Program() {
            _classCallCheck$23(this, Program);

            this.heap = new Heap();
            this._opcode = new Opcode(this.heap);
            this.constants = new Constants();
        }

        Program.prototype.opcode = function (offset) {
            this._opcode.offset = offset;
            return this._opcode;
        };

        return Program;
    }();
    var Environment = function () {
        function Environment(_ref2) {
            var appendOperations = _ref2.appendOperations,
                updateOperations = _ref2.updateOperations;

            _classCallCheck$23(this, Environment);

            this._macros = null;
            this._transaction = null;
            this.program = new Program();
            this.appendOperations = appendOperations;
            this.updateOperations = updateOperations;
        }

        Environment.prototype.toConditionalReference = function (reference$$1) {
            return new ConditionalReference(reference$$1);
        };

        Environment.prototype.getAppendOperations = function () {
            return this.appendOperations;
        };

        Environment.prototype.getDOM = function () {
            return this.updateOperations;
        };

        Environment.prototype.getIdentity = function (object) {
            return (0, _util.ensureGuid)(object) + '';
        };

        Environment.prototype.begin = function () {
            (0, _util.assert)(!this._transaction, 'a glimmer transaction was begun, but one already exists. You may have a nested transaction');
            this._transaction = new Transaction();
        };

        Environment.prototype.didCreate = function (component, manager) {
            this.transaction.didCreate(component, manager);
        };

        Environment.prototype.didUpdate = function (component, manager) {
            this.transaction.didUpdate(component, manager);
        };

        Environment.prototype.scheduleInstallModifier = function (modifier, manager) {
            this.transaction.scheduleInstallModifier(modifier, manager);
        };

        Environment.prototype.scheduleUpdateModifier = function (modifier, manager) {
            this.transaction.scheduleUpdateModifier(modifier, manager);
        };

        Environment.prototype.didDestroy = function (d) {
            this.transaction.didDestroy(d);
        };

        Environment.prototype.commit = function () {
            var transaction = this.transaction;
            this._transaction = null;
            transaction.commit();
        };

        Environment.prototype.attributeFor = function (element, attr, isTrusting, namespace) {
            return defaultManagers(element, attr, isTrusting, namespace === undefined ? null : namespace);
        };

        Environment.prototype.macros = function () {
            var macros = this._macros;
            if (!macros) {
                this._macros = macros = this.populateBuiltins();
            }
            return macros;
        };

        Environment.prototype.populateBuiltins = function () {
            return populateBuiltins();
        };

        _createClass$4(Environment, [{
            key: 'transaction',
            get: function () {
                return this._transaction;
            }
        }]);

        return Environment;
    }();

    function _defaults$15(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    var _createClass$5 = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _possibleConstructorReturn$15(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits$15(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults$15(subClass, superClass);
    }

    function _classCallCheck$30(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var UpdatingVM = function () {
        function UpdatingVM(env, _ref) {
            var _ref$alwaysRevalidate = _ref.alwaysRevalidate,
                alwaysRevalidate = _ref$alwaysRevalidate === undefined ? false : _ref$alwaysRevalidate;

            _classCallCheck$30(this, UpdatingVM);

            this.frameStack = new _util.Stack();
            this.env = env;
            this.constants = env.program.constants;
            this.dom = env.getDOM();
            this.alwaysRevalidate = alwaysRevalidate;
        }

        UpdatingVM.prototype.execute = function (opcodes, handler) {
            var frameStack = this.frameStack,
                opcode;

            this.try(opcodes, handler);
            while (true) {
                if (frameStack.isEmpty()) break;
                opcode = this.frame.nextStatement();

                if (opcode === null) {
                    this.frameStack.pop();
                    continue;
                }
                opcode.evaluate(this);
            }
        };

        UpdatingVM.prototype.goto = function (op) {
            this.frame.goto(op);
        };

        UpdatingVM.prototype.try = function (ops, handler) {
            this.frameStack.push(new UpdatingVMFrame(this, ops, handler));
        };

        UpdatingVM.prototype.throw = function () {
            this.frame.handleException();
            this.frameStack.pop();
        };

        UpdatingVM.prototype.evaluateOpcode = function (opcode) {
            opcode.evaluate(this);
        };

        _createClass$5(UpdatingVM, [{
            key: 'frame',
            get: function () {
                return this.frameStack.current;
            }
        }]);

        return UpdatingVM;
    }();

    var BlockOpcode = function (_UpdatingOpcode) {
        _inherits$15(BlockOpcode, _UpdatingOpcode);

        function BlockOpcode(start, state, bounds$$1, children) {
            _classCallCheck$30(this, BlockOpcode);

            var _this = _possibleConstructorReturn$15(this, _UpdatingOpcode.call(this));

            _this.start = start;
            _this.type = "block";
            _this.next = null;
            _this.prev = null;
            var env = state.env,
                scope = state.scope,
                dynamicScope = state.dynamicScope,
                stack = state.stack;

            _this.children = children;
            _this.env = env;
            _this.scope = scope;
            _this.dynamicScope = dynamicScope;
            _this.stack = stack;
            _this.bounds = bounds$$1;
            return _this;
        }

        BlockOpcode.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        BlockOpcode.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        BlockOpcode.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        BlockOpcode.prototype.evaluate = function (vm) {
            vm.try(this.children, null);
        };

        BlockOpcode.prototype.destroy = function () {
            this.bounds.destroy();
        };

        BlockOpcode.prototype.didDestroy = function () {
            this.env.didDestroy(this.bounds);
        };

        BlockOpcode.prototype.toJSON = function () {
            var details = (0, _util.dict)();
            details["guid"] = '' + this._guid;
            return {
                guid: this._guid,
                type: this.type,
                details: details,
                children: this.children.toArray().map(function (op) {
                    return op.toJSON();
                })
            };
        };

        return BlockOpcode;
    }(UpdatingOpcode);
    var TryOpcode = function (_BlockOpcode) {
        _inherits$15(TryOpcode, _BlockOpcode);

        function TryOpcode(start, state, bounds$$1, children) {
            _classCallCheck$30(this, TryOpcode);

            var _this2 = _possibleConstructorReturn$15(this, _BlockOpcode.call(this, start, state, bounds$$1, children));

            _this2.type = "try";
            _this2.tag = _this2._tag = _reference2.UpdatableTag.create(_reference2.CONSTANT_TAG);
            return _this2;
        }

        TryOpcode.prototype.didInitializeChildren = function () {
            this._tag.inner.update((0, _reference2.combineSlice)(this.children));
        };

        TryOpcode.prototype.evaluate = function (vm) {
            vm.try(this.children, this);
        };

        TryOpcode.prototype.handleException = function () {
            var _this3 = this;

            var env = this.env,
                bounds$$1 = this.bounds,
                children = this.children,
                scope = this.scope,
                dynamicScope = this.dynamicScope,
                start = this.start,
                stack = this.stack,
                prev = this.prev,
                next = this.next;

            children.clear();
            var elementStack = ElementStack.resume(env, bounds$$1, bounds$$1.reset(env));
            var vm = new VM(env, scope, dynamicScope, elementStack);
            var updating = new _util.LinkedList();
            vm.execute(start, function (vm) {
                vm.stack = EvaluationStack.restore(stack);
                vm.updatingOpcodeStack.push(updating);
                vm.updateWith(_this3);
                vm.updatingOpcodeStack.push(children);
            });
            this.prev = prev;
            this.next = next;
        };

        TryOpcode.prototype.toJSON = function () {
            var json = _BlockOpcode.prototype.toJSON.call(this);
            var details = json["details"];
            if (!details) {
                details = json["details"] = {};
            }
            return _BlockOpcode.prototype.toJSON.call(this);
        };

        return TryOpcode;
    }(BlockOpcode);

    var ListRevalidationDelegate = function () {
        function ListRevalidationDelegate(opcode, marker) {
            _classCallCheck$30(this, ListRevalidationDelegate);

            this.opcode = opcode;
            this.marker = marker;
            this.didInsert = false;
            this.didDelete = false;
            this.map = opcode.map;
            this.updating = opcode['children'];
        }

        ListRevalidationDelegate.prototype.insert = function (key, item, memo, before) {
            var map$$1 = this.map,
                opcode = this.opcode,
                updating = this.updating;

            var nextSibling = null;
            var reference$$1 = null;
            if (before) {
                reference$$1 = map$$1[before];
                nextSibling = reference$$1['bounds'].firstNode();
            } else {
                nextSibling = this.marker;
            }
            var vm = opcode.vmForInsertion(nextSibling);
            var tryOpcode = null;
            var start = opcode.start;

            vm.execute(start, function (vm) {
                map$$1[key] = tryOpcode = vm.iterate(memo, item);
                vm.updatingOpcodeStack.push(new _util.LinkedList());
                vm.updateWith(tryOpcode);
                vm.updatingOpcodeStack.push(tryOpcode.children);
            });
            updating.insertBefore(tryOpcode, reference$$1);
            this.didInsert = true;
        };

        ListRevalidationDelegate.prototype.retain = function () {};

        ListRevalidationDelegate.prototype.move = function (key, _item, _memo, before) {
            var map$$1 = this.map,
                updating = this.updating;

            var entry = map$$1[key];
            var reference$$1 = map$$1[before] || null;
            if (before) {
                move(entry, reference$$1.firstNode());
            } else {
                move(entry, this.marker);
            }
            updating.remove(entry);
            updating.insertBefore(entry, reference$$1);
        };

        ListRevalidationDelegate.prototype.delete = function (key) {
            var map$$1 = this.map;

            var opcode = map$$1[key];
            opcode.didDestroy();
            clear(opcode);
            this.updating.remove(opcode);
            delete map$$1[key];
            this.didDelete = true;
        };

        ListRevalidationDelegate.prototype.done = function () {
            this.opcode.didInitializeChildren(this.didInsert || this.didDelete);
        };

        return ListRevalidationDelegate;
    }();

    var ListBlockOpcode = function (_BlockOpcode2) {
        _inherits$15(ListBlockOpcode, _BlockOpcode2);

        function ListBlockOpcode(start, state, bounds$$1, children, artifacts) {
            _classCallCheck$30(this, ListBlockOpcode);

            var _this4 = _possibleConstructorReturn$15(this, _BlockOpcode2.call(this, start, state, bounds$$1, children));

            _this4.type = "list-block";
            _this4.map = (0, _util.dict)();
            _this4.lastIterated = _reference2.INITIAL;
            _this4.artifacts = artifacts;
            var _tag = _this4._tag = _reference2.UpdatableTag.create(_reference2.CONSTANT_TAG);
            _this4.tag = (0, _reference2.combine)([artifacts.tag, _tag]);
            return _this4;
        }

        ListBlockOpcode.prototype.didInitializeChildren = function () {
            var listDidChange = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

            this.lastIterated = this.artifacts.tag.value();
            if (listDidChange) {
                this._tag.inner.update((0, _reference2.combineSlice)(this.children));
            }
        };

        ListBlockOpcode.prototype.evaluate = function (vm) {
            var artifacts = this.artifacts,
                lastIterated = this.lastIterated,
                bounds$$1,
                dom,
                marker,
                target,
                synchronizer;

            if (!artifacts.tag.validate(lastIterated)) {
                bounds$$1 = this.bounds;
                dom = vm.dom;
                marker = dom.createComment('');

                dom.insertAfter(bounds$$1.parentElement(), marker, bounds$$1.lastNode());
                target = new ListRevalidationDelegate(this, marker);
                synchronizer = new _reference2.IteratorSynchronizer({ target: target, artifacts: artifacts });

                synchronizer.sync();
                this.parentElement().removeChild(marker);
            }
            // Run now-updated updating opcodes
            _BlockOpcode2.prototype.evaluate.call(this, vm);
        };

        ListBlockOpcode.prototype.vmForInsertion = function (nextSibling) {
            var env = this.env,
                scope = this.scope,
                dynamicScope = this.dynamicScope;

            var elementStack = ElementStack.forInitialRender(this.env, this.bounds.parentElement(), nextSibling);
            return new VM(env, scope, dynamicScope, elementStack);
        };

        ListBlockOpcode.prototype.toJSON = function () {
            var json = _BlockOpcode2.prototype.toJSON.call(this);
            var map$$1 = this.map;
            var inner = Object.keys(map$$1).map(function (key) {
                return JSON.stringify(key) + ': ' + map$$1[key]._guid;
            }).join(", ");
            var details = json["details"];
            if (!details) {
                details = json["details"] = {};
            }
            details["map"] = '{' + inner + '}';
            return json;
        };

        return ListBlockOpcode;
    }(BlockOpcode);

    var UpdatingVMFrame = function () {
        function UpdatingVMFrame(vm, ops, exceptionHandler) {
            _classCallCheck$30(this, UpdatingVMFrame);

            this.vm = vm;
            this.ops = ops;
            this.exceptionHandler = exceptionHandler;
            this.vm = vm;
            this.ops = ops;
            this.current = ops.head();
        }

        UpdatingVMFrame.prototype.goto = function (op) {
            this.current = op;
        };

        UpdatingVMFrame.prototype.nextStatement = function () {
            var current = this.current,
                ops = this.ops;

            if (current) this.current = ops.nextNode(current);
            return current;
        };

        UpdatingVMFrame.prototype.handleException = function () {
            if (this.exceptionHandler) {
                this.exceptionHandler.handleException();
            }
        };

        return UpdatingVMFrame;
    }();

    function _classCallCheck$31(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var RenderResult = function () {
        function RenderResult(env, updating, bounds$$1) {
            _classCallCheck$31(this, RenderResult);

            this.env = env;
            this.updating = updating;
            this.bounds = bounds$$1;
        }

        RenderResult.prototype.rerender = function () {
            var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { alwaysRevalidate: false },
                _ref$alwaysRevalidate = _ref.alwaysRevalidate,
                alwaysRevalidate = _ref$alwaysRevalidate === undefined ? false : _ref$alwaysRevalidate;

            var env = this.env,
                updating = this.updating;

            var vm = new UpdatingVM(env, { alwaysRevalidate: alwaysRevalidate });
            vm.execute(updating, this);
        };

        RenderResult.prototype.parentElement = function () {
            return this.bounds.parentElement();
        };

        RenderResult.prototype.firstNode = function () {
            return this.bounds.firstNode();
        };

        RenderResult.prototype.lastNode = function () {
            return this.bounds.lastNode();
        };

        RenderResult.prototype.opcodes = function () {
            return this.updating;
        };

        RenderResult.prototype.handleException = function () {
            throw "this should never happen";
        };

        RenderResult.prototype.destroy = function () {
            this.bounds.destroy();
            clear(this.bounds);
        };

        return RenderResult;
    }();

    var _createClass$3 = function () {
        function defineProperties(target, props) {
            var i, descriptor;

            for (i = 0; i < props.length; i++) {
                descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
            }
        }return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
        };
    }();

    function _classCallCheck$22(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var EvaluationStack = function () {
        function EvaluationStack(stack, fp, sp) {
            _classCallCheck$22(this, EvaluationStack);

            this.stack = stack;
            this.fp = fp;
            this.sp = sp;
        }

        EvaluationStack.empty = function () {
            return new this([], 0, -1);
        };

        EvaluationStack.restore = function (snapshot) {
            return new this(snapshot.slice(), 0, snapshot.length - 1);
        };

        EvaluationStack.prototype.isEmpty = function () {
            return this.sp === -1;
        };

        EvaluationStack.prototype.push = function (value) {
            this.stack[++this.sp] = value;
        };

        EvaluationStack.prototype.dup = function () {
            var position = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.sp;

            this.push(this.stack[position]);
        };

        EvaluationStack.prototype.pop = function () {
            var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            var top = this.stack[this.sp];
            this.sp -= n;
            return top;
        };

        EvaluationStack.prototype.peek = function () {
            return this.stack[this.sp];
        };

        EvaluationStack.prototype.fromBase = function (offset) {
            return this.stack[this.fp - offset];
        };

        EvaluationStack.prototype.fromTop = function (offset) {
            return this.stack[this.sp - offset];
        };

        EvaluationStack.prototype.capture = function (items) {
            var end = this.sp + 1;

            return this.stack.slice(end - items, end);
        };

        EvaluationStack.prototype.reset = function () {
            this.stack.length = 0;
        };

        EvaluationStack.prototype.toArray = function () {
            return this.stack.slice(this.fp, this.sp + 1);
        };

        return EvaluationStack;
    }();

    var VM = function () {
        function VM(env, scope, dynamicScope, elementStack) {
            _classCallCheck$22(this, VM);

            this.env = env;
            this.elementStack = elementStack;
            this.dynamicScopeStack = new _util.Stack();
            this.scopeStack = new _util.Stack();
            this.updatingOpcodeStack = new _util.Stack();
            this.cacheGroups = new _util.Stack();
            this.listBlockStack = new _util.Stack();
            this.stack = EvaluationStack.empty();
            /* Registers */
            this.pc = -1;
            this.ra = -1;
            this.s0 = null;
            this.s1 = null;
            this.t0 = null;
            this.t1 = null;
            this.env = env;
            this.heap = env.program.heap;
            this.constants = env.program.constants;
            this.elementStack = elementStack;
            this.scopeStack.push(scope);
            this.dynamicScopeStack.push(dynamicScope);
        }

        // Fetch a value from a register onto the stack
        VM.prototype.fetch = function (register) {
            this.stack.push(this[Register[register]]);
        };
        // Load a value from the stack into a register


        VM.prototype.load = function (register) {
            this[Register[register]] = this.stack.pop();
        };
        // Fetch a value from a register


        VM.prototype.fetchValue = function (register) {
            return this[Register[register]];
        };
        // Load a value into a register


        VM.prototype.loadValue = function (register, value) {
            this[Register[register]] = value;
        };
        // Start a new frame and save $ra and $fp on the stack


        VM.prototype.pushFrame = function () {
            this.stack.push(this.ra);
            this.stack.push(this.fp);
            this.fp = this.sp - 1;
        };
        // Restore $ra, $sp and $fp


        VM.prototype.popFrame = function () {
            this.sp = this.fp - 1;
            this.ra = this.stack.fromBase(0);
            this.fp = this.stack.fromBase(-1);
        };
        // Jump to an address in `program`


        VM.prototype.goto = function (offset) {
            this.pc = (0, _util.typePos)(this.pc + offset);
        };
        // Save $pc into $ra, then jump to a new address in `program` (jal in MIPS)


        VM.prototype.call = function (handle) {
            var pc = this.heap.getaddr(handle);
            this.ra = this.pc;
            this.pc = pc;
        };
        // Put a specific `program` address in $ra


        VM.prototype.returnTo = function (offset) {
            this.ra = (0, _util.typePos)(this.pc + offset);
        };
        // Return to the `program` address stored in $ra


        VM.prototype.return = function () {
            this.pc = this.ra;
        };

        VM.initial = function (env, self, dynamicScope, elementStack, program) {
            var scope = Scope.root(self, program.symbolTable.symbols.length);
            var vm = new VM(env, scope, dynamicScope, elementStack);
            vm.pc = vm.heap.getaddr(program.handle);
            vm.updatingOpcodeStack.push(new _util.LinkedList());
            return vm;
        };

        VM.prototype.capture = function (args) {
            return {
                dynamicScope: this.dynamicScope(),
                env: this.env,
                scope: this.scope(),
                stack: this.stack.capture(args)
            };
        };

        VM.prototype.beginCacheGroup = function () {
            this.cacheGroups.push(this.updating().tail());
        };

        VM.prototype.commitCacheGroup = function () {
            //        JumpIfNotModified(END)
            //        (head)
            //        (....)
            //        (tail)
            //        DidModify
            // END:   Noop
            var END = new LabelOpcode("END");
            var opcodes = this.updating();
            var marker = this.cacheGroups.pop();
            var head = marker ? opcodes.nextNode(marker) : opcodes.head();
            var tail = opcodes.tail();
            var tag = (0, _reference2.combineSlice)(new _util.ListSlice(head, tail));
            var guard = new JumpIfNotModifiedOpcode(tag, END);
            opcodes.insertBefore(guard, head);
            opcodes.append(new DidModifyOpcode(guard));
            opcodes.append(END);
        };

        VM.prototype.enter = function (args) {
            var updating = new _util.LinkedList();
            var state = this.capture(args);
            var tracker = this.elements().pushUpdatableBlock();
            var tryOpcode = new TryOpcode(this.heap.gethandle(this.pc), state, tracker, updating);
            this.didEnter(tryOpcode);
        };

        VM.prototype.iterate = function (memo, value) {
            var stack = this.stack;
            stack.push(value);
            stack.push(memo);
            var state = this.capture(2);
            var tracker = this.elements().pushUpdatableBlock();
            // let ip = this.ip;
            // this.ip = end + 4;
            // this.frames.push(ip);
            return new TryOpcode(this.heap.gethandle(this.pc), state, tracker, new _util.LinkedList());
        };

        VM.prototype.enterItem = function (key, opcode) {
            this.listBlock().map[key] = opcode;
            this.didEnter(opcode);
        };

        VM.prototype.enterList = function (relativeStart) {
            var updating = new _util.LinkedList();
            var state = this.capture(0);
            var tracker = this.elements().pushBlockList(updating);
            var artifacts = this.stack.peek().artifacts;
            var start = this.heap.gethandle((0, _util.typePos)(this.pc + relativeStart));
            var opcode = new ListBlockOpcode(start, state, tracker, updating, artifacts);
            this.listBlockStack.push(opcode);
            this.didEnter(opcode);
        };

        VM.prototype.didEnter = function (opcode) {
            this.updateWith(opcode);
            this.updatingOpcodeStack.push(opcode.children);
        };

        VM.prototype.exit = function () {
            this.elements().popBlock();
            this.updatingOpcodeStack.pop();
            var parent = this.updating().tail();
            parent.didInitializeChildren();
        };

        VM.prototype.exitList = function () {
            this.exit();
            this.listBlockStack.pop();
        };

        VM.prototype.updateWith = function (opcode) {
            this.updating().append(opcode);
        };

        VM.prototype.listBlock = function () {
            return this.listBlockStack.current;
        };

        VM.prototype.updating = function () {
            return this.updatingOpcodeStack.current;
        };

        VM.prototype.elements = function () {
            return this.elementStack;
        };

        VM.prototype.scope = function () {
            return this.scopeStack.current;
        };

        VM.prototype.dynamicScope = function () {
            return this.dynamicScopeStack.current;
        };

        VM.prototype.pushChildScope = function () {
            this.scopeStack.push(this.scope().child());
        };

        VM.prototype.pushCallerScope = function () {
            var childScope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var callerScope = this.scope().getCallerScope();
            this.scopeStack.push(childScope ? callerScope.child() : callerScope);
        };

        VM.prototype.pushDynamicScope = function () {
            var child = this.dynamicScope().child();
            this.dynamicScopeStack.push(child);
            return child;
        };

        VM.prototype.pushRootScope = function (size, bindCaller) {
            var scope = Scope.sized(size);
            if (bindCaller) scope.bindCallerScope(this.scope());
            this.scopeStack.push(scope);
            return scope;
        };

        VM.prototype.popScope = function () {
            this.scopeStack.pop();
        };

        VM.prototype.popDynamicScope = function () {
            this.dynamicScopeStack.pop();
        };

        VM.prototype.newDestroyable = function (d) {
            this.elements().newDestroyable(d);
        };
        /// SCOPE HELPERS


        VM.prototype.getSelf = function () {
            return this.scope().getSelf();
        };

        VM.prototype.referenceForSymbol = function (symbol) {
            return this.scope().getSymbol(symbol);
        };
        /// EXECUTION


        VM.prototype.execute = function (start, initialize) {
            this.pc = this.heap.getaddr(start);
            if (initialize) initialize(this);
            var result = void 0;
            while (true) {
                result = this.next();
                if (result.done) break;
            }
            return result.value;
        };

        VM.prototype.next = function () {
            var env = this.env,
                updatingOpcodeStack = this.updatingOpcodeStack,
                elementStack = this.elementStack;

            var opcode = this.nextStatement(env);
            var result = void 0;
            if (opcode !== null) {
                APPEND_OPCODES.evaluate(this, opcode, opcode.type);
                result = { done: false, value: null };
            } else {
                // Unload the stack
                this.stack.reset();
                result = {
                    done: true,
                    value: new RenderResult(env, updatingOpcodeStack.pop(), elementStack.popBlock())
                };
            }
            return result;
        };

        VM.prototype.nextStatement = function (env) {
            var pc = this.pc;

            if (pc === -1) {
                return null;
            }
            var program = env.program;
            this.pc += 4;
            return program.opcode(pc);
        };

        VM.prototype.evaluateOpcode = function (opcode) {
            APPEND_OPCODES.evaluate(this, opcode, opcode.type);
        };

        VM.prototype.bindDynamicScope = function (names) {
            var scope = this.dynamicScope(),
                i,
                name;
            for (i = names.length - 1; i >= 0; i--) {
                name = this.constants.getString(names[i]);

                scope.set(name, this.stack.pop());
            }
        };

        _createClass$3(VM, [{
            key: 'fp',
            get: function () {
                return this.stack.fp;
            },
            set: function (fp) {
                this.stack.fp = fp;
            }
        }, {
            key: 'sp',
            get: function () {
                return this.stack.sp;
            },
            set: function (sp) {
                this.stack.sp = sp;
            }
        }]);

        return VM;
    }();

    function _classCallCheck$14(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var TemplateIterator = function () {
        function TemplateIterator(vm) {
            _classCallCheck$14(this, TemplateIterator);

            this.vm = vm;
        }

        TemplateIterator.prototype.next = function () {
            return this.vm.next();
        };

        return TemplateIterator;
    }();
    var clientId = 0;


    var ScannableTemplate = function () {
        function ScannableTemplate(id, meta, env, rawBlock) {
            _classCallCheck$14(this, ScannableTemplate);

            this.id = id;
            this.meta = meta;
            this.env = env;
            this.entryPoint = null;
            this.layout = null;
            this.partial = null;
            this.block = null;
            this.scanner = new Scanner(rawBlock, env);
            this.symbols = rawBlock.symbols;
            this.hasEval = rawBlock.hasEval;
        }

        ScannableTemplate.prototype.render = function (self, appendTo, dynamicScope) {
            var env = this.env;

            var elementStack = ElementStack.forInitialRender(env, appendTo, null);
            var compiled = this.asEntryPoint().compileDynamic(env);
            var vm = VM.initial(env, self, dynamicScope, elementStack, compiled);
            return new TemplateIterator(vm);
        };

        ScannableTemplate.prototype.asEntryPoint = function () {
            if (!this.entryPoint) this.entryPoint = this.scanner.scanEntryPoint(this.compilationMeta());
            return this.entryPoint;
        };

        ScannableTemplate.prototype.asLayout = function (componentName, attrs) {
            if (!this.layout) this.layout = this.scanner.scanLayout(this.compilationMeta(), attrs || _util.EMPTY_ARRAY, componentName);
            return this.layout;
        };

        ScannableTemplate.prototype.asPartial = function () {
            if (!this.partial) this.partial = this.scanner.scanEntryPoint(this.compilationMeta(true));
            return this.partial;
        };

        ScannableTemplate.prototype.asBlock = function () {
            if (!this.block) this.block = this.scanner.scanBlock(this.compilationMeta());
            return this.block;
        };

        ScannableTemplate.prototype.compilationMeta = function () {
            var asPartial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            return { templateMeta: this.meta, symbols: this.symbols, asPartial: asPartial };
        };

        return ScannableTemplate;
    }();

    function _classCallCheck$32(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var DynamicVarReference = function () {
        function DynamicVarReference(scope, nameRef) {
            _classCallCheck$32(this, DynamicVarReference);

            this.scope = scope;
            this.nameRef = nameRef;
            var varTag = this.varTag = _reference2.UpdatableTag.create(_reference2.CONSTANT_TAG);
            this.tag = (0, _reference2.combine)([nameRef.tag, varTag]);
        }

        DynamicVarReference.prototype.value = function () {
            return this.getVar().value();
        };

        DynamicVarReference.prototype.get = function (key) {
            return this.getVar().get(key);
        };

        DynamicVarReference.prototype.getVar = function () {
            var name = String(this.nameRef.value());
            var ref = this.scope.get(name);
            this.varTag.inner.update(ref.tag);
            return ref;
        };

        return DynamicVarReference;
    }();

    function _classCallCheck$33(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var NodeType;
    (function (NodeType) {
        NodeType[NodeType["Element"] = 0] = "Element";
        NodeType[NodeType["Attribute"] = 1] = "Attribute";
        NodeType[NodeType["Text"] = 2] = "Text";
        NodeType[NodeType["CdataSection"] = 3] = "CdataSection";
        NodeType[NodeType["EntityReference"] = 4] = "EntityReference";
        NodeType[NodeType["Entity"] = 5] = "Entity";
        NodeType[NodeType["ProcessingInstruction"] = 6] = "ProcessingInstruction";
        NodeType[NodeType["Comment"] = 7] = "Comment";
        NodeType[NodeType["Document"] = 8] = "Document";
        NodeType[NodeType["DocumentType"] = 9] = "DocumentType";
        NodeType[NodeType["DocumentFragment"] = 10] = "DocumentFragment";
        NodeType[NodeType["Notation"] = 11] = "Notation";
    })(NodeType || (NodeType = {}));

    var interfaces = Object.freeze({
        get NodeType() {
            return NodeType;
        }
    });

    exports.Simple = interfaces;
    exports.templateFactory = function (_ref) {
        var templateId = _ref.id,
            meta = _ref.meta,
            block = _ref.block;

        var parsedBlock = void 0;
        var id = templateId || 'client-' + clientId++;

        return { id: id, meta: meta, create: function (env, envMeta) {
                var newMeta = envMeta ? (0, _util.assign)({}, envMeta, meta) : meta;
                if (!parsedBlock) {
                    parsedBlock = JSON.parse(block);
                }
                return new ScannableTemplate(id, newMeta, env, parsedBlock);
            } };
    };
    exports.NULL_REFERENCE = NULL_REFERENCE;
    exports.UNDEFINED_REFERENCE = UNDEFINED_REFERENCE;
    exports.PrimitiveReference = PrimitiveReference;
    exports.ConditionalReference = ConditionalReference;
    exports.OpcodeBuilderDSL = OpcodeBuilder;
    exports.compileLayout = function (compilable, env) {
        var builder = new ComponentLayoutBuilder(env);
        compilable.compile(builder);
        return builder.compile();
    };
    exports.CompiledStaticTemplate = CompiledStaticTemplate;
    exports.CompiledDynamicTemplate = CompiledDynamicTemplate;
    exports.IAttributeManager = AttributeManager;
    exports.AttributeManager = AttributeManager;
    exports.PropertyManager = PropertyManager;
    exports.INPUT_VALUE_PROPERTY_MANAGER = INPUT_VALUE_PROPERTY_MANAGER;
    exports.defaultManagers = defaultManagers;
    exports.defaultAttributeManagers = defaultAttributeManagers;
    exports.defaultPropertyManagers = defaultPropertyManagers;
    exports.readDOMAttr = function (element, attr) {
        var isSVG = element.namespaceURI === SVG_NAMESPACE;

        var _normalizeProperty2 = normalizeProperty(element, attr),
            type = _normalizeProperty2.type,
            normalized = _normalizeProperty2.normalized;

        if (isSVG) {
            return element.getAttribute(normalized);
        }
        if (type === 'attr') {
            return element.getAttribute(normalized);
        }
        {
            return element[normalized];
        }
    };
    exports.Register = Register;
    exports.debugSlice = function () {};
    exports.normalizeTextValue = normalizeTextValue;
    exports.setDebuggerCallback = function (cb) {
        callback = cb;
    };
    exports.resetDebuggerCallback = function () {
        callback = debugCallback;
    };
    exports.getDynamicVar = function (vm, args) {
        var scope = vm.dynamicScope();
        var nameRef = args.positional.at(0);
        return new DynamicVarReference(scope, nameRef);
    };
    exports.BlockMacros = Blocks;
    exports.InlineMacros = Inlines;
    exports.compileList = compileList;
    exports.compileExpression = expr;
    exports.UpdatingVM = UpdatingVM;
    exports.RenderResult = RenderResult;
    exports.isSafeString = isSafeString;
    exports.Scope = Scope;
    exports.Environment = Environment;
    exports.PartialDefinition = function PartialDefinition(name, // for debugging
    template) {
        _classCallCheck$33(this, PartialDefinition);

        this.name = name;
        this.template = template;
    };
    exports.ComponentDefinition = function ComponentDefinition(name, manager, ComponentClass) {
        _classCallCheck$10(this, ComponentDefinition);

        this[COMPONENT_DEFINITION_BRAND] = true;
        this.name = name;
        this.manager = manager;
        this.ComponentClass = ComponentClass;
    };
    exports.isComponentDefinition = isComponentDefinition;
    exports.DOMChanges = helper$1;
    exports.IDOMChanges = DOMChanges;
    exports.DOMTreeConstruction = DOMTreeConstruction;
    exports.isWhitespace = function (string) {
        return WHITESPACE.test(string);
    };
    exports.insertHTMLBefore = _insertHTMLBefore;
    exports.ElementStack = ElementStack;
    exports.ConcreteBounds = ConcreteBounds;
});