enifed('ember-glimmer/utils/curly-component-state-bucket', ['exports'], function (exports) {
    'use strict';

    // tslint:disable-next-line:no-empty

    function NOOP() {}
    /**
      @module ember
    */
    /**
      Represents the internal state of the component.
    
      @class ComponentStateBucket
      @private
    */

    var ComponentStateBucket = function () {
        function ComponentStateBucket(environment, component, args, finalizer) {

            this.environment = environment;
            this.component = component;
            this.args = args;
            this.finalizer = finalizer;
            this.classRef = null;
            this.classRef = null;
            this.argsRevision = args.tag.value();
        }

        ComponentStateBucket.prototype.destroy = function () {
            var component = this.component,
                environment = this.environment;

            if (environment.isInteractive) {
                component.trigger('willDestroyElement');
                component.trigger('willClearRender');
            }
            environment.destroyedComponents.push(component);
        };

        ComponentStateBucket.prototype.finalize = function () {
            var finalizer = this.finalizer;

            finalizer();
            this.finalizer = NOOP;
        };

        return ComponentStateBucket;
    }();

    exports.default = ComponentStateBucket;
});