enifed('ember-runtime/mixins/array', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime/mixins/enumerable', 'ember-runtime/compare', 'require'], function (exports, _emberUtils, _emberMetal, _emberDebug, _enumerable, _compare, _require2) {
  'use strict';

  exports.addArrayObserver = addArrayObserver;
  exports.removeArrayObserver = removeArrayObserver;
  exports.objectAt = objectAt;
  exports.arrayContentWillChange = arrayContentWillChange;
  exports.arrayContentDidChange = arrayContentDidChange;
  exports.isEmberArray = function (obj) {
    return obj && obj[EMBER_ARRAY];
  };

  var _Mixin$create;

  // Required to break a module cycle
  var _A = void 0;
  function A() {
    if (_A === undefined) {
      _A = (0, _require2.default)('ember-runtime/system/native_array').A;
    }
    return _A();
  }

  function arrayObserversHelper(obj, target, opts, operation, notify) {
    var willChange = opts && opts.willChange || 'arrayWillChange';
    var didChange = opts && opts.didChange || 'arrayDidChange';
    var hasObservers = (0, _emberMetal.get)(obj, 'hasArrayObservers');

    if (hasObservers === notify) {
      (0, _emberMetal.propertyWillChange)(obj, 'hasArrayObservers');
    }

    operation(obj, '@array:before', target, willChange);
    operation(obj, '@array:change', target, didChange);

    if (hasObservers === notify) {
      (0, _emberMetal.propertyDidChange)(obj, 'hasArrayObservers');
    }

    return obj;
  }

  function addArrayObserver(array, target, opts) {
    return arrayObserversHelper(array, target, opts, _emberMetal.addListener, false);
  }

  function removeArrayObserver(array, target, opts) {
    return arrayObserversHelper(array, target, opts, _emberMetal.removeListener, true);
  }

  function objectAt(content, idx) {
    return typeof content.objectAt === 'function' ? content.objectAt(idx) : content[idx];
  }

  function arrayContentWillChange(array, startIdx, removeAmt, addAmt) {
    // if no args are passed assume everything changes
    if (startIdx === undefined) {
      startIdx = 0;
      removeAmt = addAmt = -1;
    } else {
      if (removeAmt === undefined) {
        removeAmt = -1;
      }

      if (addAmt === undefined) {
        addAmt = -1;
      }
    }

    if (array.__each) {
      array.__each.arrayWillChange(array, startIdx, removeAmt, addAmt);
    }

    (0, _emberMetal.sendEvent)(array, '@array:before', [array, startIdx, removeAmt, addAmt]);

    (0, _emberMetal.propertyWillChange)(array, '[]');

    if (addAmt < 0 || removeAmt < 0 || addAmt - removeAmt !== 0) {
      (0, _emberMetal.propertyWillChange)(array, 'length');
    }

    return array;
  }

  function arrayContentDidChange(array, startIdx, removeAmt, addAmt) {
    // if no args are passed assume everything changes
    if (startIdx === undefined) {
      startIdx = 0;
      removeAmt = addAmt = -1;
    } else {
      if (removeAmt === undefined) {
        removeAmt = -1;
      }

      if (addAmt === undefined) {
        addAmt = -1;
      }
    }

    if (addAmt < 0 || removeAmt < 0 || addAmt - removeAmt !== 0) {
      (0, _emberMetal.propertyDidChange)(array, 'length');
    }

    (0, _emberMetal.propertyDidChange)(array, '[]');

    if (array.__each) {
      array.__each.arrayDidChange(array, startIdx, removeAmt, addAmt);
    }

    (0, _emberMetal.sendEvent)(array, '@array:change', [array, startIdx, removeAmt, addAmt]);

    var meta = (0, _emberMetal.peekMeta)(array),
        length,
        addedAmount,
        removedAmount,
        previousLength,
        normalStartIdx;
    var cache = meta !== undefined ? meta.readableCache() : undefined;
    if (cache !== undefined) {
      length = (0, _emberMetal.get)(array, 'length');
      addedAmount = addAmt === -1 ? 0 : addAmt;
      removedAmount = removeAmt === -1 ? 0 : removeAmt;
      previousLength = length - (addedAmount - removedAmount);
      normalStartIdx = startIdx < 0 ? previousLength + startIdx : startIdx;

      if (cache.firstObject !== undefined && normalStartIdx === 0) {
        (0, _emberMetal.propertyWillChange)(array, 'firstObject', meta);
        (0, _emberMetal.propertyDidChange)(array, 'firstObject', meta);
      }

      if (cache.lastObject !== undefined) {
        if (previousLength - 1 < normalStartIdx + removedAmount) {
          (0, _emberMetal.propertyWillChange)(array, 'lastObject', meta);
          (0, _emberMetal.propertyDidChange)(array, 'lastObject', meta);
        }
      }
    }

    return array;
  }

  var EMBER_ARRAY = (0, _emberUtils.symbol)('EMBER_ARRAY');

  function iter(key, value) {
    var valueProvided = arguments.length === 2;

    return valueProvided ? function (item) {
      return value === (0, _emberMetal.get)(item, key);
    } : function (item) {
      return !!(0, _emberMetal.get)(item, key);
    };
  }

  // ..........................................................
  // ARRAY
  //
  /**
    This mixin implements Observer-friendly Array-like behavior. It is not a
    concrete implementation, but it can be used up by other classes that want
    to appear like arrays.
  
    For example, ArrayProxy is a concrete classes that can
    be instantiated to implement array-like behavior. Both of these classes use
    the Array Mixin by way of the MutableArray mixin, which allows observable
    changes to be made to the underlying array.
  
    This mixin defines methods specifically for collections that provide
    index-ordered access to their contents. When you are designing code that
    needs to accept any kind of Array-like object, you should use these methods
    instead of Array primitives because these will properly notify observers of
    changes to the array.
  
    Although these methods are efficient, they do add a layer of indirection to
    your application so it is a good idea to use them only when you need the
    flexibility of using both true JavaScript arrays and "virtual" arrays such
    as controllers and collections.
  
    You can use the methods defined in this module to access and modify array
    contents in a KVO-friendly way. You can also be notified whenever the
    membership of an array changes by using `.observes('myArray.[]')`.
  
    To support `EmberArray` in your own class, you must override two
    primitives to use it: `length()` and `objectAt()`.
  
    @class EmberArray
    @uses Enumerable
    @since Ember 0.9.0
    @public
  */
  var ArrayMixin = _emberMetal.Mixin.create(_enumerable.default, (_Mixin$create = {}, _Mixin$create[EMBER_ARRAY] = true, _Mixin$create.length = null, _Mixin$create.objectAt = function (idx) {
    if (idx < 0 || idx >= (0, _emberMetal.get)(this, 'length')) {
      return undefined;
    }

    return (0, _emberMetal.get)(this, idx);
  }, _Mixin$create.objectsAt = function (indexes) {
    var _this = this;

    return indexes.map(function (idx) {
      return objectAt(_this, idx);
    });
  }, _Mixin$create['[]'] = (0, _emberMetal.computed)({
    get: function () {
      // eslint-disable-line no-unused-vars
      return this;
    },
    set: function (key, value) {
      this.replace(0, (0, _emberMetal.get)(this, 'length'), value);
      return this;
    }
  }), _Mixin$create.firstObject = (0, _emberMetal.computed)(function () {
    return objectAt(this, 0);
  }).readOnly(), _Mixin$create.lastObject = (0, _emberMetal.computed)(function () {
    return objectAt(this, (0, _emberMetal.get)(this, 'length') - 1);
  }).readOnly(), _Mixin$create.slice = function (beginIndex, endIndex) {
    var ret = A();
    var length = (0, _emberMetal.get)(this, 'length');

    if ((0, _emberMetal.isNone)(beginIndex)) {
      beginIndex = 0;
    } else if (beginIndex < 0) {
      beginIndex = length + beginIndex;
    }

    if ((0, _emberMetal.isNone)(endIndex) || endIndex > length) {
      endIndex = length;
    } else if (endIndex < 0) {
      endIndex = length + endIndex;
    }

    while (beginIndex < endIndex) {
      ret[ret.length] = objectAt(this, beginIndex++);
    }

    return ret;
  }, _Mixin$create.indexOf = function (object, startAt) {
    var len = (0, _emberMetal.get)(this, 'length'),
        idx;

    if (startAt === undefined) {
      startAt = 0;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (idx = startAt; idx < len; idx++) {
      if (objectAt(this, idx) === object) {
        return idx;
      }
    }

    return -1;
  }, _Mixin$create.lastIndexOf = function (object, startAt) {
    var len = (0, _emberMetal.get)(this, 'length'),
        idx;

    if (startAt === undefined || startAt >= len) {
      startAt = len - 1;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (idx = startAt; idx >= 0; idx--) {
      if (objectAt(this, idx) === object) {
        return idx;
      }
    }

    return -1;
  }, _Mixin$create.addArrayObserver = function (target, opts) {
    return addArrayObserver(this, target, opts);
  }, _Mixin$create.removeArrayObserver = function (target, opts) {
    return removeArrayObserver(this, target, opts);
  }, _Mixin$create.hasArrayObservers = (0, _emberMetal.computed)(function () {
    return (0, _emberMetal.hasListeners)(this, '@array:change') || (0, _emberMetal.hasListeners)(this, '@array:before');
  }), _Mixin$create.arrayContentWillChange = function (startIdx, removeAmt, addAmt) {
    return arrayContentWillChange(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.arrayContentDidChange = function (startIdx, removeAmt, addAmt) {
    return arrayContentDidChange(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.forEach = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null,
        index,
        item;
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('forEach expects a function as first argument.', typeof callback === 'function');

    var length = (0, _emberMetal.get)(this, 'length');

    for (index = 0; index < length; index++) {
      item = this.objectAt(index);

      callback.call(target, item, index, this);
    }

    return this;
  }, _Mixin$create.getEach = (0, _emberMetal.aliasMethod)('mapBy'), _Mixin$create.setEach = function (key, value) {
    return this.forEach(function (item) {
      return (0, _emberMetal.set)(item, key, value);
    });
  }, _Mixin$create.map = function (callback, target) {
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('map expects a function as first argument.', typeof callback === 'function');

    var ret = A();

    this.forEach(function (x, idx, i) {
      return ret[idx] = callback.call(target, x, idx, i);
    });

    return ret;
  }, _Mixin$create.mapBy = function (key) {
    return this.map(function (next) {
      return (0, _emberMetal.get)(next, key);
    });
  }, _Mixin$create.filter = function (callback, target) {
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('filter expects a function as first argument.', typeof callback === 'function');

    var ret = A();

    this.forEach(function (x, idx, i) {
      if (callback.call(target, x, idx, i)) {
        ret.push(x);
      }
    });

    return ret;
  }, _Mixin$create.reject = function (callback, target) {
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('reject expects a function as first argument.', typeof callback === 'function');

    return this.filter(function () {
      return !callback.apply(target, arguments);
    });
  }, _Mixin$create.filterBy = function () {
    // eslint-disable-line no-unused-vars
    return this.filter(iter.apply(this, arguments));
  }, _Mixin$create.rejectBy = function (key, value) {
    var use = arguments.length === 2 ? function (item) {
      return (0, _emberMetal.get)(item, key) === value;
    } : function (item) {
      return !!(0, _emberMetal.get)(item, key);
    };

    return this.reject(use);
  }, _Mixin$create.find = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null,
        index,
        item;
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('find expects a function as first argument.', typeof callback === 'function');

    var length = (0, _emberMetal.get)(this, 'length');

    for (index = 0; index < length; index++) {
      item = this.objectAt(index);


      if (callback.call(target, item, index, this)) {
        return item;
      }
    }
  }, _Mixin$create.findBy = function () {
    // eslint-disable-line no-unused-vars
    return this.find(iter.apply(this, arguments));
  }, _Mixin$create.every = function (callback, target) {
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('every expects a function as first argument.', typeof callback === 'function');

    return !this.find(function (x, idx, i) {
      return !callback.call(target, x, idx, i);
    });
  }, _Mixin$create.isEvery = function () {
    // eslint-disable-line no-unused-vars
    return this.every(iter.apply(this, arguments));
  }, _Mixin$create.any = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null,
        index,
        item;
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('any expects a function as first argument.', typeof callback === 'function');

    var length = (0, _emberMetal.get)(this, 'length');

    for (index = 0; index < length; index++) {
      item = this.objectAt(index);


      if (callback.call(target, item, index, this)) {
        return true;
      }
    }

    return false;
  }, _Mixin$create.isAny = function () {
    // eslint-disable-line no-unused-vars
    return this.any(iter.apply(this, arguments));
  }, _Mixin$create.reduce = function (callback, initialValue, reducerProperty) {
    false && !(typeof callback === 'function') && (0, _emberDebug.assert)('reduce expects a function as first argument.', typeof callback === 'function');

    var ret = initialValue;

    this.forEach(function (item, i) {
      ret = callback(ret, item, i, this, reducerProperty);
    }, this);

    return ret;
  }, _Mixin$create.invoke = function (methodName) {
    for (_len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    var ret = A(),
        _len,
        args,
        _key;

    this.forEach(function (x, idx) {
      var method = x && x[methodName];

      if ('function' === typeof method) {
        ret[idx] = args.length ? method.apply(x, args) : x[methodName]();
      }
    }, this);

    return ret;
  }, _Mixin$create.toArray = function () {
    var ret = A();

    this.forEach(function (o, idx) {
      return ret[idx] = o;
    });

    return ret;
  }, _Mixin$create.compact = function () {
    return this.filter(function (value) {
      return value != null;
    });
  }, _Mixin$create.includes = function (obj, startAt) {
    var len = (0, _emberMetal.get)(this, 'length'),
        idx,
        currentObj;

    if (startAt === undefined) {
      startAt = 0;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (idx = startAt; idx < len; idx++) {
      currentObj = objectAt(this, idx);

      // SameValueZero comparison (NaN !== NaN)

      if (obj === currentObj || obj !== obj && currentObj !== currentObj) {
        return true;
      }
    }

    return false;
  }, _Mixin$create.sortBy = function () {
    var sortKeys = arguments;

    return this.toArray().sort(function (a, b) {
      var i, key, propA, propB, compareValue;

      for (i = 0; i < sortKeys.length; i++) {
        key = sortKeys[i];
        propA = (0, _emberMetal.get)(a, key);
        propB = (0, _emberMetal.get)(b, key);
        // return 1 or -1 else continue to the next sortKey

        compareValue = (0, _compare.default)(propA, propB);


        if (compareValue) {
          return compareValue;
        }
      }
      return 0;
    });
  }, _Mixin$create.uniq = function () {
    var ret = A();

    var seen = new Set();
    this.forEach(function (item) {
      if (!seen.has(item)) {
        seen.add(item);
        ret.push(item);
      }
    });

    return ret;
  }, _Mixin$create.uniqBy = function (key) {
    var ret = A();
    var seen = new Set();

    this.forEach(function (item) {
      var val = (0, _emberMetal.get)(item, key);
      if (!seen.has(val)) {
        seen.add(val);
        ret.push(item);
      }
    });

    return ret;
  }, _Mixin$create.without = function (value) {
    if (!this.includes(value)) {
      return this; // nothing to do
    }

    var ret = A();

    this.forEach(function (k) {
      // SameValueZero comparison (NaN !== NaN)
      if (!(k === value || k !== k && value !== value)) {
        ret[ret.length] = k;
      }
    });

    return ret;
  }, _Mixin$create['@each'] = (0, _emberMetal.computed)(function () {
    // TODO use Symbol or add to meta
    if (!this.__each) {
      this.__each = new EachProxy(this);
    }

    return this.__each;
  }).volatile().readOnly(), _Mixin$create));

  /**
    This is the object instance returned when you get the `@each` property on an
    array. It uses the unknownProperty handler to automatically create
    EachArray instances for property names.
    @class EachProxy
    @private
  */
  function EachProxy(content) {
    this._content = content;
    this._keys = undefined;
    (0, _emberMetal.meta)(this);
  }

  EachProxy.prototype = {
    __defineNonEnumerable: function (property) {
      this[property.name] = property.descriptor.value;
    },
    arrayWillChange: function (content, idx, removedCnt) {
      // eslint-disable-line no-unused-vars
      var keys = this._keys;
      var lim = removedCnt > 0 ? idx + removedCnt : -1;
      var meta = (0, _emberMetal.peekMeta)(this);
      for (var key in keys) {
        if (lim > 0) {
          removeObserverForContentKey(content, key, this, idx, lim);
        }
        (0, _emberMetal.propertyWillChange)(this, key, meta);
      }
    },
    arrayDidChange: function (content, idx, removedCnt, addedCnt) {
      var keys = this._keys;
      var lim = addedCnt > 0 ? idx + addedCnt : -1;
      var meta = (0, _emberMetal.peekMeta)(this);
      for (var key in keys) {
        if (lim > 0) {
          addObserverForContentKey(content, key, this, idx, lim);
        }
        (0, _emberMetal.propertyDidChange)(this, key, meta);
      }
    },
    willWatchProperty: function (property) {
      this.beginObservingContentKey(property);
    },
    didUnwatchProperty: function (property) {
      this.stopObservingContentKey(property);
    },
    beginObservingContentKey: function (keyName) {
      var keys = this._keys,
          content,
          len;
      if (!keys) {
        keys = this._keys = Object.create(null);
      }

      if (!keys[keyName]) {
        keys[keyName] = 1;
        content = this._content;
        len = (0, _emberMetal.get)(content, 'length');


        addObserverForContentKey(content, keyName, this, 0, len);
      } else {
        keys[keyName]++;
      }
    },
    stopObservingContentKey: function (keyName) {
      var keys = this._keys,
          content,
          len;
      if (keys && keys[keyName] > 0 && --keys[keyName] <= 0) {
        content = this._content;
        len = (0, _emberMetal.get)(content, 'length');


        removeObserverForContentKey(content, keyName, this, 0, len);
      }
    },
    contentKeyWillChange: function (obj, keyName) {
      (0, _emberMetal.propertyWillChange)(this, keyName);
    },
    contentKeyDidChange: function (obj, keyName) {
      (0, _emberMetal.propertyDidChange)(this, keyName);
    }
  };

  function addObserverForContentKey(content, keyName, proxy, idx, loc) {
    var item;

    while (--loc >= idx) {
      item = objectAt(content, loc);

      if (item) {
        false && !(typeof item === 'object') && (0, _emberDebug.assert)('When using @each to observe the array `' + (0, _emberUtils.toString)(content) + '`, the array must return an object', typeof item === 'object');

        (0, _emberMetal._addBeforeObserver)(item, keyName, proxy, 'contentKeyWillChange');
        (0, _emberMetal.addObserver)(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  function removeObserverForContentKey(content, keyName, proxy, idx, loc) {
    var item;

    while (--loc >= idx) {
      item = objectAt(content, loc);

      if (item) {
        (0, _emberMetal._removeBeforeObserver)(item, keyName, proxy, 'contentKeyWillChange');
        (0, _emberMetal.removeObserver)(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  exports.default = ArrayMixin;
});