enifed('ember-glimmer/component-managers/template-only', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-glimmer/component-managers/abstract'], function (exports, _emberBabel, _runtime, _abstract) {
    'use strict';

    exports.TemplateOnlyComponentDefinition = undefined;

    var TemplateOnlyComponentLayoutCompiler = function () {
        function TemplateOnlyComponentLayoutCompiler(template) {

            this.template = template;
        }

        TemplateOnlyComponentLayoutCompiler.prototype.compile = function (builder) {
            // TODO: use fromLayout
            builder.wrapLayout(this.template);
        };

        return TemplateOnlyComponentLayoutCompiler;
    }();

    TemplateOnlyComponentLayoutCompiler.id = 'template-only';

    var TemplateOnlyComponentManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(TemplateOnlyComponentManager, _AbstractManager);

        function TemplateOnlyComponentManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        TemplateOnlyComponentManager.prototype.create = function () {
            return null;
        };

        TemplateOnlyComponentManager.prototype.layoutFor = function (_ref, _, env) {
            var template = _ref.template;

            return env.getCompiledBlock(TemplateOnlyComponentLayoutCompiler, template);
        };

        TemplateOnlyComponentManager.prototype.getSelf = function () {
            return _runtime.NULL_REFERENCE;
        };

        TemplateOnlyComponentManager.prototype.getDestructor = function () {
            return null;
        };

        return TemplateOnlyComponentManager;
    }(_abstract.default);

    exports.default = TemplateOnlyComponentManager;

    var MANAGER = new TemplateOnlyComponentManager();

    exports.TemplateOnlyComponentDefinition = function (_ComponentDefinition) {
        (0, _emberBabel.inherits)(TemplateOnlyComponentDefinition, _ComponentDefinition);

        function TemplateOnlyComponentDefinition(name, template) {

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, MANAGER, null));

            _this2.template = template;
            return _this2;
        }

        return TemplateOnlyComponentDefinition;
    }(_runtime.ComponentDefinition);
});