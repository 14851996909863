define('ember-calendar/macros/computed-duration', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (dependentKey) {
    return _ember.default.computed(dependentKey, function () {
      var dependentValue = this.get(dependentKey);

      if (dependentValue != null) {
        return _moment.default.duration(dependentValue);
      } else {
        return null;
      }
    });
  };
});