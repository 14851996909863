define('ember-calendar/test-helpers/all', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.selectPreviousWeek = exports.selectNextWeek = exports.selectTimeZone = exports.dragOccurrence = exports.resizeOccurrence = exports.selectTime = exports.dayWidth = exports.timeSlotHeight = undefined;


  var timeSlotHeight = function timeSlotHeight() {
    return _ember.default.$('.as-calendar-timetable-content').find('.as-calendar-timetable__slot-item:first').height();
  };

  var dayWidth = function dayWidth() {
    var $content = _ember.default.$('.as-calendar-timetable-content');
    return $content.width() / $content.find('.as-calendar-timetable__day').length;
  };

  var pointForTime = function pointForTime(options) {
    var $target = _ember.default.$('.as-calendar-timetable-content');
    var offsetX = options.day * dayWidth();
    var offsetY = options.timeSlot * timeSlotHeight();
    var pageX = $target.offset().left + offsetX;
    var pageY = $target.offset().top + offsetY;

    return {
      pageX: pageX,
      pageY: pageY,
      clientX: pageX - _ember.default.$(document).scrollLeft(),
      clientY: pageY - _ember.default.$(document).scrollTop()
    };
  };

  var selectTime = function selectTime(options) {
    _ember.default.run(function () {
      var $target = _ember.default.$('.as-calendar-timetable-content');
      var point = pointForTime(options);
      var event = _ember.default.$.Event('click');

      event.pageX = point.pageX;
      event.pageY = point.pageY;

      $target.trigger(event);
    });
  };

  var resizeOccurrence = function resizeOccurrence(occurrence, options) {
    _ember.default.run(function () {
      occurrence.find('.as-calendar-occurrence__resize-handle').simulate('drag', {
        dx: 0,
        dy: options.timeSlots * timeSlotHeight() + occurrence.height()
      });
    });
  };

  var dragOccurrence = function dragOccurrence(occurrence, options) {
    _ember.default.run(function () {
      occurrence.simulate('drag', {
        dx: options.days * dayWidth(),
        dy: options.timeSlots * timeSlotHeight() + occurrence.height()
      });
    });
  };

  var selectTimeZone = function selectTimeZone(name) {
    _ember.default.run(function () {
      _ember.default.$('.as-calendar-time-zone-select .rl-dropdown-toggle').click();
    });

    _ember.default.run(function () {
      _ember.default.$('.as-calendar-time-zone-option:contains(\'' + name + '\')').click();
    });
  };

  var selectNextWeek = function selectNextWeek() {
    _ember.default.run(function () {
      _ember.default.$('button.as-calendar-header__nav-group-action--next-week').click();
    });
  };

  var selectPreviousWeek = function selectPreviousWeek() {
    _ember.default.run(function () {
      _ember.default.$('button.as-calendar-header__nav-group-action--previous-week').click();
    });
  };

  exports.timeSlotHeight = timeSlotHeight;
  exports.dayWidth = dayWidth;
  exports.selectTime = selectTime;
  exports.resizeOccurrence = resizeOccurrence;
  exports.dragOccurrence = dragOccurrence;
  exports.selectTimeZone = selectTimeZone;
  exports.selectNextWeek = selectNextWeek;
  exports.selectPreviousWeek = selectPreviousWeek;
});