
    (function(){
      var _fontawesome = require('@fortawesome/fontawesome-svg-core');
      var pack;
      
        pack = require('@fortawesome/pro-solid-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);

        pack = require('@fortawesome/pro-light-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);

        pack = require('@fortawesome/pro-regular-svg-icons'); 
        delete pack['default']; 
        _fontawesome.library.add(pack);
    })()
  