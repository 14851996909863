define('ember-social-share/components/share-panel', ['exports', 'ember-social-share/templates/components/share-panel'], function (exports, _sharePanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _sharePanel.default,
    classNames: ['share-panel'],
    buttonToComponent: { 'fb': 'fb-share-button',
      'facebook': 'fb-share-button',
      'vk': 'vk-share-button',
      'vkontakte': 'vk-share-button',
      'twitter': 'twitter-share-button',
      'linkedin': 'linkedin-share-button',
      'gplus': 'gplus-share-button',
      'email': 'email-share-button',
      'e-mail': 'email-share-button',
      'reddit': 'reddit-share-button'
    },
    buttons: '',
    labels: '',
    adaptive: true,

    components: Ember.computed('buttons', function () {
      var _this = this;

      var buttons = this.splitData(this.get('buttons'));
      var labels = this.splitData(this.get('labels'));
      return buttons.map(function (item, index) {
        return { name: _this.get('buttonToComponent')[item], label: labels[index] };
      });
    }),

    splitData: function splitData(data) {
      return data.split(',').map(function (item) {
        return item.trim();
      });
    }
  });
});