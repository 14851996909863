enifed('ember-glimmer/component-managers/render', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-debug', 'ember-routing', 'ember-glimmer/utils/references', 'ember-glimmer/component-managers/abstract', 'ember-glimmer/component-managers/outlet'], function (exports, _emberBabel, _runtime, _emberDebug, _emberRouting, _references, _abstract, _outlet) {
    'use strict';

    exports.RenderDefinition = exports.NON_SINGLETON_RENDER_MANAGER = exports.SINGLETON_RENDER_MANAGER = exports.AbstractRenderManager = undefined;

    var AbstractRenderManager = exports.AbstractRenderManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(AbstractRenderManager, _AbstractManager);

        function AbstractRenderManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        AbstractRenderManager.prototype.layoutFor = function (definition, _bucket, env) {
            // only curly components can have lazy layout
            false && !!!definition.template && (0, _emberDebug.assert)('definition is missing a template', !!definition.template);

            return env.getCompiledBlock(_outlet.OutletLayoutCompiler, definition.template);
        };

        AbstractRenderManager.prototype.getSelf = function (_ref) {
            var controller = _ref.controller;

            return new _references.RootReference(controller);
        };

        return AbstractRenderManager;
    }(_abstract.default);

    var SingletonRenderManager = function (_AbstractRenderManage) {
        (0, _emberBabel.inherits)(SingletonRenderManager, _AbstractRenderManage);

        function SingletonRenderManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage.apply(this, arguments));
        }

        SingletonRenderManager.prototype.create = function (env, definition, _args, dynamicScope) {
            var name = definition.name;

            var controller = env.owner.lookup('controller:' + name) || (0, _emberRouting.generateController)(env.owner, name);

            if (dynamicScope.rootOutletState) {
                dynamicScope.outletState = dynamicScope.rootOutletState.getOrphan(name);
            }
            return { controller: controller };
        };

        SingletonRenderManager.prototype.getDestructor = function () {
            return null;
        };

        return SingletonRenderManager;
    }(AbstractRenderManager);

    exports.SINGLETON_RENDER_MANAGER = new SingletonRenderManager();


    var NonSingletonRenderManager = function (_AbstractRenderManage2) {
        (0, _emberBabel.inherits)(NonSingletonRenderManager, _AbstractRenderManage2);

        function NonSingletonRenderManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractRenderManage2.apply(this, arguments));
        }

        NonSingletonRenderManager.prototype.create = function (environment, definition, args, dynamicScope) {
            var name = definition.name,
                env = definition.env;

            var modelRef = args.positional.at(0);
            var controllerFactory = env.owner.factoryFor('controller:' + name);
            var factory = controllerFactory || (0, _emberRouting.generateControllerFactory)(env.owner, name);
            var controller = factory.create({ model: modelRef.value() });

            if (dynamicScope.rootOutletState) {
                dynamicScope.outletState = dynamicScope.rootOutletState.getOrphan(name);
            }
            return { controller: controller, model: modelRef };
        };

        NonSingletonRenderManager.prototype.update = function (_ref2) {
            var controller = _ref2.controller,
                model = _ref2.model;

            controller.set('model', model.value());
        };

        NonSingletonRenderManager.prototype.getDestructor = function (_ref3) {
            var controller = _ref3.controller;

            return controller;
        };

        return NonSingletonRenderManager;
    }(AbstractRenderManager);

    exports.NON_SINGLETON_RENDER_MANAGER = new NonSingletonRenderManager();

    exports.RenderDefinition = function (_ComponentDefinition) {
        (0, _emberBabel.inherits)(RenderDefinition, _ComponentDefinition);

        function RenderDefinition(name, template, env, manager) {

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, 'render', manager, null));

            _this4.name = name;
            _this4.template = template;
            _this4.env = env;
            return _this4;
        }

        return RenderDefinition;
    }(_runtime.ComponentDefinition);
});