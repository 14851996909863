enifed('ember-runtime/mixins/copyable', ['exports', 'ember-metal'], function (exports, _emberMetal) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    /**
      __Required.__ You must implement this method to apply this mixin.
       Override to return a copy of the receiver. Default implementation raises
      an exception.
       @method copy
      @param {Boolean} deep if `true`, a deep copy of the object should be made
      @return {Object} copy of receiver
      @private
    */
    copy: null
  });
});