enifed('ember-debug/warn', ['exports', 'ember-environment', 'ember-console', 'ember-debug/deprecate', 'ember-debug/index', 'ember-debug/handlers'], function (exports) {
  'use strict';

  exports.missingOptionsDeprecation = exports.missingOptionsIdDeprecation = exports.registerHandler = undefined;

  /**
  @module @ember/debug
  */

  exports.default = function () {};
  exports.registerHandler = function () {};
  exports.missingOptionsIdDeprecation = void 0;
  exports.missingOptionsDeprecation = void 0;
});