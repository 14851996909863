define('ember-calendar/models/occurrence-proxy', ['exports', 'ember', 'moment', 'ember-calendar/macros/computed-moment', 'ember-calendar/models/day'], function (exports, _ember, _moment, _computedMoment, _day) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OccurrenceProxy = _ember.default.Object.extend(_ember.default.Copyable, {
    calendar: null,
    content: null,
    endingTime: (0, _computedMoment.default)('content.endsAt'),
    startingTime: (0, _computedMoment.default)('content.startsAt'),
    title: _ember.default.computed.oneWay('content.title'),

    duration: _ember.default.computed('startingTime', 'endingTime', function () {
      return _moment.default.duration(this.get('endingTime').diff(this.get('startingTime')));
    }),

    day: _ember.default.computed('startingTime', 'calendar', 'calendar.{startingTime,startFromDate}', function () {
      var currentDay = this.get('startingTime');
      var firstDay = void 0;

      if (this.get('calendar.startFromDate')) {
        firstDay = this.get('calendar.startingTime');
      } else {
        firstDay = this.get('calendar.startingTime').startOf('isoWeek');
      }

      return _day.default.create({
        calendar: this.get('calendar'),
        offset: currentDay.dayOfYear() - firstDay.dayOfYear()
      });
    }),

    copy: function copy() {
      return OccurrenceProxy.create({
        calendar: this.get('calendar'),

        content: _ember.default.Object.create({
          startsAt: this.get('content.startsAt'),
          endsAt: this.get('content.endsAt'),
          title: this.get('content.title')
        })
      });
    }
  });

  exports.default = OccurrenceProxy;
});