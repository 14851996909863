define("ember-cli-uuid/utils/uuid-helpers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.uuid = uuid;
  function uuid() {
    return window.uuid.v4();
  }

  exports.default = uuid;
});