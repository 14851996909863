define('ember-sanitize/mixins/sanitize', ['exports', 'ember', 'ember-sanitize/utils/sanitize', 'ember-getowner-polyfill'], function (exports, _ember, _emberSanitizeUtilsSanitize, _emberGetownerPolyfill) {

  function loadConfig(container, name) {
    if (!name) {
      return;
    }
    return container.lookup("sanitizer:" + name);
  }

  exports['default'] = _ember['default'].Mixin.create({
    sanitizeElement: function sanitizeElement(selector, configName) {
      var element = this.$(selector)[0];
      return (0, _emberSanitizeUtilsSanitize.sanitizeElement)(element, loadConfig(this.container, configName));
    },

    sanitizeHTML: function sanitizeHTML(html, configName) {
      return (0, _emberSanitizeUtilsSanitize.sanitize)(html, loadConfig((0, _emberGetownerPolyfill['default'])(this), configName));
    }
  });
});