define('ember-highcharts/utils/chart-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getSeriesMap = getSeriesMap;
  exports.getSeriesChanges = getSeriesChanges;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function getSeriesMap(seriesGroup) {
    var seriesMap = seriesGroup.reduce(function (seriesMap, seriesItem) {
      seriesMap[seriesItem.name] = seriesItem;
      return seriesMap;
    }, {});

    return seriesMap;
  }

  function getSeriesChanges(contentSeries, series) {
    var updatedKeys = Object.keys(contentSeries).filter(function (key) {
      var isValidKey = key !== 'data' && key.charAt(0) !== '_';
      var isValidType = ['object', 'function'].indexOf(_typeof(contentSeries[key])) === -1;
      var isTheSame = contentSeries[key] === series[key];

      return isValidKey && isValidType && !isTheSame;
    });

    // returns a list of updated keys
    return updatedKeys;
  }
});