enifed("ember-glimmer/template_registry", ["exports"], function (exports) {
    "use strict";

    exports.setTemplates = function (templates) {
        TEMPLATES = templates;
    };
    exports.getTemplates = function () {
        return TEMPLATES;
    };
    exports.getTemplate = function (name) {
        if (TEMPLATES.hasOwnProperty(name)) {
            return TEMPLATES[name];
        }
    };
    exports.hasTemplate = function (name) {
        return TEMPLATES.hasOwnProperty(name);
    };
    exports.setTemplate = function (name, template) {
        return TEMPLATES[name] = template;
    };
    var TEMPLATES = {};
});