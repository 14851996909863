define('ember-calendar/models/day', ['exports', 'lodash', 'moment', 'ember'], function (exports, _lodash, _moment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Day = _ember.default.Object.extend({
    calendar: null,
    offset: 0,

    value: _ember.default.computed('_week', 'offset', function () {
      return (0, _moment.default)(this.get('_week')).add(this.get('offset'), 'day');
    }),

    occurrences: _ember.default.computed('calendar.occurrences.@each.startingTime', 'startingTime', 'endingTime', function () {
      var _this = this;

      return this.get('calendar.occurrences').filter(function (occurrence) {
        var startingTime = occurrence.get('startingTime');

        return startingTime.isSameOrAfter(_this.get('startingTime')) && startingTime.isSameOrBefore(_this.get('endingTime'));
      });
    }),

    occurrencePreview: _ember.default.computed('calendar.occurrencePreview.startingTime', 'startingTime', 'endingTime', function () {
      var occurrencePreview = this.get('calendar.occurrencePreview');

      if (occurrencePreview != null) {
        var startingTime = occurrencePreview.get('startingTime');

        if (startingTime >= this.get('startingTime') && startingTime <= this.get('endingTime')) {
          return occurrencePreview;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),

    startingTime: _ember.default.computed('value', '_timeSlots.firstObject.time', function () {
      return (0, _moment.default)(this.get('value')).add(this.get('_timeSlots.firstObject.time'));
    }),

    endingTime: _ember.default.computed('value', '_timeSlots.lastObject.endingTime', function () {
      return (0, _moment.default)(this.get('value')).add(this.get('_timeSlots.lastObject.endingTime'));
    }),

    isToday: _ember.default.computed('value', function () {
      return this.get('value').isSame((0, _moment.default)(), 'day');
    }),

    _week: _ember.default.computed.oneWay('calendar.week'),
    _timeSlots: _ember.default.computed.oneWay('calendar.timeSlots')
  });

  Day.reopenClass({
    buildWeek: function buildWeek(options) {
      return _ember.default.A(_lodash.default.range(0, 7).map(function (dayOffset) {
        return Day.create({
          calendar: options.calendar,
          offset: dayOffset
        });
      }));
    }
  });

  exports.default = Day;
});