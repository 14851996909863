define("ember-composable-helpers/helpers/shuffle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shuffle = shuffle;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function shuffle(array, randomizer) {
    array = array.slice(0);
    var count = Ember.get(array, 'length');
    var rand, temp;
    randomizer = Ember.typeOf(randomizer) === 'function' && randomizer || Math.random;

    while (count > 1) {
      rand = Math.floor(randomizer() * count--);
      temp = array[count];
      array[count] = array[rand];
      array[rand] = temp;
    }

    return array;
  }

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          random = _ref2[0],
          array = _ref2[1];

      if (array === undefined) {
        array = random;
        random = undefined;
      }

      if (!Ember.isArray(array)) {
        return Ember.A([array]);
      }

      Ember.set(this, 'array', array);
      return shuffle(array, random);
    },
    arrayContentDidChange: Ember.observer('array.[]', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});