enifed('ember-glimmer/helpers/component', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-debug', 'ember-utils', 'ember-glimmer/component-managers/curly', 'ember-glimmer/utils/references'], function (exports, _emberBabel, _runtime, _emberDebug, _emberUtils, _curly, _references) {
  'use strict';

  exports.ClosureComponentReference = undefined;

  exports.default = function (vm, args, meta) {
    return ClosureComponentReference.create(args.capture(), meta, vm.env);
  };

  var ClosureComponentReference = exports.ClosureComponentReference = function (_CachedReference) {
    (0, _emberBabel.inherits)(ClosureComponentReference, _CachedReference);

    ClosureComponentReference.create = function (args, meta, env) {
      return new ClosureComponentReference(args, meta, env);
    };

    function ClosureComponentReference(args, meta, env) {

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

      var firstArg = args.positional.at(0);
      _this.defRef = firstArg;
      _this.tag = firstArg.tag;
      _this.args = args;
      _this.meta = meta;
      _this.env = env;
      _this.lastDefinition = undefined;
      _this.lastName = undefined;
      return _this;
    }

    ClosureComponentReference.prototype.compute = function () {
      // TODO: Figure out how to extract this because it's nearly identical to
      // DynamicComponentReference::compute(). The only differences besides
      // currying are in the assertion messages.
      var args = this.args,
          defRef = this.defRef,
          env = this.env,
          meta = this.meta,
          lastDefinition = this.lastDefinition,
          lastName = this.lastName;

      var nameOrDef = defRef.value();
      var definition = void 0;
      if (nameOrDef && nameOrDef === lastName) {
        return lastDefinition;
      }
      this.lastName = nameOrDef;
      if (typeof nameOrDef === 'string') {
        // tslint:disable-next-line:max-line-length
        false && !(nameOrDef !== 'input') && (0, _emberDebug.assert)('You cannot use the input helper as a contextual helper. Please extend TextField or Checkbox to use it as a contextual component.', nameOrDef !== 'input');
        // tslint:disable-next-line:max-line-length

        false && !(nameOrDef !== 'textarea') && (0, _emberDebug.assert)('You cannot use the textarea helper as a contextual helper. Please extend TextArea to use it as a contextual component.', nameOrDef !== 'textarea');

        definition = env.getComponentDefinition(nameOrDef, meta);
        // tslint:disable-next-line:max-line-length
        false && !!!definition && (0, _emberDebug.assert)('The component helper cannot be used without a valid component name. You used "' + nameOrDef + '" via (component "' + nameOrDef + '")', !!definition);
      } else if ((0, _runtime.isComponentDefinition)(nameOrDef)) {
        definition = nameOrDef;
      } else {
        false && !nameOrDef && (0, _emberDebug.assert)('You cannot create a component from ' + nameOrDef + ' using the {{component}} helper', nameOrDef);

        return null;
      }
      var newDef = createCurriedDefinition(definition, args);
      this.lastDefinition = newDef;
      return newDef;
    };

    return ClosureComponentReference;
  }(_references.CachedReference);

  function createCurriedDefinition(definition, args) {
    var curriedArgs = curryArgs(definition, args);
    return new _curly.CurlyComponentDefinition(definition.name, definition.ComponentClass, definition.template, curriedArgs);
  }
  function curryArgs(definition, newArgs) {
    var args = definition.args,
        ComponentClass = definition.ComponentClass,
        limit,
        i,
        name;

    var positionalParams = ComponentClass.class.positionalParams;
    // The args being passed in are from the (component ...) invocation,
    // so the first positional argument is actually the name or component
    // definition. It needs to be dropped in order for any actual positional
    // args to coincide with the ComponentClass's positionParams.
    // For "normal" curly components this slicing is done at the syntax layer,
    // but we don't have that luxury here.

    var _newArgs$positional$r = newArgs.positional.references,
        slicedPositionalArgs = _newArgs$positional$r.slice(1);

    if (positionalParams && slicedPositionalArgs.length) {
      (0, _curly.validatePositionalParameters)(newArgs.named, slicedPositionalArgs, positionalParams);
    }

    // For non-rest position params, we need to perform the position -> name mapping
    // at each layer to avoid a collision later when the args are used to construct
    // the component instance (inside of processArgs(), inside of create()).
    var positionalToNamedParams = {};
    if (!(typeof positionalParams === 'string') && positionalParams.length > 0) {
      limit = Math.min(positionalParams.length, slicedPositionalArgs.length);

      for (i = 0; i < limit; i++) {
        name = positionalParams[i];

        positionalToNamedParams[name] = slicedPositionalArgs[i];
      }
      slicedPositionalArgs.length = 0; // Throw them away since you're merged in.
    }
    // args (aka 'oldArgs') may be undefined or simply be empty args, so
    // we need to fall back to an empty array or object.
    var oldNamed = args && args.named || {};
    var oldPositional = args && args.positional || [];
    // Merge positional arrays
    var positional = new Array(Math.max(oldPositional.length, slicedPositionalArgs.length));
    positional.splice.apply(positional, [0, oldPositional.length].concat(oldPositional));
    positional.splice.apply(positional, [0, slicedPositionalArgs.length].concat(slicedPositionalArgs));
    // Merge named maps
    var named = (0, _emberUtils.assign)({}, oldNamed, positionalToNamedParams, newArgs.named.map);
    return { positional: positional, named: named };
  }
});