define("ember-cli-string-helpers/helpers/truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function truncate(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        string = _ref2[0],
        _ref2$ = _ref2[1],
        characterLimit = _ref2$ === void 0 ? 140 : _ref2$,
        _ref2$2 = _ref2[2],
        useEllipsis = _ref2$2 === void 0 ? true : _ref2$2;

    var limit = useEllipsis ? characterLimit - 3 : characterLimit;

    if (Ember.String.isHTMLSafe(string)) {
      string = string.string;
    }

    if (string && string.length > limit) {
      return useEllipsis ? "".concat(string.substring(0, limit), "...") : string.substring(0, limit);
    } else {
      return string;
    }
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});