define('ember-social-share/components/email-share-button', ['exports', 'ember-social-share/components/share-button', 'ember-social-share/templates/components/email-share-button'], function (exports, _shareButton, _emailShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _emailShareButton.default,
    classNames: ['email-share-button', 'share-button'],
    recipient: ''
  });
});