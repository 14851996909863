define("ember-cli-string-helpers/helpers/w", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.w = w;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function w(_ref) {
    var _ref2 = _toArray(_ref),
        wordStrings = _ref2.slice(0);

    return wordStrings.map(Ember.String.w).reduce(function (words, moreWords) {
      return words.concat(moreWords);
    }, []);
  }

  var _default = Ember.Helper.helper(w);

  _exports.default = _default;
});