define('ember-click-outside/mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var bound = function bound(fnName) {
    return Ember.computed(fnName, function () {
      var fn = Ember.get(this, fnName);
      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }
    });
  };

  var ios = function ios() {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    );
  };

  var documentOrBodyContains = function documentOrBodyContains(element) {
    // https://github.com/zeppelin/ember-click-outside/issues/30
    if (typeof document.contains === 'function') {
      return document.contains(element);
    } else {
      return document.body.contains(element);
    }
  };

  exports.default = Ember.Mixin.create({
    clickOutside: function clickOutside() {},

    clickHandler: bound('outsideClickHandler'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!ios()) {
        return;
      }

      document.body.style.cursor = 'pointer';
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!ios()) {
        return;
      }

      document.body.style.cursor = '';
    },
    outsideClickHandler: function outsideClickHandler(e) {
      var element = Ember.get(this, 'element');
      var path = e.path || e.composedPath && e.composedPath();

      if (path) {
        path.includes(element) || this.clickOutside(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        var isRemoved = !e.target || !documentOrBodyContains(e.target);

        // Check the element is found as a parent of the click target.
        var isInside = element === e.target || element.contains(e.target);

        if (!isRemoved && !isInside) {
          this.clickOutside(e);
        }
      }
    },
    addClickOutsideListener: function addClickOutsideListener() {
      var clickHandler = Ember.get(this, 'clickHandler');
      document.addEventListener('click', clickHandler);
    },
    removeClickOutsideListener: function removeClickOutsideListener() {
      var clickHandler = Ember.get(this, 'clickHandler');
      document.removeEventListener('click', clickHandler);
    }
  });
});