define('ember-click-outside/mixins/click-outside', ['exports', 'ember-click-outside/mixin', 'ember-click-outside/utils'], function (exports, _mixin, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  (0, _utils.printConsoleMessage)('Importing \'ember-click-outside/mixins/click-outside\' is deprecated. Please import from \'ember-click-outside/mixin\' instead.');

  exports.default = _mixin.default;
});