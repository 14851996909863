enifed('ember-glimmer/component-managers/abstract', ['exports'], function (exports) {
    'use strict';

    var AbstractManager = function () {
        function AbstractManager() {

            this.debugStack = undefined;
        }

        AbstractManager.prototype.prepareArgs = function () {
            return null;
        };

        AbstractManager.prototype.didCreateElement = function () {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didRenderLayout = function () {
            // noop
        };

        AbstractManager.prototype.didCreate = function () {
            // noop
        };

        AbstractManager.prototype.getTag = function () {
            return null;
        };

        AbstractManager.prototype.update = function () {}
        // noop

        // inheritors should also call `this.debugStack.pop()` to
        // ensure the rerendering assertion messages are properly
        // maintained
        ;

        AbstractManager.prototype.didUpdateLayout = function () {
            // noop
        };

        AbstractManager.prototype.didUpdate = function () {
            // noop
        };

        return AbstractManager;
    }();

    exports.default = AbstractManager;
});