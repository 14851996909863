define('ember-localforage-adapter/serializers/localforage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    shouldSerializeHasMany: function shouldSerializeHasMany(snapshot, key, relationship) {
      var relationshipType = snapshot.type.determineRelationshipType(relationship, this.store);

      if (this._mustSerialize(key)) {
        return true;
      }

      return this._canSerialize(key) && (relationshipType === 'manyToNone' || relationshipType === 'manyToMany' || relationshipType === 'manyToOne');
    }
  });
});