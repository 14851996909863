enifed('ember-glimmer/syntax/mount', ['exports', 'ember-debug', 'ember-glimmer/component-managers/mount', 'ember-glimmer/syntax/utils'], function (exports, _emberDebug, _mount, _utils) {
    'use strict';

    exports.mountMacro =
    /**
      The `{{mount}}` helper lets you embed a routeless engine in a template.
      Mounting an engine will cause an instance to be booted and its `application`
      template to be rendered.
    
      For example, the following template mounts the `ember-chat` engine:
    
      ```handlebars
      {{! application.hbs }}
      {{mount "ember-chat"}}
      ```
    
      Additionally, you can also pass in a `model` argument that will be
      set as the engines model. This can be an existing object:
    
      ```
      <div>
        {{mount 'admin' model=userSettings}}
      </div>
      ```
    
      Or an inline `hash`, and you can even pass components:
    
      ```
      <div>
        <h1>Application template!</h1>
        {{mount 'admin' model=(hash
            title='Secret Admin'
            signInButton=(component 'sign-in-button')
        )}}
      </div>
      ```
    
      @method mount
      @param {String} name Name of the engine to mount.
      @param {Object} [model] Object that will be set as
                              the model of the engine.
      @for Ember.Templates.helpers
      @category ember-application-engines
      @public
    */
    function (_name, params, hash, builder) {
        false && !(params.length === 1) && (0, _emberDebug.assert)('You can only pass a single positional argument to the {{mount}} helper, e.g. {{mount "chat-engine"}}.', params.length === 1);

        var definitionArgs = [params.slice(0, 1), null, null, null];
        var args = [null, (0, _utils.hashToArgs)(hash), null, null];
        builder.component.dynamic(definitionArgs, dynamicEngineFor, args);
        return true;
    };

    function dynamicEngineFor(vm, args, meta) {
        var env = vm.env;
        var nameRef = args.positional.at(0);
        return new DynamicEngineReference({ nameRef: nameRef, env: env, meta: meta });
    }

    var DynamicEngineReference = function () {
        function DynamicEngineReference(_ref) {
            var nameRef = _ref.nameRef,
                env = _ref.env,
                meta = _ref.meta;


            this.tag = nameRef.tag;
            this.nameRef = nameRef;
            this.env = env;
            this.meta = meta;
            this._lastName = undefined;
            this._lastDef = undefined;
        }

        DynamicEngineReference.prototype.value = function () {
            var env = this.env,
                nameRef = this.nameRef;

            var nameOrDef = nameRef.value();
            if (typeof nameOrDef === 'string') {
                if (this._lastName === nameOrDef) {
                    return this._lastDef;
                }
                false && !env.owner.hasRegistration('engine:' + nameOrDef) && (0, _emberDebug.assert)('You used `{{mount \'' + nameOrDef + '\'}}`, but the engine \'' + nameOrDef + '\' can not be found.', env.owner.hasRegistration('engine:' + nameOrDef));

                if (!env.owner.hasRegistration('engine:' + nameOrDef)) {
                    return null;
                }
                this._lastName = nameOrDef;
                this._lastDef = new _mount.MountDefinition(nameOrDef);
                return this._lastDef;
            } else {
                false && !(nameOrDef === null || nameOrDef === undefined) && (0, _emberDebug.assert)('Invalid engine name \'' + nameOrDef + '\' specified, engine name must be either a string, null or undefined.', nameOrDef === null || nameOrDef === undefined);

                return null;
            }
        };

        return DynamicEngineReference;
    }();
});