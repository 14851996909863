define("ember-uuid/index", ["exports", "ember-uuid/utils/uuid-generator"], function (exports, _uuidGenerator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "v1", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.v1;
    }
  });
  Object.defineProperty(exports, "v4", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.v4;
    }
  });
  Object.defineProperty(exports, "parse", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.parse;
    }
  });
  Object.defineProperty(exports, "unparse", {
    enumerable: true,
    get: function () {
      return _uuidGenerator.unparse;
    }
  });
});