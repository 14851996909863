define('ember-social-share/components/fb-share-button', ['exports', 'ember-social-share/components/share-button', 'ember-social-share/templates/components/fb-share-button'], function (exports, _shareButton, _fbShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    layout: _fbShareButton.default,
    shareURL: 'https://facebook.com/sharer.php',
    classNames: ['fb-share-button', 'share-button'],

    hashtag: Ember.computed('hashtags', function () {
      if (this.get('hashtags')) {
        var firstTag = this.get('hashtags').split(',').shift();
        return encodeURIComponent('#' + firstTag);
      }
    }),

    buildUrl: function buildUrl() {
      var quote = this.get('quote') ? '&quote=' + this.get('quote') : '';
      var hashtag = this.get('hashtag') ? '&hashtag=' + this.get('hashtag') : '';
      var currentUrl = encodeURIComponent(this.getCurrentUrl());
      var url = this.get('shareURL') + '?display=popup&u=' + currentUrl + hashtag + quote;
      return url;
    },
    click: function click() {
      var url = this.buildUrl();
      this.openSharePopup(url);
    }
  });
});