define("ember-page-title/helpers/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function updateTitle(tokens) {
    Ember.set(this, 'title', tokens.toString());
  }
  /**
    `{{page-title}}` is used to communicate with
  
    @public
    @method page-title
   */


  var _default = Ember.Helper.extend({
    pageTitleList: Ember.inject.service(),
    headData: Ember.inject.service(),
    init: function init() {
      this._super();

      var tokens = Ember.get(this, 'pageTitleList');
      tokens.push({
        id: Ember.guidFor(this)
      });
    },
    compute: function compute(params, _hash) {
      var tokens = Ember.get(this, 'pageTitleList');
      var hash = Ember.assign({}, _hash);
      hash.id = Ember.guidFor(this);
      hash.title = params.join('');
      tokens.push(hash);
      Ember.run.scheduleOnce('afterRender', Ember.get(this, 'headData'), updateTitle, tokens);
      return '';
    },
    destroy: function destroy() {
      var tokens = Ember.get(this, 'pageTitleList');
      var id = Ember.guidFor(this);
      tokens.remove(id);
      var router = Ember.getOwner(this).lookup('router:main');
      var routes = router._routerMicrolib || router.router;

      var _ref = routes || {},
          activeTransition = _ref.activeTransition;

      var headData = Ember.get(this, 'headData');

      if (activeTransition) {
        activeTransition.promise.finally(function () {
          if (headData.isDestroyed) {
            return;
          }

          Ember.run.scheduleOnce('afterRender', headData, updateTitle, tokens);
        });
      } else {
        Ember.run.scheduleOnce('afterRender', headData, updateTitle, tokens);
      }
    }
  });

  _exports.default = _default;
});