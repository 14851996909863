define("ember-quill/components/quill-editor", ["exports", "quill", "ember-quill/templates/components/quill-editor"], function (exports, _quill, _quillEditor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _quillEditor.default,
    editor: null,

    textChange: function textChange() {},
    selectionChange: function selectionChange() {},


    options: Ember.computed(function () {
      return { theme: "snow" };
    }),

    safeValue: Ember.computed("value", function () {
      return Ember.String.htmlSafe(this.get("value"));
    }),

    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup("service:fastboot");
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      // Don't instantiate Quill if fastboot is detected
      if (this.get("fastboot.isFastBoot")) {
        return;
      }

      var editor = new _quill.default(this.element, this.get("options"));

      editor.on("text-change", function (delta, oldDelta, source) {
        _this.get("textChange")(_this.get("editor"), delta, oldDelta, source);
      });

      editor.on("selection-change", function (delta, oldDelta, source) {
        _this.get("selectionChange")(_this.get("editor"), delta, oldDelta, source);
      });

      this.set("editor", editor);
    }
  });
});