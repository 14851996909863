define('ember-sanitize-html/components/sanitize-html', ['exports', 'ember', 'ember-sanitize-html/templates/components/sanitize-html', 'ember-sanitize-html/utils/sanitize-html'], function (exports, _ember, _emberSanitizeHtmlTemplatesComponentsSanitizeHtml, _emberSanitizeHtmlUtilsSanitizeHtml) {
  exports['default'] = _ember['default'].Component.extend({

    layout: _emberSanitizeHtmlTemplatesComponentsSanitizeHtml['default'],

    classNames: ['sanitized-value'],
    value: null,
    options: null,

    sanitizedValue: _ember['default'].computed('value', function () {
      var value = this.get('value');
      var options = this.get('options');
      console.log('value', (0, _emberSanitizeHtmlUtilsSanitizeHtml['default'])(value, options));
      return (0, _emberSanitizeHtmlUtilsSanitizeHtml['default'])(value, options);
    })

  });
});