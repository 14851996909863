enifed('ember-application/system/resolver', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-runtime', 'ember-application/utils/validate-type', 'ember-glimmer'], function (exports, _emberUtils, _emberMetal, _emberDebug, _emberRuntime, _validateType, _emberGlimmer) {
  'use strict';

  exports.Resolver = undefined;
  /**
  @module @ember/application
  */

  exports.Resolver = _emberRuntime.Object.extend({
    /*
      This will be set to the Application instance when it is
      created.
       @property namespace
    */
    namespace: null,
    normalize: null, // required
    resolve: null, // required
    parseName: null, // required
    lookupDescription: null, // required
    makeToString: null, // required
    resolveOther: null, // required
    _logLookup: null // required
  });

  /**
    The DefaultResolver defines the default lookup rules to resolve
    container lookups before consulting the container for registered
    items:
  
    * templates are looked up on `Ember.TEMPLATES`
    * other names are looked up on the application after converting
      the name. For example, `controller:post` looks up
      `App.PostController` by default.
    * there are some nuances (see examples below)
  
    ### How Resolving Works
  
    The container calls this object's `resolve` method with the
    `fullName` argument.
  
    It first parses the fullName into an object using `parseName`.
  
    Then it checks for the presence of a type-specific instance
    method of the form `resolve[Type]` and calls it if it exists.
    For example if it was resolving 'template:post', it would call
    the `resolveTemplate` method.
  
    Its last resort is to call the `resolveOther` method.
  
    The methods of this object are designed to be easy to override
    in a subclass. For example, you could enhance how a template
    is resolved like so:
  
    ```app/app.js
    import Application from '@ember/application';
    import GlobalsResolver from '@ember/application/globals-resolver';
  
    App = Application.create({
      Resolver: GlobalsResolver.extend({
        resolveTemplate(parsedName) {
          let resolvedTemplate = this._super(parsedName);
          if (resolvedTemplate) { return resolvedTemplate; }
  
          return Ember.TEMPLATES['not_found'];
        }
      })
    });
    ```
  
    Some examples of how names are resolved:
  
    ```text
    'template:post'           //=> Ember.TEMPLATES['post']
    'template:posts/byline'   //=> Ember.TEMPLATES['posts/byline']
    'template:posts.byline'   //=> Ember.TEMPLATES['posts/byline']
    'template:blogPost'       //=> Ember.TEMPLATES['blog-post']
    'controller:post'         //=> App.PostController
    'controller:posts.index'  //=> App.PostsIndexController
    'controller:blog/post'    //=> Blog.PostController
    'controller:basic'        //=> Controller
    'route:post'              //=> App.PostRoute
    'route:posts.index'       //=> App.PostsIndexRoute
    'route:blog/post'         //=> Blog.PostRoute
    'route:basic'             //=> Route
    'foo:post'                //=> App.PostFoo
    'model:post'              //=> App.Post
    ```
  
    @class GlobalsResolver
    @extends EmberObject
    @public
  */

  var DefaultResolver = _emberRuntime.Object.extend({
    /**
      This will be set to the Application instance when it is
      created.
       @property namespace
      @public
    */
    namespace: null,

    init: function () {
      this._parseNameCache = (0, _emberUtils.dictionary)(null);
    },
    normalize: function (fullName) {
      var _fullName$split = fullName.split(':'),
          type = _fullName$split[0],
          name = _fullName$split[1],
          result;

      false && !(fullName.split(':').length === 2) && (0, _emberDebug.assert)('Tried to normalize a container name without a colon (:) in it. ' + 'You probably tried to lookup a name that did not contain a type, ' + 'a colon, and a name. A proper lookup name would be `view:post`.', fullName.split(':').length === 2);

      if (type !== 'template') {
        result = name.replace(/(\.|_|-)./g, function (m) {
          return m.charAt(1).toUpperCase();
        });


        return type + ':' + result;
      } else {
        return fullName;
      }
    },
    resolve: function (fullName) {
      var parsedName = this.parseName(fullName);
      var resolveMethodName = parsedName.resolveMethodName;
      var resolved = void 0;

      if (this[resolveMethodName]) {
        resolved = this[resolveMethodName](parsedName);
      }

      resolved = resolved || this.resolveOther(parsedName);

      if (resolved) {
        (0, _validateType.default)(resolved, parsedName);
      }

      return resolved;
    },
    parseName: function (fullName) {
      return this._parseNameCache[fullName] || (this._parseNameCache[fullName] = this._parseName(fullName));
    },
    _parseName: function (fullName) {
      var _fullName$split2 = fullName.split(':'),
          type = _fullName$split2[0],
          fullNameWithoutType = _fullName$split2[1],
          parts,
          namespaceName;

      var name = fullNameWithoutType;
      var namespace = (0, _emberMetal.get)(this, 'namespace');
      var root = namespace;
      var lastSlashIndex = name.lastIndexOf('/');
      var dirname = lastSlashIndex !== -1 ? name.slice(0, lastSlashIndex) : null;

      if (type !== 'template' && lastSlashIndex !== -1) {
        parts = name.split('/');

        name = parts[parts.length - 1];
        namespaceName = _emberRuntime.String.capitalize(parts.slice(0, -1).join('.'));

        root = _emberRuntime.Namespace.byName(namespaceName);

        false && !root && (0, _emberDebug.assert)('You are looking for a ' + name + ' ' + type + ' in the ' + namespaceName + ' namespace, but the namespace could not be found', root);
      }

      var resolveMethodName = fullNameWithoutType === 'main' ? 'Main' : _emberRuntime.String.classify(type);

      if (!(name && type)) {
        throw new TypeError('Invalid fullName: `' + fullName + '`, must be of the form `type:name` ');
      }

      return {
        fullName: fullName,
        type: type,
        fullNameWithoutType: fullNameWithoutType,
        dirname: dirname,
        name: name,
        root: root,
        resolveMethodName: 'resolve' + resolveMethodName
      };
    },
    lookupDescription: function (fullName) {
      var parsedName = this.parseName(fullName);
      var description = void 0;

      if (parsedName.type === 'template') {
        return 'template at ' + parsedName.fullNameWithoutType.replace(/\./g, '/');
      }

      description = parsedName.root + '.' + _emberRuntime.String.classify(parsedName.name).replace(/\./g, '');

      if (parsedName.type !== 'model') {
        description += _emberRuntime.String.classify(parsedName.type);
      }

      return description;
    },
    makeToString: function (factory) {
      return factory.toString();
    },
    useRouterNaming: function (parsedName) {
      if (parsedName.name === 'basic') {
        parsedName.name = '';
      } else {
        parsedName.name = parsedName.name.replace(/\./g, '_');
      }
    },
    resolveTemplate: function (parsedName) {
      var templateName = parsedName.fullNameWithoutType.replace(/\./g, '/');

      return (0, _emberGlimmer.getTemplate)(templateName) || (0, _emberGlimmer.getTemplate)(_emberRuntime.String.decamelize(templateName));
    },
    resolveView: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },
    resolveController: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },
    resolveRoute: function (parsedName) {
      this.useRouterNaming(parsedName);
      return this.resolveOther(parsedName);
    },
    resolveModel: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.name);
      var factory = (0, _emberMetal.get)(parsedName.root, className);

      return factory;
    },
    resolveHelper: function (parsedName) {
      return this.resolveOther(parsedName);
    },
    resolveOther: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.name) + _emberRuntime.String.classify(parsedName.type);
      var factory = (0, _emberMetal.get)(parsedName.root, className);
      return factory;
    },
    resolveMain: function (parsedName) {
      var className = _emberRuntime.String.classify(parsedName.type);
      return (0, _emberMetal.get)(parsedName.root, className);
    },
    knownForType: function (type) {
      var namespace = (0, _emberMetal.get)(this, 'namespace'),
          index,
          name,
          containerName;
      var suffix = _emberRuntime.String.classify(type);
      var typeRegexp = new RegExp(suffix + '$');

      var known = (0, _emberUtils.dictionary)(null);
      var knownKeys = Object.keys(namespace);
      for (index = 0; index < knownKeys.length; index++) {
        name = knownKeys[index];


        if (typeRegexp.test(name)) {
          containerName = this.translateToContainerFullname(type, name);


          known[containerName] = true;
        }
      }

      return known;
    },
    translateToContainerFullname: function (type, name) {
      var suffix = _emberRuntime.String.classify(type);
      var namePrefix = name.slice(0, suffix.length * -1);
      var dasherizedName = _emberRuntime.String.dasherize(namePrefix);

      return type + ':' + dasherizedName;
    }
  });

  exports.default = DefaultResolver;
});