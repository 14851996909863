enifed('ember-glimmer/template', ['exports', '@glimmer/runtime', 'ember-utils'], function (exports, _runtime, _emberUtils) {
    'use strict';

    exports.WrappedTemplateFactory = undefined;
    exports.default = function (json) {
        var factory = (0, _runtime.templateFactory)(json);
        return new WrappedTemplateFactory(factory);
    };

    var WrappedTemplateFactory = exports.WrappedTemplateFactory = function () {
        function WrappedTemplateFactory(factory) {

            this.factory = factory;
            this.id = factory.id;
            this.meta = factory.meta;
        }

        WrappedTemplateFactory.prototype.create = function (props) {
            var owner = props[_emberUtils.OWNER];
            return this.factory.create(props.env, { owner: owner });
        };

        return WrappedTemplateFactory;
    }();
});