define('ember-cli-uuid/index', ['exports', 'ember-cli-uuid/utils/uuid-helpers'], function (exports, _uuidHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'uuid', {
    enumerable: true,
    get: function () {
      return _uuidHelpers.uuid;
    }
  });
});